import styled from "styled-components";

export const StyledHeaderBox = styled.header`
    background: #d0ecf4;
    line-height: 180%;
    padding: 40px;
    margin-top: 30px;

    h2 {
        font-size: 32px;
        font-weight: 400;
        margin-bottom: 20px;
    }

    p {
        font-size: 22px;
        font-weight: 400;
    }
`;
