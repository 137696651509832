import { ITranslations } from "@honzachalupa/ui";

export const translations: ITranslations = {
    cs: {
        translation: {
            form: {
                validationMessages: {
                    isRequired: "Pole je povinné",
                    minLength: "Hodnota je kratší než povoleno",
                    maxLength: "Hodnota je delší než povoleno",
                    pattern: "Hodnota neodpovídá formátu",
                },
            },
            cookieBanner: {
                headline: "Souhlasíte s použitím analytických cookies?",
                content:
                    "Tyto soubory používáme k měření a analýze návštěvnosti našich webových stránek (množství návštěvníků, zobrazené stránky, průměrná doba prohlížení atd.), což nám pomáhá vylepšovat jejich fungování a vyvíjet pro vás nové funkce. Souhlasem nám pomáháte získat cenná data o tom, jak naše stránky užíváte. Díky tomu náš web funguje lépe a vy si můžete užít kvalitnější uživatelský zážitek.",
                agreeButtonText: "Souhlasím",
                disagreeButtonText: "Nesouhlasím",
            },
            copyButton: {
                copy: "Kopírovat",
                copied: "Zkopírováno do schránky",
            },
        },
    },
};
