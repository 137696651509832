import { convertDocToItem } from "@honzachalupa/firebase";
import { ITask } from "@nazorna-vyuka/types";
import { Collections, Database } from "../utils/firebase";

export const TasksAPI = {
    get: (id: ITask["id"]): Promise<ITask> =>
        Database.get(Collections.tasks, id)
            .then(convertDocToItem)
            .catch((error) => error),
};
