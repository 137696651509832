import { DATE_TIME_FORMAT } from "@honzachalupa/utils";
import { ECreditTransactionTypeLabels } from "@nazorna-vyuka/types";
import { Layout_Main, List, Title } from "@nazorna-vyuka/ui";
import {
    CreditTransactionsAPI,
    formatUserName,
    useCurrentPage,
    UsersAPI,
} from "@nazorna-vyuka/utils";
import moment from "moment";
import { useEffect, useState } from "react";
import { StyledTransaction } from "./CreditTransactionsBlock.styled";

export const Page_CreditTransactions: React.FC = () => {
    const { title } = useCurrentPage({
        title: "Transakce",
        isAnalyticsDisabled: true,
    });

    const [data, setData] = useState<any[]>([]);

    const getData = async () => {
        const creditTransactionsFiltered = await CreditTransactionsAPI.search();

        const usersWithTransactions = await Promise.all(
            creditTransactionsFiltered
                .filter(({ transactions }) => transactions?.length > 0)
                .map(async ({ id, transactions }) => {
                    const user = await UsersAPI.get(id);

                    const transactionsFiltered = transactions.filter(
                        ({ type }) =>
                            type && type !== "CREDIT_TOP_UP_REGISTRATION",
                    );

                    return transactionsFiltered.length > 0
                        ? {
                              ...user,
                              transactions: transactionsFiltered,
                          }
                        : null;
                }),
        );

        setData(usersWithTransactions.filter(Boolean));
    };

    useEffect(() => {
        getData();
    }, []);

    return (
        <Layout_Main>
            <Title level={1}>{title}</Title>

            <List
                headline="Transakce"
                headlineAlignment="left"
                data={data}
                columns={[
                    {
                        id: "name",
                        label: "Jméno",
                        renderer: ({ firstName, lastName }: any) =>
                            formatUserName(firstName, lastName),
                    },
                    {
                        id: "emailAddress",
                        label: "E-mailová adresa",
                    },
                    {
                        id: "transactions",
                        label: "Transakce",
                        renderer: ({ transactions }: any) =>
                            transactions.map(
                                ({ type, amount, createdDate }: any) => (
                                    <StyledTransaction type={type}>
                                        <p>
                                            {/*
                                            // @ts-ignore */}
                                            {ECreditTransactionTypeLabels[type]}
                                        </p>
                                        <p>
                                            Datum:{" "}
                                            {moment(createdDate).format(
                                                DATE_TIME_FORMAT,
                                            )}
                                        </p>
                                        <p>Částka: {Math.abs(amount)} Kč</p>
                                    </StyledTransaction>
                                ),
                            ),
                    },
                ]}
            />
        </Layout_Main>
    );
};
