import { EPatterns, IFormInput } from "@nazorna-vyuka/types";
import { Form, HorizontalDivider, Loader } from "@nazorna-vyuka/ui";
import {
    Authentication,
    log,
    translateAuthError,
    useNavigation,
    UsersAPI,
} from "@nazorna-vyuka/utils";
import { Fragment, useMemo, useState } from "react";
import { ThirdPartyAuthOptions } from "../../molecules/ThirdPartyAuthOptions";
import { StyledTermsAndConditionsContainer } from "./SignUp.styled";

interface IFormData {
    emailAddress: string;
    password: string;
    passwordVerification: string;
}

export const Form_SignUp: React.FC = () => {
    const navigateTo = useNavigation();

    const [errors, setErrors] = useState<{
        [inputId: string]: string | undefined;
    }>();
    const [isLoading, setIsLoading] = useState<boolean>();
    const [isPasswordMismatched, setIsPasswordMismatched] = useState<boolean>();

    const handleSubmit = ({
        emailAddress,
        password,
        passwordVerification,
    }: IFormData) => {
        if (password === passwordVerification) {
            setIsLoading(true);

            Authentication.createUserWithEmailAndPassword(
                emailAddress.trim(),
                password,
            )
                .then(({ user }) => {
                    UsersAPI.create(user.uid, emailAddress.trim())
                        .then(navigateTo.accountSetInitialDetails)
                        .catch((error) => {
                            log({
                                code: "EXCEPTION",
                                scope: "Form_SignUp",
                                error,
                            });
                        });
                })
                .catch((error) => {
                    setErrors(translateAuthError(error.code));
                })
                .finally(() => {
                    setIsLoading(false);
                });
        } else {
            setIsPasswordMismatched(true);
        }
    };

    return (
        <Fragment>
            <HorizontalDivider>
                <div style={{ padding: "0 15%" }}>
                    <Form
                        inputs={useMemo<IFormInput[]>(
                            () => [
                                {
                                    id: "emailAddress",
                                    label: "Emailová adresa",
                                    controlType: "input",
                                    inputType: "email",
                                    rules: {
                                        required: true,
                                        pattern: EPatterns.EMAIL_ADDRESS,
                                    },
                                    error: errors?.["emailAddress"],
                                },
                                {
                                    id: "password",
                                    label: "Heslo",
                                    controlType: "input",
                                    inputType: "password",
                                    rules: {
                                        required: true,
                                    },
                                    error: errors?.["password"],
                                },
                                {
                                    id: "passwordVerification",
                                    label: "Heslo (ověření)",
                                    controlType: "input",
                                    inputType: "password",
                                    rules: {
                                        required: true,
                                    },
                                    error: isPasswordMismatched
                                        ? "Hesla se neshodují"
                                        : errors?.["passwordVerification"],
                                },
                            ],
                            [errors, isPasswordMismatched],
                        )}
                        buttonsRenderer={(submit) => [
                            {
                                label: "Registrovat",
                                testId: "SignUp",
                                onClick: submit,
                            },
                        ]}
                        onChange={() => setIsPasswordMismatched(false)}
                        onSubmit={handleSubmit}
                    />
                </div>

                <div style={{ padding: "50px 15% 0px 15%" }}>
                    <ThirdPartyAuthOptions action="sign-up" />
                </div>
            </HorizontalDivider>

            <StyledTermsAndConditionsContainer>
                Registrací souhlasíte s podmínkami použití a Lorem ipsum dolor
                sit amet, consectetuer adipiscing elit. Mauris tincidunt sem sed
                arcu. Nullam eget nisl. Duis pulvinar. Donec vitae arcu.
                Praesent in mauris eu tortor porttitor accumsan. Aenean
                placerat. Duis risus. Vivamus porttitor turpis ac leo.{" "}
            </StyledTermsAndConditionsContainer>

            {isLoading && <Loader isFullscreen />}
        </Fragment>
    );
};
