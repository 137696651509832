import { ICurrentRoute } from "./app";
import { ISignedUser } from "./user";

export interface IContext {
    STORAGE_URL: string;
    user: ISignedUser | null | undefined;
    searchQuery: string | undefined;
    currentRoute: ICurrentRoute | undefined;
    storageData: {
        lessonName: string;
        lessonId: string;
        sortId: number;
        animationUrl: string;
        thumbnailUrl: string;
    }[];
    isGlobalLoading: boolean;
    isScrollDisabled: boolean;
    isCookiesAllowed: boolean;
    setIsGlobalLoading: (value: boolean) => void;
    setSearchQuery: (value: string | undefined) => void;
    setCurrentRoute: (route: ICurrentRoute) => void;
    setIsScrollDisabled: (value: boolean) => void;
    setIsCookiesAllowed: (value: boolean) => void;
}
