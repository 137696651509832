import { ERoutePaths } from "@nazorna-vyuka/types";
import { Route } from "react-router-dom";
import { Page_PaymentConfirmation } from "./PaymentConfirmation/PaymentConfirmation";

export default [
    <Route
        path={ERoutePaths.PAYMENT_CONFIRMATION}
        element={<Page_PaymentConfirmation />}
    />,
];
