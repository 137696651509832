import styled from "styled-components";
import { Icon } from "../../../../../..";

export const StyledIconContainer = styled.div`
    display: flex;
    align-items: center;
    margin: 0 20px 0 50px;
    cursor: pointer;
    transition: all 250ms;

    &:hover {
        background: rgba(0, 0, 0, 0.04);
        border-radius: 25px;
    }
`;

export const StyledIcon = styled(Icon)`
    width: 50px;
    min-width: 50px;
    height: 50px;
    background: ${({ theme }) => theme.colors.grayLight};
    border-radius: 50%;
    padding: 10px;
`;

export const StyledUserName = styled.span`
    margin-top: 2px;
    padding: 0 15px;
    white-space: nowrap;
`;

export const StyledItemsContainer = styled.ul`
    width: 250px;
    background: white;
    box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.15);
    padding: 10px;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 100px;
    right: 20px;
    z-index: 2;
`;

export const StyledItem = styled.button`
    font-weight: 400;

    &,
    &:hover {
        background: none;
        font-size: 21.5px;
        line-height: 24px;
        text-align: left;
        border: none;
        border-bottom: 1.5px solid #d9d9e0;
        border-radius: 0;
        padding: 10px 20px;
        margin: 0 10px 5px 10px;
    }

    &:hover {
        font-weight: 600;
    }

    &:last-of-type {
        border-bottom: none;
        margin-bottom: 0;
    }
`;

export const StyledOverlay = styled.div`
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
`;
