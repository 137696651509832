import styled from "styled-components";

export const StyledContainer = styled.div<{ isFullscreenOn: boolean }>`
    ${({ isFullscreenOn }) =>
        isFullscreenOn &&
        `
        width: 100vw;
        height: 100vh;
        background-color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 5;
    `}
`;

export const StyledIframe = styled.iframe`
    width: 100%;
    aspect-ratio: 16/9.05;
    border: none;
    position: relative;
    margin-bottom: 10px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
`;
