import styled from "styled-components";

export const StyledTermsAndConditionsContainer = styled.div`
    font-weight: 400;
    font-size: 15px;
    line-height: 17px;
    text-align: center;
    margin-top: 100px;
    padding: 0 20vw;
`;
