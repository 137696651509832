import React from "react";
import { Logo } from "../Logo";
import { Navigation } from "../Navigation";
import { Search } from "../Search";
import { StyledContainer } from "./Header.styled";

export interface IProps {
    onLogoClick: () => void;
}

export const Header: React.FC<IProps> = ({ onLogoClick }) => (
    <StyledContainer>
        <Logo onClick={onLogoClick} />
        <Search />
        <Navigation />
    </StyledContainer>
);
