import { getTestId } from "@honzachalupa/utils";
import { config, useNavigation } from "@nazorna-vyuka/utils";
import moment from "moment";
import React from "react";
import {
    StyledContainer,
    StyledCopyright,
    StyledTermsAndConditions,
} from "./Footer.styled";

export const Footer: React.FC = () => {
    const navigateTo = useNavigation();

    return (
        <StyledContainer {...getTestId(Footer.name)}>
            <StyledCopyright>
                &copy; {moment().year()} {config.developerName}
            </StyledCopyright>

            <StyledTermsAndConditions onClick={navigateTo.termsAndConditions}>
                Obchodní podmínky
            </StyledTermsAndConditions>
        </StyledContainer>
    );
};
