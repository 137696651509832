import { convertDocToItem } from "@honzachalupa/firebase";
import { IInvitation } from "@nazorna-vyuka/types";
import { Collections, Database } from "../utils/firebase";

export const InvitationsAPI = {
    create: ({
        emailAddress,
        createdBy,
    }: {
        emailAddress: IInvitation["emailAddress"];
        createdBy: IInvitation["createdBy"];
    }) => {
        const data: Omit<IInvitation, "id"> = {
            emailAddress,
            state: "PENDING",
            createdBy,
        };

        return Database.create(Collections.invitations, data).catch(
            (error) => error,
        );
    },

    get: (id: IInvitation["id"]): Promise<IInvitation> =>
        Database.get(Collections.invitations, id)
            .then(convertDocToItem)
            .catch((error) => error),

    setState: (id: IInvitation["id"], state: IInvitation["state"]) =>
        Database.update(Collections.invitations, id, {
            state,
        }).catch((error) => error),
};
