import {
    IIconProps,
    IModalRefProps,
    Modal,
    StyledModalTitle,
} from "@nazorna-vyuka/ui";
import { forwardRef, RefAttributes } from "react";
import { StyledContent, StyledIcon } from "./ModalMessage.styled";

interface IProps {
    message: string;
    icon?: {
        name: IIconProps["name"];
        color?: IIconProps["color"];
        backgroundColor?: string;
    };
    onClose?: () => void;
}

export const ModalMessage: React.FC<IProps & RefAttributes<IModalRefProps>> =
    forwardRef(({ message, icon, onClose }, ref) => {
        return (
            <Modal ref={ref} onClose={onClose}>
                <StyledContent>
                    <StyledModalTitle level={3}>{message}</StyledModalTitle>

                    {icon && (
                        <StyledIcon
                            name={icon.name}
                            color={icon.color}
                            backgroundColor={icon.backgroundColor}
                        />
                    )}
                </StyledContent>
            </Modal>
        );
    });
