import { IModalRefProps } from "@honzachalupa/ui";
import {
    Authentication,
    log,
    useAuthentication,
    useNavigation,
} from "@nazorna-vyuka/utils";
import { Fragment, useMemo, useRef, useState } from "react";
import { Modal_SignOutConfirmation } from "../../../../../../components/organisms/modals/SignOutConfirmation";
import {
    StyledIcon,
    StyledIconContainer,
    StyledItem,
    StyledItemsContainer,
    StyledOverlay,
    StyledUserName,
} from "./Account.styled";

export const Account: React.FC = () => {
    const navigateTo = useNavigation();
    const {
        user,
        isUserAuthenticated,
        isUserAuthenticatedAnonymously,
        isAdmin,
        sinOutAnonymously,
    } = useAuthentication();

    const modalRef = useRef<IModalRefProps>(null);

    const [isOpened, setIsOpened] = useState<boolean>();

    const handleSignOut = () =>
        Authentication.signOut()
            .then(() => {
                sinOutAnonymously();
                modalRef.current?.hide();
                window.location.reload();
            })
            .catch((error) => {
                log({
                    code: "EXCEPTION",
                    scope: "Page_AuthenticationSignOut",
                    error,
                });
            });

    const userName = useMemo(
        () =>
            user?.firstName && user?.lastName
                ? `${user.firstName} ${user.lastName}`
                : "Účet",
        [user],
    );

    const items = useMemo(
        () =>
            [
                {
                    label: "Administrace",
                    isShown: isAdmin,
                    onClick: navigateTo.administration,
                },
                {
                    label: "Přihlásit",
                    isShown:
                        !isUserAuthenticated && !isUserAuthenticatedAnonymously,
                    onClick: navigateTo.signIn,
                },
                {
                    label: "Registrovat",
                    isShown:
                        !isUserAuthenticated && !isUserAuthenticatedAnonymously,
                    onClick: navigateTo.signUp,
                },
                {
                    label: "Účet",
                    isShown:
                        isUserAuthenticated && !isUserAuthenticatedAnonymously,
                    onClick: navigateTo.accountDetails,
                },
                {
                    label: "Kredit",
                    isShown:
                        isUserAuthenticated && !isUserAuthenticatedAnonymously,
                    onClick: navigateTo.accountCredit,
                },
                {
                    label: "Disponenti",
                    isShown:
                        isUserAuthenticated && !isUserAuthenticatedAnonymously,
                    onClick: navigateTo.accountDisponents,
                },
                {
                    label: "Odhlásit",
                    isShown:
                        isUserAuthenticated || isUserAuthenticatedAnonymously,
                    onClick: () => modalRef.current?.show(),
                },
            ].filter(({ isShown }) => isShown),
        [isUserAuthenticated, isUserAuthenticatedAnonymously, isAdmin],
    );

    return (
        <Fragment>
            <Modal_SignOutConfirmation
                ref={modalRef}
                onSubmit={handleSignOut}
            />

            <StyledIconContainer
                onClick={() => setIsOpened((prevState) => !prevState)}
            >
                <StyledIcon name="person" testId="userIcon" />
                <StyledUserName>{userName}</StyledUserName>
            </StyledIconContainer>

            {isOpened && (
                <Fragment>
                    <StyledItemsContainer>
                        {items.map(({ label, onClick }) => (
                            <StyledItem key={label} onClick={onClick}>
                                {label}
                            </StyledItem>
                        ))}
                    </StyledItemsContainer>

                    <StyledOverlay onClick={() => setIsOpened(false)} />
                </Fragment>
            )}
        </Fragment>
    );
};
