import styled from "styled-components";

export const StyledContainer = styled.div`
    display: flex;

    & > div {
        width: 500px;
        margin-right: 5px;
    }

    button {
        width: 150px;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    }
`;
