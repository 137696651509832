import { IModule } from "@nazorna-vyuka/types";
import { IoGameControllerOutline } from "react-icons/io5";
import { CollapsibleBlock } from "../../../atoms/CollapsibleBlock";
import { MarkdownText } from "../../../atoms/MarkdownText";

export const Module_SimulatorManual: React.FC<IModule> = ({ text }) => (
    <CollapsibleBlock
        label="Návod na simulátor"
        icon={<IoGameControllerOutline />}
        color="#AEA9FF"
    >
        <MarkdownText value={text} />
    </CollapsibleBlock>
);
