import { TCreditTransactionType } from "@nazorna-vyuka/types";
import styled from "styled-components";

export const StyledTransaction = styled.div<{ type: TCreditTransactionType }>`
    margin: 10px 0;
    padding-left: 7px;
    border-left: 5px solid
        ${({ type, theme }) =>
            type === "LESSON_ACTIVATION"
                ? theme.colors.red
                : theme.colors.green};
`;
