import CryptoJS from "crypto-js";
import moment from "moment";

const URL = "https://vimto.nazornavyuka.cz/go";
const SECRET = "edhancepreview202210";

const textToSHA1 = (text: string) => CryptoJS.SHA1(text).toString();

const jsonToBase64 = (json: object) =>
    CryptoJS.enc.Base64.stringify(
        CryptoJS.enc.Utf8.parse(JSON.stringify(json)),
    );

export const getVimtoUrl = ({
    classRoomName,
    subjectCode,
    classYear,
    teacherName,
    exerciseName,
}: {
    classRoomName: string;
    subjectCode: string;
    classYear: number;
    teacherName: string;
    exerciseName: string;
}) => {
    const time = moment().valueOf();

    const checksum = textToSHA1(
        classRoomName.toLowerCase() + teacherName.toLowerCase() + time + SECRET,
    );

    const params = new URLSearchParams({
        edhance_create: jsonToBase64({
            room: classRoomName,
            user: teacherName,
            time,
            checksum,
        }),
        pref: jsonToBase64({
            z: "edhance",
            s: subjectCode,
            c: `p_${classYear}`,
        }),
        goto: jsonToBase64({
            section: "task",
            filter: {
                tag: [exerciseName],
            },
        }),
    });

    return `${URL}?${params}`;
};
