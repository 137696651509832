import { IFormInput } from "@nazorna-vyuka/types";
import { Form } from "@nazorna-vyuka/ui";
import { Authentication, translateAuthError } from "@nazorna-vyuka/utils";
import { useMemo, useState } from "react";

export interface IProps {
    emailAddress: string | undefined;
    onSubmit: () => void;
}

interface IFormData {
    emailAddress: string;
}

export const Form_PasswordReset: React.FC<IProps> = ({
    emailAddress,
    onSubmit,
}) => {
    const [errors, setErrors] = useState<{
        [inputId: string]: string | undefined;
    }>();

    const handleSubmit = ({ emailAddress }: IFormData) => {
        Authentication.sendPasswordResetEmail(emailAddress.trim())
            .then(onSubmit)
            .catch((error) => {
                setErrors(translateAuthError(error.code));
            });
    };

    return (
        <Form
            inputs={useMemo<IFormInput[]>(
                () => [
                    {
                        id: "emailAddress",
                        label: "E-mailová adresa",
                        value: emailAddress,
                        controlType: "input",
                        rules: {
                            required: true,
                        },
                        error: errors?.["emailAddress"],
                    },
                ],
                [errors],
            )}
            buttonsRenderer={(submit) => [
                {
                    label: "Resetovat",
                    onClick: submit,
                },
            ]}
            onSubmit={handleSubmit}
        />
    );
};
