import { useLocalStorage } from "@honzachalupa/utils";
import { ERoles, IContext } from "@nazorna-vyuka/types";
import { useContext } from "react";
import { Context } from "../utils/context";

export const useAuthentication = () => {
    const { user } = useContext<IContext>(Context);

    const [anonymousAuth, setAnonymousAuth] = useLocalStorage<{
        id?: string;
    }>("anonymousAuth", {});

    const isUserAuthenticated = !!user;
    const isUserAuthenticatedAnonymously = !!anonymousAuth?.id;
    const isUserInfoCompleted =
        !!(
            user?.firstName &&
            user?.lastName &&
            user?.phoneNumber &&
            user?.schoolName
        ) || anonymousAuth?.id;

    const sinOutAnonymously = () => {
        setAnonymousAuth({});
    };

    return {
        user,
        signedAsUserId: anonymousAuth.id,
        isAdmin: user?.role === ERoles.ADMIN,
        isUserAuthenticated,
        isUserAuthenticatedAnonymously,
        isUserInfoCompleted,
        sinOutAnonymously,
    };
};
