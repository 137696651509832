import styled from "styled-components";
import { Icon } from "../../..";

export const StyledContainer = styled.div<{ color: string; hasIcon: boolean }>`
    background: ${({ color }) => color};
    border: 1px solid rgba(0, 0, 0, 0.25);
    border-radius: 7px;
    margin: 20px 0;
    overflow: hidden;
    position: relative;

    .Collapsible {
        &__trigger {
            min-height: auto;
            font-weight: 500;
            font-size: 20px;
            line-height: 22px;
            padding-left: ${({ hasIcon }) => (hasIcon ? 60 : 25)}px;
            cursor: pointer;
            display: flex;
            align-items: center;
        }

        &__contentInner {
            background: white;
            padding: 20px;
        }
    }
`;

export const StyledIconContainer = styled.div`
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 22px;
    left: 20px;
    pointer-events: none;

    * {
        width: 100%;
        height: 100%;
    }
`;

export const StyledLabelsContainer = styled.div`
    min-height: 65px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px 60px 10px 0;

    & > div {
        font-size: 16px !important;
        font-weight: 600 !important;
        margin-top: 5px !important;

        & + * {
            border-top: 1px solid rgba(0, 0, 0, 0.3);
            padding-top: 10px;
            margin-bottom: 5px;
        }
    }
`;

export const StyledToggleIcon = styled(Icon)<{ isClickDisabled?: boolean }>`
    width: 35px;
    position: absolute;
    top: 17px;
    right: 15px;
    z-index: 99;

    ${({ isClickDisabled }) =>
        isClickDisabled &&
        `
        pointer-events: none;
    `};
`;

export const StyledText = styled.div`
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    padding-right: 60px;

    p + p {
        margin-bottom: 10px;
    }
`;
