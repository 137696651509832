import { ISignedUser } from "@nazorna-vyuka/types";
import { Layout_Main } from "@nazorna-vyuka/ui";
import { OwnershipsAPI, useCurrentPage, UsersAPI } from "@nazorna-vyuka/utils";
import React, { Fragment, useEffect, useState } from "react";
import { LessonsGrid } from "../../components/molecules/LessonsGrid";
import { StyledHeadline } from "./CoursesUnlocked.styled";

export const Page_CoursesUnlocked: React.FC = () => {
    const { user } = useCurrentPage({
        title: "Odemčené lekce",
    });

    const [ownedLessonIDs, setOwnedLessonIDs] = useState<string[]>([]);
    const [sharedLessonIDs, setSharedLessonIDs] = useState<{
        [sharedBy: string]: string[];
    }>({});

    const getUserNameById = (id: ISignedUser["id"]) =>
        UsersAPI.get(id).then((user) => `${user.firstName} ${user.lastName}`);

    useEffect(() => {
        if (user) {
            OwnershipsAPI.getUnlockedLessons(user.id).then(async (lessons) => {
                setOwnedLessonIDs(
                    lessons
                        .filter(
                            ({ ownershipType }) => ownershipType === "owned",
                        )
                        .map(({ lessonId }) => lessonId),
                );

                setSharedLessonIDs(
                    (
                        await Promise.all(
                            lessons
                                .filter(
                                    ({ ownershipType }) =>
                                        ownershipType === "shared",
                                )
                                .map(async (item) => ({
                                    ...item,
                                    sharedByName: await getUserNameById(
                                        item.sharedBy!,
                                    ),
                                })),
                        )
                    ).reduce(
                        (prevValue, value: any) => ({
                            ...prevValue,
                            [value.sharedByName]: value.lessonId,
                        }),
                        {},
                    ),
                );
            });
        }
    }, []);

    return (
        <Layout_Main>
            {ownedLessonIDs.length > 0 && (
                <Fragment>
                    <StyledHeadline>Zakoupené lekce</StyledHeadline>

                    <LessonsGrid
                        filter={{
                            lessonIDs: ownedLessonIDs,
                        }}
                        sort={{ alphabetically: true }}
                    />
                </Fragment>
            )}

            {Object.entries(sharedLessonIDs).map(([sharedBy, lessonIDs]) => (
                <Fragment>
                    <StyledHeadline>
                        Lekce sdílené uživatelem {sharedBy}
                    </StyledHeadline>

                    <LessonsGrid
                        filter={{
                            lessonIDs,
                        }}
                        sort={{ alphabetically: true }}
                    />
                </Fragment>
            ))}
        </Layout_Main>
    );
};
