import { ICourse } from "@nazorna-vyuka/types";
import { Layout_Main, Loader } from "@nazorna-vyuka/ui";
import { CoursesAPI, useCurrentPage } from "@nazorna-vyuka/utils";
import { useEffect, useState } from "react";
import { LessonsGrid } from "../../components/molecules/LessonsGrid";

export const Page_Lessons: React.FC = () => {
    const [course, setCourse] = useState<ICourse>();

    const { params } = useCurrentPage({
        title: course?.title || "Lekce",
    });

    useEffect(() => {
        CoursesAPI.get(params["courseId"] as ICourse["id"]).then(setCourse);
    }, [params["courseId"]]);

    return (
        <Layout_Main>
            {course ? (
                <LessonsGrid
                    key={course.id}
                    filter={{
                        courseIDs: [course.id],
                    }}
                />
            ) : (
                <Loader />
            )}
        </Layout_Main>
    );
};
