import { config } from "@nazorna-vyuka/utils";
import { StyledContainer } from "./Logo.styled";

interface IProps {
    onClick: () => void;
}

export const Logo: React.FC<IProps> = ({ onClick }) => (
    <StyledContainer>
        <img
            src="./assets/logo.svg"
            alt={config.developerName}
            onClick={onClick}
        />
    </StyledContainer>
);
