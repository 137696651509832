import { Layout_Main, Link, Text, Title } from "@nazorna-vyuka/ui";
import { useCurrentPage } from "@nazorna-vyuka/utils";
import styled from "styled-components";
import { Form_SignUp } from "../../../components/organisms/forms/SignUp";

const StyledText = styled(Text)`
    position: relative;
    top: -30px;
`;

export const Page_AuthenticationSignUp: React.FC = () => {
    const { title, navigateTo } = useCurrentPage({ title: "Registrovat" });

    return (
        <Layout_Main>
            <Title level={1}>{title}</Title>

            <StyledText alignment="center">
                Už máte účet?{" "}
                <Link label="Přihlaste se" onClick={navigateTo.signIn} />
            </StyledText>

            <Form_SignUp />
        </Layout_Main>
    );
};
