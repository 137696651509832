import styled from "styled-components";

export const StyledHeadline = styled.h3`
    font-weight: 500;
    font-size: 28px;
    line-height: 31px;
    text-align: center;
    margin-bottom: 5px;
`;

export const StyledValue = styled.p`
    font-weight: 400;
    font-size: 23px;
    line-height: 130%;
    text-align: center;
    margin-bottom: 30px;
`;

export const StyledFormContainer = styled.div`
    padding: 0 15%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const StyledDescription = styled.p`
    font-weight: 400;
    font-size: 20px;
    line-height: 22px;
    text-align: center;
    opacity: 0.5;
    margin: 30px 0 10px 0;
`;

export const StyledListContainer = styled.div`
    border: 1px solid #cfcfd1;
    border-radius: 7px;
    padding: 0 20px;
`;

export const StyledHelpContainer = styled.ul`
    background: white;
    border: 1px solid #cfcfd1;
    border-radius: 7px;
    padding: 50px;

    li {
        font-weight: 400;
        font-size: 20px;
        line-height: 22px;
        margin-bottom: 30px;

        &:last-of-type {
            margin-bottom: 0;
        }
    }
`;
