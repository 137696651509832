import { ReactNode } from "react";
import { StyledContainer, StyledContentItem } from "./HorizontalDivider.styled";

interface IProps {
    padding?: number;
    children: ReactNode[];
    isLineHidden?: boolean;
}

export const HorizontalDivider: React.FC<IProps> = ({
    padding,
    children,
    isLineHidden,
}) => (
    <StyledContainer>
        {children.map((children, i) => (
            <StyledContentItem
                key={i}
                padding={padding}
                isLineHidden={isLineHidden}
            >
                {children}
            </StyledContentItem>
        ))}
    </StyledContainer>
);
