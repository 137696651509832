import { IModule } from "@nazorna-vyuka/types";
import { HiOutlineMenuAlt2 } from "react-icons/hi";
import styled from "styled-components";
import { MarkdownText } from "../../../atoms/MarkdownText";

export const StyledContainer = styled.div`
    display: flex;
    margin: 20px 0;
`;

const StyledIcon = styled(HiOutlineMenuAlt2)`
    width: 45px;
    min-width: 45px;
    height: 45px;
    background: #ffd8d8;
    border-radius: 7px;
    padding: 8px;
`;

const StyledNoteContainer = styled.div`
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    padding-left: 20px;
`;

export const Module_Note: React.FC<IModule> = ({ text }) => (
    <StyledContainer>
        <StyledIcon />

        <StyledNoteContainer>
            <MarkdownText value={text} />
        </StyledNoteContainer>
    </StyledContainer>
);
