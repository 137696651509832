import chemie8 from "./icons/courses/chemie-8.svg";
import chemiePokusy from "./icons/courses/chemie-pokusy.svg";
import financniGramotnost from "./icons/courses/financni-gramotnost.svg";
import fyzika6 from "./icons/courses/fyzika-6.svg";
import informatikaI from "./icons/courses/informatika-i.svg";
import matematika5 from "./icons/courses/matematika-5.svg";
import matematikaPrijimacky from "./icons/courses/matematika-prijimacky.svg";
import medialniGramotnost from "./icons/courses/medialni-gramotnost.svg";
import nutricniGramotnost from "./icons/courses/nutricni-gramotnost.svg";
import home from "./icons/home.svg";
import lock from "./icons/lock.svg";
import unlock from "./icons/unlock.svg";

export const Icons = {
    home,
    lock,
    unlock,
};

export const CoursesIcons = {
    chemie8,
    fyzika6,
    matematika5,
    matematikaPrijimacky,
    informatikaI,
    chemiePokusy,
    nutricniGramotnost,
    financniGramotnost,
    medialniGramotnost,
};
