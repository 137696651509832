import {
    Button,
    ButtonsGroup,
    Layout_Main,
    List,
    Title,
} from "@nazorna-vyuka/ui";

import { convertJsonToCsv, DATE_FORMAT } from "@honzachalupa/utils";
import { ERoleLabels, ERoles, ISignedUser } from "@nazorna-vyuka/types";
import { IModalRefProps } from "@nazorna-vyuka/ui";
import {
    formatUserName,
    OwnershipsAPI,
    useCurrentPage,
    UsersAPI,
} from "@nazorna-vyuka/utils";
import DownloadJS from "downloadjs";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { Modal_DeleteUserConfirmation } from "../../components/organisms/modals/DeleteUserConfirmation";
import {
    ISignedUserOwnershipDetails,
    Modal_ManageUser,
} from "../../components/organisms/modals/ManageUser";

export const Page_Home: React.FC = () => {
    const { title, navigateTo } = useCurrentPage({
        title: "Administrace",
        isAnalyticsDisabled: true,
    });

    const manageUserModalRef = useRef<IModalRefProps>(null);
    const confirmationModalRef = useRef<IModalRefProps>(null);

    const [users, setUsers] = useState<ISignedUserOwnershipDetails[]>([]);
    const [selectedUser, setSelectedUser] =
        useState<ISignedUserOwnershipDetails>(
            {} as ISignedUserOwnershipDetails,
        );

    const getParentDisponents = async (userId: ISignedUser["id"]) => {
        const parentDisponentIDs = await OwnershipsAPI.get(userId).then(
            ({ sharedBy }) => sharedBy || [],
        );

        const parentDisponent = Promise.all(
            parentDisponentIDs.map((userId) => UsersAPI.get(userId)),
        );

        return parentDisponent;
    };

    const getUsers = () => {
        UsersAPI.search().then((users) =>
            Promise.all(
                users.map((user) =>
                    OwnershipsAPI.getSubscriptionsStatus(user.id).then(
                        async (subscription) => ({
                            ...user,
                            subscription,
                            parentDisponents: await getParentDisponents(
                                user.id,
                            ),
                        }),
                    ),
                ),
            ).then(setUsers),
        );
    };

    const getSubscriptionLabel = (isActive: boolean, dueDate?: string) => {
        const dueDateFormatted = moment(dueDate).format(DATE_FORMAT);

        return isActive
            ? `Ano (do ${dueDateFormatted})`
            : dueDate
            ? `Ne (skončilo ${dueDateFormatted})`
            : "Ne";
    };

    const handleUsersDataExport = () => {
        const csv = convertJsonToCsv(users, {
            headerLabels: {
                id: "ID",
                updatedDate: "Datum úpravy",
                createdDate: "Datum registrace",
                role: "Role",
                phoneNumber: "Telefonní číslo",
                schoolName: "Název školy",
                firstName: "Jméno",
                lastName: "Příjmení",
                emailAddress: "E-mailová adresa",
                subscription: "Předplatné",
                parentDisponents: "Disponenství",
                izo: "IZO",
            },
            processor: {
                parentDisponents: (array: any[]) =>
                    array.map(({ id, firstName, lastName, emailAddress }) => ({
                        id,
                        firstName,
                        lastName,
                        emailAddress,
                    })),
            },
        });

        DownloadJS(
            new Blob(["\uFEFF" + csv], {
                type: "charset=utf-8",
            }),
            "export.csv",
            "text/csv",
        );
    };

    useEffect(() => {
        getUsers();
    }, []);

    return (
        <Layout_Main>
            <Title level={1}>{title}</Title>

            <ButtonsGroup>
                <Button
                    label="Přehled transakcí"
                    onClick={navigateTo.administrationCreditTransactions}
                />

                <Button
                    label="Správa dat"
                    onClick={navigateTo.administrationDataManager}
                />
            </ButtonsGroup>

            <Modal_ManageUser
                ref={manageUserModalRef}
                user={selectedUser}
                onClose={getUsers}
            />

            <Modal_DeleteUserConfirmation
                ref={confirmationModalRef}
                user={selectedUser}
                onClose={() => {
                    confirmationModalRef.current?.hide();
                    getUsers();
                }}
            />

            <List
                headline="Správa uživatelů"
                headlineAlignment="left"
                data={users}
                columns={[
                    {
                        id: "name",
                        label: "Jméno",
                        renderer: ({
                            firstName,
                            lastName,
                        }: ISignedUserOwnershipDetails) =>
                            formatUserName(firstName, lastName),
                    },
                    {
                        id: "emailAddress",
                        label: "E-mailová adresa",
                    },
                    {
                        id: "phoneNumber",
                        label: "Telefonní číslo",
                    },
                    {
                        id: "schoolName",
                        label: "Název školy",
                    },
                    {
                        id: "signUpDate",
                        label: "Datum registrace",
                        renderer: ({
                            createdDate,
                        }: ISignedUserOwnershipDetails) =>
                            moment(createdDate).format(DATE_FORMAT),
                    },
                    {
                        id: "isSubscriptionActive",
                        label: "Aktivní předplatné",
                        renderer: ({
                            subscription,
                        }: ISignedUserOwnershipDetails) =>
                            getSubscriptionLabel(
                                subscription.isActive,
                                subscription.dueDate,
                            ),
                    },
                    {
                        id: "isDisponent",
                        label: "Je disponentem",
                        renderer: ({
                            parentDisponents,
                        }: ISignedUserOwnershipDetails) =>
                            parentDisponents.length > 0
                                ? `Ano (${parentDisponents
                                      .map((user) =>
                                          formatUserName(
                                              user.firstName,
                                              user.lastName,
                                          ),
                                      )
                                      .join(", ")})`
                                : "Ne",
                    },
                    {
                        id: "role",
                        label: "Role",
                        renderer: ({ role }: ISignedUserOwnershipDetails) =>
                            // @ts-ignore
                            ERoleLabels[role],
                    },
                ]}
                actions={[
                    {
                        label: "Spravovat",
                        onClick: (user: ISignedUserOwnershipDetails) => {
                            setSelectedUser(user);

                            manageUserModalRef.current?.show();
                        },
                    },
                    {
                        label: "Odebrat",
                        onClick: (user: ISignedUserOwnershipDetails) => {
                            setSelectedUser(user);

                            confirmationModalRef.current?.show();
                        },
                    },
                ]}
                rowHighlighting={{
                    isGreen: ({ role }: ISignedUserOwnershipDetails) =>
                        role === ERoles.ADMIN,
                }}
                isCountShown
            />

            <ButtonsGroup alignment="left">
                <Button
                    label="Exportovat data"
                    onClick={handleUsersDataExport}
                />
            </ButtonsGroup>
        </Layout_Main>
    );
};
