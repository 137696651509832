import { Icon } from "@nazorna-vyuka/ui";
import styled from "styled-components";

export const StyledContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const StyledText = styled.p`
    font-weight: 500;
    font-size: 34px;
    line-height: 36px;
`;

export const StyledIcon = styled(Icon)<{ backgroundColor?: string }>`
    width: 90px;
    background-color: ${({ backgroundColor }) => backgroundColor};
    border-radius: 50%;
    padding: 15px;
    margin-top: 50px;
`;
