import {
    convertDocsToItems,
    convertDocToItem,
    TQuery,
} from "@honzachalupa/firebase";
import {
    ILesson,
    IOwnership,
    IOwnershipItem,
    ISignedUser,
} from "@nazorna-vyuka/types";
import moment from "moment";
import { Collections, Database } from "../utils/firebase";
import { UsersAPI } from "./users";

const search = (query?: TQuery): Promise<IOwnership[]> =>
    Database.search(Collections.ownerships, query)
        .then(convertDocsToItems)
        .catch((error) => error);

const get = (userId: ISignedUser["id"]): Promise<IOwnership> =>
    Database.get(Collections.ownerships, userId)
        .then(convertDocToItem)
        .catch((error) => error);

const getUnlockedLessons = async (
    userId: ISignedUser["id"],
): Promise<IOwnershipItem[]> => {
    const ownership = await Database.get(Collections.ownerships, userId).then(
        (item) => convertDocToItem(item) as IOwnership,
    );

    const sharedLessons: IOwnershipItem[] = (
        await Promise.all(
            (ownership?.sharedBy || []).map(async (sharedByUserId) => {
                const ownership = await Database.get(
                    Collections.ownerships,
                    sharedByUserId,
                ).then((item) => convertDocToItem(item) as IOwnership);

                return ownership.ownerships.map(
                    ({ lessonId }): IOwnershipItem => ({
                        lessonId,
                        ownershipType: "shared",
                        sharedBy: sharedByUserId,
                    }),
                );
            }),
        )
    ).flat();

    const ownedLessons: IOwnershipItem[] = (ownership.ownerships || []).map(
        ({ lessonId }: IOwnership["ownerships"][0]) => ({
            lessonId,
            ownershipType: "owned",
        }),
    );

    return [...ownedLessons, ...sharedLessons];
};

const unlockLesson = (
    userId: ISignedUser["id"],
    data: {
        lessonId: ILesson["id"];
    },
) =>
    Database.get(Collections.ownerships, userId).then((doc) => {
        const prevState = doc.data()!;

        const nextState = {
            ...prevState,
            ownerships: [
                {
                    ...data,
                    createdDate: moment().format(),
                },
                ...(prevState?.["ownerships"] || []),
            ],
        };

        return Database.set(Collections.ownerships, userId, nextState);
    });

const getDisponents = async (
    userId: ISignedUser["id"],
): Promise<ISignedUser[]> =>
    Promise.all(
        await search().then((disponents) =>
            disponents
                .filter(({ sharedBy }) => sharedBy?.includes(userId))
                .map(({ id }) => UsersAPI.get(id)),
        ),
    );

const setDisponent = (
    sourceUserId: ISignedUser["id"],
    targetUserEmailAddress: ISignedUser["emailAddress"],
) =>
    UsersAPI.search({
        where: [["emailAddress", "==", targetUserEmailAddress]],
    }).then((users) => {
        if (users?.[0]) {
            const { id } = users[0];

            return Database.get(Collections.ownerships, id).then((doc) => {
                const prevState = doc.data()!;

                const nextState = {
                    ...prevState,
                    sharedBy: [
                        ...(prevState?.["sharedBy"] || []),
                        sourceUserId,
                    ],
                };

                return Database.update(Collections.ownerships, id, nextState);
            });
        } else {
            throw new Error(
                "Uživatel se zadanou e-mailovou adresou neexistuje.",
            );
        }
    });

const unsetDisponent = (
    sourceUserId: ISignedUser["id"],
    targetUserEmailAddress: ISignedUser["emailAddress"],
) =>
    UsersAPI.search({
        where: [["emailAddress", "==", targetUserEmailAddress]],
    }).then((users) => {
        const { id } = users[0];

        Database.get(Collections.ownerships, id).then((doc) => {
            const prevState = doc.data()!;

            const nextState = {
                ...prevState,
                sharedBy: [...prevState["sharedBy"]].filter(
                    (id) => id !== sourceUserId,
                ),
            };

            return Database.update(Collections.ownerships, id, nextState);
        });
    });

const getSubscriptionsStatus = async (
    userId: ISignedUser["id"],
): Promise<{
    isActive: boolean;
    dueDate?: string;
}> => {
    const ownership = await Database.get(Collections.ownerships, userId).then(
        (item) => convertDocToItem(item) as IOwnership,
    );

    const isSubscriptionActive = (
        subscription: { dueDate: string } | undefined,
    ) =>
        !!subscription?.dueDate &&
        moment().endOf("day").diff(moment(subscription.dueDate).endOf("day")) <=
            0;

    const status = {
        isActive: isSubscriptionActive(ownership.subscription),
        dueDate: ownership.subscription?.dueDate,
    };

    if (status.isActive) {
        return status;
    }

    const ownershipShared: any[] = (
        await Promise.all(
            (ownership?.sharedBy || []).map(async (sharedByUserId) => {
                const ownership = await Database.get(
                    Collections.ownerships,
                    sharedByUserId,
                ).then((item) => convertDocToItem(item) as IOwnership);

                return ownership.subscription;
            }),
        )
    ).flat();

    const subscriptionShared = ownershipShared.find(isSubscriptionActive);

    if (subscriptionShared) {
        return {
            isActive: true,
            dueDate: subscriptionShared.dueDate,
        };
    }

    return {
        isActive: false,
    };
};

const activateSubscriptions = (
    userId: ISignedUser["id"],
    data: {
        dueDate: string;
    },
) =>
    Database.get(Collections.ownerships, userId).then((doc) => {
        if (doc.exists()) {
            return Database.update(Collections.ownerships, userId, {
                subscription: data,
            });
        }

        return Database.set(Collections.ownerships, userId, {
            subscription: data,
        });
    });

const deactivateSubscriptions = (userId: ISignedUser["id"]) =>
    Database.update(Collections.ownerships, userId, {
        subscription: {},
    });

export const OwnershipsAPI = {
    search,
    get,
    getUnlockedLessons,
    unlockLesson,
    getDisponents,
    setDisponent,
    unsetDisponent,
    getSubscriptionsStatus,
    activateSubscriptions,
    deactivateSubscriptions,
};
