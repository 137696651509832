import { useQuery } from "@honzachalupa/utils";
import { ICourse, ILesson } from "@nazorna-vyuka/types";
import { Layout_Main, List, Title } from "@nazorna-vyuka/ui";
import {
    Context,
    CoursesAPI,
    LessonsAPI,
    useCurrentPage,
} from "@nazorna-vyuka/utils";
import { log } from "firebase-functions/logger";
import { useContext, useEffect, useMemo } from "react";
import { ImportBlock } from "./blocks/Import";
import { StyledInvalidValue } from "./DataManager.styled";

export const Page_DataManager: React.FC = () => {
    const { title } = useCurrentPage({
        title: "Správa dat",
        isAnalyticsDisabled: true,
    });

    const { storageData } = useContext(Context);

    const { data: courses } = useQuery(["courses"], () =>
        CoursesAPI.search().catch((error) => {
            log({
                code: "EXCEPTION",
                scope: "Layout_Main",
                error,
            });
        }),
    );

    const { data: lessons } = useQuery(["lessons"], () =>
        LessonsAPI.search().catch((error) => {
            log({
                code: "EXCEPTION",
                scope: "Layout_Main",
                error,
            });
        }),
    );

    const getStorageDataItem = (id: ILesson["id"]) =>
        storageData.find((item) => item.lessonId === id);

    const isCourseSortIdInvalid = (sortId: ICourse["sortId"]) => sortId <= 0;

    const isLessonSortIdInvalid = (id: ILesson["id"]) =>
        typeof getStorageDataItem(id)?.sortId === "undefined";

    const isLessonThumbnailUrlInvalid = (id: ILesson["id"]) =>
        !getStorageDataItem(id)?.thumbnailUrl;

    const isLessonAnimationUrlInvalid = (id: ILesson["id"]) =>
        !id.includes("matematika-5") && !getStorageDataItem(id)?.animationUrl;

    const isLessonSlideIDsInvalid = (slidesIDs: ILesson["slidesIDs"]) =>
        slidesIDs.length === 0;

    const coursesWithError = useMemo(
        () =>
            courses?.filter(({ id, sortId }) =>
                isCourseSortIdInvalid(sortId),
            ) || [],
        [courses],
    );

    const lessonsWithError = useMemo(
        () =>
            lessons?.filter(
                ({ id, slidesIDs }) =>
                    isLessonSortIdInvalid(id) ||
                    isLessonThumbnailUrlInvalid(id) ||
                    isLessonAnimationUrlInvalid(id) ||
                    isLessonSlideIDsInvalid(slidesIDs),
            ) || [],
        [lessons],
    );

    useEffect(() => {
        // extractData();
    }, []);

    return (
        <Layout_Main>
            <Title level={1}>{title}</Title>

            <ImportBlock />

            {coursesWithError.length > 0 && (
                <List
                    headline="Kurzy s chybou"
                    headlineAlignment="left"
                    columns={[
                        {
                            id: "id",
                            label: "id",
                        },
                        {
                            id: "sortId",
                            label: "sortId",
                            renderer: ({ sortId }) =>
                                isCourseSortIdInvalid(sortId) ? (
                                    <StyledInvalidValue>
                                        Sort ID musí být větší než 0
                                    </StyledInvalidValue>
                                ) : (
                                    sortId.toString()
                                ),
                        },
                    ]}
                    data={coursesWithError}
                />
            )}

            {lessonsWithError.length > 0 && (
                <List
                    headline="Lekce s chybou"
                    headlineAlignment="left"
                    columns={[
                        {
                            id: "id",
                            label: "id",
                        },
                        {
                            id: "sortId",
                            label: "sortId",
                            renderer: ({ id }) =>
                                isLessonSortIdInvalid(id) ? (
                                    <StyledInvalidValue>
                                        Chybí sort ID
                                    </StyledInvalidValue>
                                ) : (
                                    getStorageDataItem(id)?.sortId
                                ),
                        },
                        {
                            id: "thumbnailUrl",
                            label: "thumbnailUrl",
                            renderer: ({ id }) =>
                                isLessonThumbnailUrlInvalid(id) ? (
                                    <StyledInvalidValue>
                                        Chybí thumbnail URL
                                    </StyledInvalidValue>
                                ) : (
                                    getStorageDataItem(id)?.thumbnailUrl
                                ),
                        },
                        {
                            id: "animationUrl",
                            label: "animationUrl",
                            renderer: ({ id }) =>
                                isLessonAnimationUrlInvalid(id) ? (
                                    <StyledInvalidValue>
                                        Chybí animation URL
                                    </StyledInvalidValue>
                                ) : (
                                    getStorageDataItem(id)?.animationUrl
                                ),
                        },
                        {
                            id: "slidesIDs",
                            label: "slidesIDs",
                            renderer: ({ slidesIDs }) =>
                                isLessonSlideIDsInvalid(slidesIDs) ? (
                                    <StyledInvalidValue>
                                        Nemá přiřazené žádné slidy
                                    </StyledInvalidValue>
                                ) : (
                                    `Počet přiřazených slidů: ${slidesIDs.length}`
                                ),
                        },
                    ]}
                    data={lessonsWithError}
                />
            )}
        </Layout_Main>
    );
};
