import { IModalRefProps, Modal, StyledModalTitle } from "@nazorna-vyuka/ui";
import { forwardRef, RefAttributes } from "react";
import { Form_PasswordReset } from "../forms/PasswordReset";

export interface IProps {
    emailAddress: string | undefined;
    onSubmit: () => void;
}

export const Modal_PasswordReset: React.FC<
    IProps & RefAttributes<IModalRefProps>
> = forwardRef(({ emailAddress, onSubmit }, ref) => (
    <Modal ref={ref}>
        <StyledModalTitle level={3} alignment="left">
            Resetovat heslo
        </StyledModalTitle>

        <p>
            Doručení e-mailu může trvat až několik minut. Vyčkejte prosím, než
            se pokusíte opětovně resetovat heslo.
        </p>

        <Form_PasswordReset emailAddress={emailAddress} onSubmit={onSubmit} />
    </Modal>
));
