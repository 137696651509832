import { IModule } from "@nazorna-vyuka/types";
import styled from "styled-components";
import { MarkdownText } from "../../../atoms/MarkdownText";

const StyledHeadline = styled.h2<{ level: number }>`
    font-size: ${({ level }) => (level === 1 ? 24 : level === 2 ? 20 : 16)}px;
    margin: 10px 0;
`;

export const Module_Headline: React.FC<IModule & { level: number }> = ({
    id,
    text,
    level,
}) => (
    <StyledHeadline id={id} level={level}>
        <MarkdownText value={text} />
    </StyledHeadline>
);
