import { ISignedUser } from "@nazorna-vyuka/types";
import {
    Button,
    IModalRefProps,
    Modal,
    StyledModalButtonsGroup,
    StyledModalText,
    StyledModalTitle,
} from "@nazorna-vyuka/ui";
import { UsersAPI } from "@nazorna-vyuka/utils";
import { forwardRef, RefAttributes } from "react";

export type ISignedUserOwnershipDetails = ISignedUser & {
    subscription: {
        isActive: boolean;
        dueDate?: string;
    };
};

interface IProps {
    user: ISignedUserOwnershipDetails;
    onClose: () => void;
}

export const Modal_DeleteUserConfirmation: React.FC<
    IProps & RefAttributes<IModalRefProps>
> = forwardRef(({ user, onClose }, ref) => {
    const handleSubmit = () => {
        UsersAPI.delete(user.id).then(onClose);
    };

    return (
        <Modal ref={ref} onClose={onClose}>
            <StyledModalTitle level={3} alignment="left">
                Odebrat uživatele
            </StyledModalTitle>

            <StyledModalText>
                Opravdu si přejete uživatele {user.emailAddress} odebrat? Tato
                akce je nevratná.
            </StyledModalText>

            <StyledModalButtonsGroup>
                <Button label="Ano" color="red" onClick={handleSubmit} />
                <Button label="Ne" color="secondary" onClick={onClose} />
            </StyledModalButtonsGroup>
        </Modal>
    );
});
