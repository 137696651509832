export interface ITaskFull {
    TaskId: {
        $binary: {
            base64: string;
        };
    };
    Description: string;
    Difficulty: string;
    DurationInMinutes: number;
    Order: number;
    QuestionsCount: number;
    TaskKind: TTaskType;
    Title: string;
    OpenCode: number;
    IsDeleted: boolean;
}

export interface IModuleFull {
    Text: string;
    Url?: string;
    PreviewImageUrl?: string;
    Type: string;
    Order: number;
    QuestionModuleItems?: {
        Question: string;
        Answer: string;
        QuestionMarking: string;
    }[];
    SlideIndexes?: number[];
    IsDeleted: boolean;
}

export interface ISlideFull {
    FrontEndId: string;
    Modules: IModuleFull[];
    Order: number;
    IsDeleted: boolean;
}

export interface ILessonFull {
    Title: string;
    Description: string;
    Order: number;
    LessonGuide: {
        Slides?: ISlideFull[];
    };
    TeachBook?: {
        Modules: IModuleFull[];
    };
    Tasks: ITaskFull[];
    VideoURLs?: string[];
    IsDeleted?: boolean;
}

export interface ICourseFull {
    Id:
        | "matematika-5"
        | "matematika-prijimacky"
        | "fyzika-6"
        | "chemie-8"
        | "chemie-pokusy"
        | "informatika-i"
        | "nutricni-gramotnost"
        | "financni-gramotnost"
        | "medialni-gramotnost";
    Title: string;
    SubjectCode: string;
    ClassYear: number;
    Order: number;
    Lessons: ILessonFull[];
    IsDeleted: boolean;
}

export interface ICourse {
    id:
        | "matematika-5"
        | "matematika-prijimacky"
        | "fyzika-6"
        | "chemie-8"
        | "chemie-pokusy"
        | "informatika-i"
        | "nutricni-gramotnost"
        | "financni-gramotnost"
        | "medialni-gramotnost";
    title: string;
    subjectCode: string;
    classYear: number;
    lessonsIDs: string[];
    sortId: number;
}

export interface ILesson {
    id: string;
    title: string;
    description: string;
    slidesIDs: string[];
    tasksIDs: string[];
    teachBookModuleIDs: string[];
    videoURLs: string[];
}

export interface ISlide {
    id: string;
    moduleIDs: string[];
}

export type TModuleType =
    | "Activity"
    | "Assignment"
    | "Definition"
    | "Exercise"
    | "FileDownload"
    | "H1"
    | "H2"
    | "H3"
    | "H4"
    | "Image"
    | "Audio"
    | "Video"
    | "InteractiveTask"
    | "Interesting"
    | "Methodology"
    | "Note"
    | "NoteToNotebook"
    | "Paragraph"
    | "QuestionAnswer"
    | "SimulatorManual"
    | "Spacer"
    | "Table"
    | "PresentationSlides";

export interface IModule {
    id: string;
    text: string;
    url?: string;
    previewImageUrl?: string;
    slideIndexes?: number[];
    type: TModuleType;
    items?: {
        question: string;
        answer: string;
    }[];
}

export type TTaskType = "InteractiveTask" | undefined;

export interface ITask {
    id: string;
    title: string;
    description: string;
    type: TTaskType;
    order: number;
    difficulty: string;
    durationInMinutes: number;
    questionsCount: number;
    openCode: number;
    isAvailableInVimto: boolean;
}
