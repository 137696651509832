import { ISignedUser } from "./user";

export interface IInvitation {
    id: string;
    emailAddress: string;
    state:
        | "PENDING"
        | "SENT"
        | "SENT_FAILED"
        | "ACCEPTED"
        | "HOST_NOTIFIED"
        | "HOST_NOTIFIED_FAILED";
    createdBy: ISignedUser["id"];
}
