import { IFormInput } from "@nazorna-vyuka/types";
import { Form } from "@nazorna-vyuka/ui";
import {
    Authentication,
    EAuthErrorCodes,
    EAuthErrorTranslations,
    translateAuthError,
} from "@nazorna-vyuka/utils";
import { useMemo, useState } from "react";

export interface IProps {
    onSubmit: () => void;
}

interface IFormData {
    currentPassword: string;
    newPassword: string;
    newPasswordVerification: string;
}

export const Form_PasswordChange: React.FC<IProps> = ({ onSubmit }) => {
    const [errors, setErrors] = useState<{
        [inputId: string]: string | undefined;
    }>();
    const [isPasswordMismatched, setIsPasswordMismatched] = useState<boolean>();

    const handleSubmit = ({
        currentPassword,
        newPassword,
        newPasswordVerification,
    }: IFormData) => {
        if (newPassword === newPasswordVerification) {
            Authentication.updatePassword(currentPassword, newPassword)
                .then(onSubmit)
                .catch((error) => {
                    setErrors(
                        error.code === EAuthErrorCodes.WEAK_PASSWORD
                            ? {
                                  newPassword:
                                      EAuthErrorTranslations.WEAK_PASSWORD,
                              }
                            : translateAuthError(error.code),
                    );
                });
        } else {
            setIsPasswordMismatched(true);
        }
    };

    return (
        <Form
            inputs={useMemo<IFormInput[]>(
                () => [
                    {
                        id: "currentPassword",
                        label: "Původní heslo",
                        controlType: "input",
                        inputType: "password",
                        rules: {
                            required: true,
                        },
                        error: errors?.["currentPassword"],
                    },
                    {
                        id: "newPassword",
                        label: "Nové heslo",
                        controlType: "input",
                        inputType: "password",
                        rules: {
                            required: true,
                        },
                        error: errors?.["newPassword"],
                    },
                    {
                        id: "newPasswordVerification",
                        label: "Nové heslo (ověření)",
                        controlType: "input",
                        inputType: "password",
                        rules: {
                            required: true,
                        },
                        error: isPasswordMismatched
                            ? "Hesla se neshodují"
                            : errors?.["newPasswordVerification"],
                    },
                ],
                [errors, isPasswordMismatched],
            )}
            buttonsRenderer={(submit) => [
                {
                    label: "Změnit",
                    onClick: submit,
                },
            ]}
            onChange={() => setIsPasswordMismatched(false)}
            onSubmit={handleSubmit}
        />
    );
};
