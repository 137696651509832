import { Layout_Main } from "@nazorna-vyuka/ui";
import { Context, OwnershipsAPI, useCurrentPage } from "@nazorna-vyuka/utils";
import React, { useContext, useEffect, useState } from "react";
import { LessonsGrid } from "../../components/molecules/LessonsGrid";
import { StyledHeaderBox } from "./Home.styled";

export const Page_Home: React.FC = () => {
    const { searchQuery } = useContext(Context);

    const { user, isUserAuthenticated } = useCurrentPage({});

    const [isSubscriptionActive, setIsSubscriptionActive] = useState<boolean>();

    useEffect(() => {
        if (user) {
            OwnershipsAPI.getSubscriptionsStatus(user.id).then(
                ({ isActive }) => {
                    setIsSubscriptionActive(isActive);
                },
            );
        }
    }, [user]);

    return (
        <Layout_Main>
            {!isSubscriptionActive && (
                <StyledHeaderBox>
                    <h2>Vítá vás Edhance.</h2>
                    <p>
                        {isUserAuthenticated && user!.isOfficialDomain
                            ? "Po registraci jste obdrželi 750 kreditů, které vám postačí na vyzkoušení tří lekcí dle vašeho výběru. Tři lekce vám nestačí? "
                            : null}
                        Kontaktujte nás a my vám dočasně otevřeme všechny lekce.
                        Vy si tak můžete vše podrobně prohlédnout a vyzkoušet.
                        Volejte na 604 174 104 nebo pište na
                        tomas.kvasnicka@edhance.cz
                    </p>
                </StyledHeaderBox>
            )}

            <LessonsGrid
                filter={{
                    query: searchQuery,
                }}
                sort={{ alphabetically: true }}
            />
        </Layout_Main>
    );
};
