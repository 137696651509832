import styled from "styled-components";

export const StyledContainer = styled.div`
    height: 100%;
    padding: 18px 18px 18px 30px;
    cursor: pointer;

    img {
        height: 100%;
    }
`;
