import { IModule } from "@nazorna-vyuka/types";
import { useNavigation } from "@nazorna-vyuka/utils";
import styled from "styled-components";

const StyledImage = styled.img`
    width: 250px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    cursor: pointer;
`;

export const Module_FileDownload: React.FC<IModule> = ({
    text,
    url,
    previewImageUrl,
}) => {
    const navigateTo = useNavigation();

    return (
        <StyledImage
            src={previewImageUrl!}
            alt={text}
            onClick={() => navigateTo.url(url!, true)}
        />
    );
};
