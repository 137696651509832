import {
    convertDocsToItems,
    convertDocToItem,
    TQuery,
} from "@honzachalupa/firebase";
import { ILesson } from "@nazorna-vyuka/types";
import { Collections, Database } from "../utils/firebase";
import { log } from "../utils/logging";

export const LessonsAPI = {
    search: (query?: TQuery): Promise<ILesson[]> =>
        Database.search(Collections.lessons, query)
            .then(convertDocsToItems)
            .catch((error) => {
                log({
                    code: "EXCEPTION",
                    scope: "LessonsAPI",
                    error,
                });

                return [];
            }),

    get: (id: ILesson["id"]): Promise<ILesson> =>
        Database.get(Collections.lessons, id)
            .then(convertDocToItem)
            .catch((error) => error),
};
