import { CookieBanner as CookieBannerCore } from "@honzachalupa/ui";
import { IContext } from "@nazorna-vyuka/types";
import { Context } from "@nazorna-vyuka/utils";
import { useContext } from "react";

export const CookieBanner: React.FC = () => {
    const { setIsCookiesAllowed } = useContext<IContext>(Context);

    return <CookieBannerCore onSubmit={setIsCookiesAllowed} />;
};
