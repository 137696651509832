import { TabsView, Title } from "@nazorna-vyuka/ui";
import styled from "styled-components";

export const StyledContainer = styled.div`
    display: flex;
`;

export const StyledTitle = styled(Title)`
    font-size: 24px;
    margin-bottom: 20px;
`;

export const StyledTabsView = styled(TabsView)`
    height: 100%;
    margin: 0 -30px;
    display: flex;
    flex-direction: column;

    header {
        padding-left: 15px;
    }

    article {
        flex-basis: 100%;
        background: white;
        padding: 30px;
    }
`;

export const StyledContentContainer = styled.div`
    width: 60%;
    padding-right: 30px;
`;

export const StyledAnimationContainer = styled.div`
    width: 40%;
`;
