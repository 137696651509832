import { ERoles } from "./roles";

export interface ISignedUser {
    id: string;
    emailAddress: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    schoolName: string;
    createdDate: string;
    updatedDate: string;
    role: ERoles;
    sharedAccessCode?: number;
    isOfficialDomain: boolean;
}
