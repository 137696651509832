import { FirebaseConnector, Firestore } from "@honzachalupa/firebase";
import { TFirebaseCollection } from "@nazorna-vyuka/types";
import { log } from "./logging";

const { firestore, ...connector } = new FirebaseConnector(
    {
        apiKey: FIREBASE_API_KEY,
        authDomain: FIREBASE_AUTH_DOMAIN,
        projectId: FIREBASE_PROJECT_ID,
        storageBucket: FIREBASE_STORAGE_BUCKET,
        messagingSenderId: FIREBASE_MESSAGING_SENDER_ID,
        appId: FIREBASE_APP_ID,
        measurementId: FIREBASE_MEASUREMENT_ID,
    },
    log,
);

export const where = Firestore.where;
export const limit = Firestore.limit;
export const orderBy = Firestore.orderBy;
export const Authentication = connector.Authentication;
export const Database = connector.Database;
export const Storage = connector.Storage;
export const Analytics = connector.Analytics;

export const Collections: {
    [key in TFirebaseCollection]: any;
} = {
    // Users data
    users: Firestore.collection(firestore, "users"),
    invitations: Firestore.collection(firestore, "invitations"),
    ownerships: Firestore.collection(firestore, "ownerships"),
    creditTransactions: Firestore.collection(firestore, "credit-transactions"),

    // Courses data
    courses: Firestore.collection(firestore, "courses"),
    lessons: Firestore.collection(firestore, "lessons"),
    slides: Firestore.collection(firestore, "slides"),
    modules: Firestore.collection(firestore, "modules"),
    teachBookModules: Firestore.collection(firestore, "teach-book-modules"),
    tasks: Firestore.collection(firestore, "tasks"),

    // Other:
    config: Firestore.collection(firestore, "config"),
};

export enum EAuthErrorCodes {
    INVALID_EMAIL = "auth/invalid-email",
    USER_NOT_FOUND = "auth/user-not-found",
    EMAIL_ALREADY_IN_USE = "auth/email-already-in-use",
    WRONG_PASSWORD = "auth/wrong-password",
    WEAK_PASSWORD = "auth/weak-password",
    TOO_MANY_ATTEMPTS = "auth/too-many-requests",
}

export enum EAuthErrorTranslations {
    INVALID_EMAIL = "Zadaná e-mailová adresa není platná.",
    USER_NOT_FOUND = "Uživatel s tímto e-mailem neexistuje",
    EMAIL_ALREADY_IN_USE = "Uživatel s tímto e-mailem již existuje",
    WRONG_PASSWORD = "Zadané heslo je chybné",
    WEAK_PASSWORD = "Zvolte bezpečnější heslo",
    TOO_MANY_ATTEMPTS = "Byl překročen povolený počet pokusů - přihlášení bylo dočasně zablokováno.",
}

export const translateAuthError = (
    errorCode: string,
): {
    emailAddress?: string;
    password?: string;
} => {
    const errorMessage = {
        [EAuthErrorCodes.INVALID_EMAIL]: {
            emailAddress: EAuthErrorTranslations.INVALID_EMAIL,
        },
        [EAuthErrorCodes.USER_NOT_FOUND]: {
            emailAddress: EAuthErrorTranslations.USER_NOT_FOUND,
        },
        [EAuthErrorCodes.EMAIL_ALREADY_IN_USE]: {
            emailAddress: EAuthErrorTranslations.EMAIL_ALREADY_IN_USE,
        },
        [EAuthErrorCodes.WRONG_PASSWORD]: {
            password: EAuthErrorTranslations.WRONG_PASSWORD,
        },
        [EAuthErrorCodes.WEAK_PASSWORD]: {
            password: EAuthErrorTranslations.WEAK_PASSWORD,
        },
        [EAuthErrorCodes.TOO_MANY_ATTEMPTS]: {
            password: EAuthErrorTranslations.TOO_MANY_ATTEMPTS,
        },
    }[errorCode];

    if (!errorMessage) {
        log({
            code: "INFO",
            scope: "translateAuthError",
            error: new Error(`Unknown error code: ${errorCode}`),
        });
    }

    return errorMessage!;
};
