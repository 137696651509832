import { Form as FormCore, IFormProps } from "@honzachalupa/ui";
import { getTestId } from "@honzachalupa/utils";
import { IFormInput } from "@nazorna-vyuka/types";
import { forwardRef } from "react";

export interface IFormData {
    [key: string]: IFormInput["value"] | Array<IFormInput["value"]>;
}

export const Form = forwardRef((props: IFormProps, ref) => (
    <FormCore ref={ref} {...getTestId(Form.name)} {...props} />
));
