import { ReactNode, useState } from "react";
import ReactCollapsible from "react-collapsible";
import {
    StyledContainer,
    StyledIconContainer,
    StyledText,
    StyledToggleIcon,
} from "./CollapsibleBlock.styled";

interface IProps {
    label: string;
    icon?: any;
    color: string;
    children: ReactNode;
}

export const CollapsibleBlock: React.FC<IProps> = ({
    label,
    icon,
    color,
    children,
}) => {
    const [isOpened, setIsOpened] = useState<boolean>();

    return (
        <StyledContainer color={color} hasIcon={!!icon}>
            {icon ? <StyledIconContainer>{icon}</StyledIconContainer> : null}

            <StyledToggleIcon name={isOpened ? "arrowTop" : "arrowDown"} />

            <ReactCollapsible
                trigger={label}
                transitionTime={250}
                easing="ease-in-out"
                onTriggerOpening={() => setIsOpened(true)}
                onTriggerClosing={() => setIsOpened(false)}
            >
                <StyledText>{children}</StyledText>
            </ReactCollapsible>
        </StyledContainer>
    );
};
