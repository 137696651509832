import { DATE_FORMAT } from "@honzachalupa/utils";
import { ERoleLabels, ERoles, ISignedUser } from "@nazorna-vyuka/types";
import {
    Button,
    ButtonsGroup,
    Form,
    IModalRefProps,
    Modal,
    StyledModalTitle,
    Title,
} from "@nazorna-vyuka/ui";
import { Context, OwnershipsAPI, UsersAPI } from "@nazorna-vyuka/utils";
import moment from "moment";
import {
    forwardRef,
    Fragment,
    RefAttributes,
    useContext,
    useEffect,
    useState,
} from "react";

export type ISignedUserOwnershipDetails = ISignedUser & {
    subscription: {
        isActive: boolean;
        dueDate?: string;
    };
    parentDisponents: ISignedUser[];
};

interface IProps {
    user: ISignedUserOwnershipDetails;
    onClose: () => void;
}

export const Modal_ManageUser: React.FC<
    IProps & RefAttributes<IModalRefProps>
> = forwardRef(({ user, onClose }, ref) => {
    const { user: currentUser } = useContext(Context);

    const [isSubscriptionActive, setIsSubscriptionActive] = useState<boolean>();
    const [isAdmin, setIsAdmin] = useState<boolean>();
    const [dueDate, setDueDate] = useState<string>();

    const handleActivateSubscription = () => {
        OwnershipsAPI.activateSubscriptions(user.id, {
            dueDate: moment(dueDate, DATE_FORMAT).format(),
        }).then(() => setIsSubscriptionActive(true));
    };

    const handleDeactivateSubscription = () => {
        OwnershipsAPI.deactivateSubscriptions(user.id).then(() =>
            setIsSubscriptionActive(false),
        );
    };

    const handleSetAdmin = () => {
        UsersAPI.setRole(user.id, ERoles.ADMIN).then(() => setIsAdmin(true));
    };

    const handleUnsetAdmin = () => {
        UsersAPI.setRole(user.id, ERoles.USER).then(() => setIsAdmin(false));
    };

    useEffect(() => {
        setIsSubscriptionActive(user.subscription?.isActive);
        setIsAdmin(user.role === ERoles.ADMIN);
    }, [user]);

    return (
        <Modal ref={ref} onClose={onClose} height={800}>
            <StyledModalTitle level={3} alignment="left">
                Spravovat uživatele
            </StyledModalTitle>

            <p>E-mailová adresa: {user.emailAddress}</p>
            <p>
                Aktivní předplatné:{" "}
                {isSubscriptionActive
                    ? `Ano (do ${
                          dueDate ||
                          moment(user.subscription.dueDate).format(DATE_FORMAT)
                      })`
                    : "Ne"}
            </p>
            <p>Role: {isAdmin ? ERoleLabels.ADMIN : ERoleLabels.USER}</p>

            <br />

            <Title level={4} alignment="left">
                Předplatné
            </Title>

            <ButtonsGroup alignment="left">
                {isSubscriptionActive ? (
                    <Button
                        label="Deaktivovat předplatné"
                        onClick={handleDeactivateSubscription}
                    />
                ) : (
                    <Fragment>
                        <Form
                            inputs={[
                                {
                                    controlType: "input",
                                    id: "dueDate",
                                    placeholder: "Platnost do",
                                    inputType: "date",
                                },
                            ]}
                            onChange={({ dueDate }: { dueDate: string }) => {
                                setDueDate(dueDate);
                            }}
                        />

                        <Button
                            label="Aktivovat předplatné"
                            isDisabled={!dueDate}
                            onClick={handleActivateSubscription}
                        />
                    </Fragment>
                )}
            </ButtonsGroup>

            <br />

            <Title level={4} alignment="left">
                Role
            </Title>

            <ButtonsGroup alignment="left">
                {isAdmin ? (
                    <Button
                        label="Odebrat roli administrátor"
                        isDisabled={
                            user.emailAddress === currentUser?.emailAddress ||
                            user.emailAddress === "jan.chalupa@edhance.cz"
                        }
                        onClick={handleUnsetAdmin}
                    />
                ) : (
                    <Button
                        label="Přidělit roli administrátor"
                        isDisabled={!user.emailAddress?.includes("@edhance.cz")}
                        onClick={handleSetAdmin}
                    />
                )}
            </ButtonsGroup>
        </Modal>
    );
});
