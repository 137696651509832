import {
    convertDocsToItems,
    convertDocToItem,
    TQuery,
} from "@honzachalupa/firebase";
import { ICourse } from "@nazorna-vyuka/types";
import { Collections, Database } from "../utils/firebase";
import { log } from "../utils/logging";

export const CoursesAPI = {
    search: (query?: TQuery): Promise<ICourse[]> =>
        Database.search(Collections.courses, query)
            .then(convertDocsToItems)
            .then((items) =>
                items.sort((a: ICourse, b: ICourse) =>
                    a.sortId > b.sortId ? 1 : -1,
                ),
            )
            .catch((error) => {
                log({
                    code: "EXCEPTION",
                    scope: "CoursesAPI",
                    error,
                });

                return [];
            }),

    get: (id: ICourse["id"]): Promise<ICourse> =>
        Database.get(Collections.courses, id)
            .then(convertDocToItem)
            .catch((error) => error),
};
