export type TFirebaseCollection =
    | "users"
    | "invitations"
    | "ownerships"
    | "creditTransactions"
    | "courses"
    | "lessons"
    | "slides"
    | "modules"
    | "teachBookModules"
    | "tasks"
    | "config";
