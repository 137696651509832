import { Layout_Main, Title } from "@nazorna-vyuka/ui";
import { useCurrentPage } from "@nazorna-vyuka/utils";
import React from "react";
import content from "./TermsAndConditions.content";
import { StyledContent, StyledHeadline } from "./TermsAndConditions.styled";

export const Page_TermsAndConditions: React.FC = () => {
    const { title } = useCurrentPage({ title: "Obchodní podmínky" });

    const Content = () => (
        <ol>
            {content.map((chapter) => (
                <li>
                    <StyledHeadline>{chapter.headline}</StyledHeadline>

                    {chapter.ol && (
                        <ol>
                            {chapter.ol.map((item) => (
                                <li>
                                    {item.text}

                                    {item.ol && (
                                        <ol>
                                            {item.ol.map((item) => (
                                                <li>{item.text}</li>
                                            ))}
                                        </ol>
                                    )}

                                    {item.ul && (
                                        <ul>
                                            {item.ul.map((item) => (
                                                <li>{item.text}</li>
                                            ))}
                                        </ul>
                                    )}
                                </li>
                            ))}
                        </ol>
                    )}

                    {chapter.ul && (
                        <ul>
                            {chapter.ul.map((item) => (
                                <li>
                                    {item.text}

                                    {item.ol && (
                                        <ol>
                                            {item.ol.map((item) => (
                                                <li>{item.text}</li>
                                            ))}
                                        </ol>
                                    )}

                                    {item.ul && (
                                        <ul>
                                            {item.ul.map((item) => (
                                                <li>{item.text}</li>
                                            ))}
                                        </ul>
                                    )}
                                </li>
                            ))}
                        </ul>
                    )}
                </li>
            ))}
        </ol>
    );

    /* const renderList = (item: any, isRoot?: true) => {
        const content = Object.entries(item).map(([key, value]: any, i) => (
            <li key={i}>
                {key === "headline" ? (
                    <StyledHeadline>{value}</StyledHeadline>
                ) : key === "ol" ? (
                    <ol>{renderList(value)}</ol>
                ) : key === "ul" ? (
                    <ul>{renderList(value)}</ul>
                ) : (
                    value
                )}
            </li>
        ));

        return isRoot ? <ol>{content}</ol> : content;
    }; */

    return (
        <Layout_Main>
            <Title level={1} alignment="left">
                {title}
            </Title>

            <StyledContent>
                <Content />
            </StyledContent>
        </Layout_Main>
    );
};
