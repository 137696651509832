import { forwardRef, RefAttributes } from "react";
import { Button, IModalRefProps, Modal } from "../../../";
import {
    StyledModalButtonsGroup,
    StyledModalText,
    StyledModalTitle,
} from "../../atoms/Modal";

export interface IProps {
    onSubmit: () => void;
}

export const Modal_SignOutConfirmation: React.FC<
    IProps & RefAttributes<IModalRefProps>
> = forwardRef(({ onSubmit }, ref) => (
    <Modal ref={ref}>
        <StyledModalTitle level={3} alignment="left">
            Odhlásit se
        </StyledModalTitle>

        <StyledModalText>Opravdu si přejete odhlásit?</StyledModalText>

        <StyledModalButtonsGroup>
            <Button label="Odhlásit" onClick={onSubmit} />
        </StyledModalButtonsGroup>
    </Modal>
));
