import { TModuleType } from "@nazorna-vyuka/types";
import styled from "styled-components";

export const StyledContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
`;

export const StyledContent = styled.div`
    width: 60%;
`;

export const StyledOverview = styled.div`
    width: 40%;
    margin-left: 40px;
    position: sticky;
    top: 20px;
`;

export const StyledOverview_Headline = styled.p`
    font-family: "Graphik";
    font-size: 24px;
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 20px;
`;

export const StyledContent_ItemsContainer = styled.ul`
    border-left: 3px solid lightgray;
    padding-left: 20px;
`;

export const StyledOverview_Item = styled.li<{ type: TModuleType }>`
    font-size: 15px;
    font-weight: 600;
    list-style: none;
    margin-left: ${({ type }) => (type === "H2" ? 20 : 0)}px;
    padding: 8px 0;
    opacity: 0.4;
    cursor: pointer;

    &:hover {
        opacity: 0.6;
    }
`;
