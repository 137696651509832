import {
    ICourse,
    ICourseFull,
    ILesson,
    ILessonFull,
    IModule,
    IModuleFull,
    ITask,
    ITaskFull,
    TModuleType,
} from "@nazorna-vyuka/types";

export const generateKey = (namespace: string, i: number, parentKey?: string) =>
    [parentKey || "", `${namespace}-${i + 1}`].join("_");

export const cleanCourse = (item: ICourseFull): ICourse => ({
    id: item.Id,
    title: item.Title,
    subjectCode: item.SubjectCode,
    classYear: item.ClassYear,
    lessonsIDs: [],
    sortId: item.Order || 0,
});

export const cleanLesson = (
    item: ILessonFull,
    i: number,
    parentKey: string,
): ILesson => ({
    id: generateKey("lesson", i, parentKey),
    title: item.Title,
    description: item.Description,
    slidesIDs: [],
    tasksIDs: [],
    teachBookModuleIDs: [],
    videoURLs: item.VideoURLs || [],
});

export const cleanModule = (
    item: IModuleFull,
    i: number,
    parentKey: string,
): IModule => ({
    id: generateKey("module", i, parentKey),
    text: item.Text,
    url: item.Url,
    previewImageUrl: item.PreviewImageUrl,
    slideIndexes: item.SlideIndexes,
    type: item.Type as TModuleType,
    items: item.QuestionModuleItems?.map(({ Question, Answer }) => ({
        question: Question,
        answer: Answer,
    })),
});

export const cleanTeachBookModule = (
    item: IModuleFull,
    i: number,
    parentKey: string,
): IModule => ({
    id: generateKey("teach-book-module", i, parentKey),
    text: item.Text,
    url: item.Url,
    previewImageUrl: item.PreviewImageUrl,
    slideIndexes: item.SlideIndexes,
    type: item.Type as TModuleType,
    items: item.QuestionModuleItems?.map(({ Question, Answer }) => ({
        question: Question,
        answer: Answer,
    })),
});

export const cleanTask = (
    item: ITaskFull,
    i: number,
    parentKey: string,
    vimtoTaskIDs: string[],
): ITask => ({
    id: generateKey("task", i, parentKey),
    title: item.Title,
    description: item.Description,
    type: item.TaskKind,
    order: item.Order,
    difficulty: item.Difficulty,
    durationInMinutes: item.DurationInMinutes,
    questionsCount: item.QuestionsCount,
    openCode: item.OpenCode,
    isAvailableInVimto: item.TaskId?.$binary?.base64
        ? vimtoTaskIDs.includes(item.TaskId.$binary.base64)
        : false,
});
