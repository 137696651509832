import {
    ERoutePaths,
    ICourse,
    ILesson,
    ISlide,
    ITask,
} from "@nazorna-vyuka/types";
import { Animation, Breadcrumbs, Layout_Main, Loader } from "@nazorna-vyuka/ui";
import {
    Context,
    CoursesAPI,
    SlidesAPI,
    TasksAPI,
    log,
    useCurrentPage,
} from "@nazorna-vyuka/utils";
import {
    Fragment,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react";
import {
    StyledAnimationContainer,
    StyledContainer,
    StyledContentContainer,
    StyledTabsView,
    StyledTitle,
} from "./LessonDetail.styled";
import { useData } from "./LessonDetail.useData";
import { Content } from "./components/Content";
import { TasksListVimto } from "./components/TasksListVimto";
import { TeachBook } from "./components/TeachBook";
import { Videos } from "./components/Videos";

const ContentWrapper: React.FC<{
    courseId: ICourse["id"];
    lesson: ILesson;
}> = ({ courseId, lesson }) => {
    const [course, setCourse] = useState<ICourse>();
    const [slide, setSlide] = useState<ISlide>();
    const [index, setIndex] = useState<number>(0);
    const [tasks, setTasks] = useState<ITask[]>([]);
    const [tasksVimto, setTasksVimto] = useState<ITask[]>([]);

    const getTasks = async () => {
        await Promise.all(
            lesson.tasksIDs.map((taskId) => TasksAPI.get(taskId)),
        ).then((data) => {
            setTasks(data);
            setTasksVimto(
                data?.filter(({ isAvailableInVimto }) => isAvailableInVimto),
            );
        });
    };

    useEffect(() => {
        const slideId = lesson.slidesIDs?.[index];

        if (slideId) {
            SlidesAPI.get(slideId)
                .then(setSlide)
                .catch((error) => {
                    log({
                        code: "EXCEPTION",
                        scope: "Page_LessonDetail",
                        error,
                    });
                });
        }
    }, [index]);

    useEffect(() => {
        getTasks();
    }, [lesson]);

    useEffect(() => {
        CoursesAPI.get(courseId)
            .then(setCourse)
            .catch((error) => {
                log({
                    code: "EXCEPTION",
                    scope: "Page_LessonDetail",
                    error,
                });
            });
    }, []);

    const setIndexCallback = useCallback(setIndex, []);

    const tabs = useMemo(
        () =>
            [
                {
                    label: "Průvodce lekcí",
                    children: (
                        <StyledContainer>
                            <StyledContentContainer>
                                {slide ? (
                                    <Content
                                        lessonId={lesson.id}
                                        moduleIDs={slide.moduleIDs || []}
                                    />
                                ) : (
                                    <Loader />
                                )}
                            </StyledContentContainer>

                            <StyledAnimationContainer>
                                <Animation
                                    lessonId={lesson.id}
                                    onFrameChange={setIndexCallback}
                                />
                            </StyledAnimationContainer>
                        </StyledContainer>
                    ),
                    isVisible:
                        !!slide &&
                        slide.moduleIDs?.length > 0 &&
                        courseId !== "matematika-5",
                },
                {
                    label: "Video",
                    children: <Videos urls={lesson.videoURLs} />,
                    isVisible: lesson.videoURLs?.length > 0,
                },
                {
                    label: "Studovna",
                    children: (
                        <TeachBook
                            lessonId={lesson.id}
                            moduleIDs={lesson.teachBookModuleIDs || []}
                        />
                    ),
                    isVisible: lesson.teachBookModuleIDs?.length > 0,
                },
                /* {
                    label: "Cvičení",
                    children: <TasksList tasks={tasks} />,
                    isVisible: tasks.length > 0,
                }, */
                {
                    label: "Cvičení ve třídě",
                    children: course ? (
                        <TasksListVimto
                            subjectCode={course.subjectCode}
                            classYear={course.classYear}
                            tasks={tasksVimto}
                        />
                    ) : null,
                    isVisible: course && tasksVimto.length > 0,
                },
            ].filter(({ isVisible }) => isVisible),
        [lesson, slide, tasks, tasksVimto],
    );

    return tabs.length > 0 ? (
        <StyledTabsView labels={tabs.map(({ label }) => label)}>
            {tabs.map(({ label, children }) => (
                <Fragment key={label}>{children}</Fragment>
            ))}
        </StyledTabsView>
    ) : null;
};

export const Page_LessonDetail: React.FC = () => {
    const { params } = useCurrentPage({
        title: "Lekce",
    });

    const { storageData } = useContext(Context);

    const [breadcrumbs, setBreadcrumbs] = useState<any>([]);

    const courseId = params["courseId"] as ICourse["id"];
    const lessonTitle = params["lessonTitle"] as ILesson["title"];

    const { course, lesson } = useData({
        courseId,
        lessonTitle,
    });

    const sortId = useMemo(
        () =>
            storageData.find((item) => item.lessonId === lesson?.id)?.sortId ||
            0,
        [lesson, storageData],
    );

    useEffect(() => {
        if (course && lesson) {
            setBreadcrumbs([
                {
                    label: "Home",
                    path: ERoutePaths.INDEX,
                },
                {
                    label: course.title,
                    path: ERoutePaths.COURSE.replace(":courseId", courseId),
                },
                { label: lesson.title },
            ]);
        }
    }, [course, lesson]);

    return (
        <Layout_Main>
            <Breadcrumbs paths={breadcrumbs} data-test-id="drobek" />

            <StyledTitle level={2}>
                {sortId}. {lesson?.title}
            </StyledTitle>

            {course && lesson ? (
                <ContentWrapper
                    key={lesson.id}
                    courseId={course.id}
                    lesson={lesson}
                />
            ) : (
                <Loader />
            )}
        </Layout_Main>
    );
};
