import ReactMarkdown from "react-markdown";
import styled from "styled-components";

export const StyledReactMarkdown = styled(ReactMarkdown)`
    div,
    img,
    p,
    span {
        max-width: 100% !important;
        background: transparent !important;
        color: black !important;
        font-family: "Graphik" !important;
        padding: 0 !important;
        margin: 0 !important;
    }

    ul,
    ol {
        padding-left: 50px;
    }
`;
