import { ButtonsGroup } from "@nazorna-vyuka/ui";
import styled from "styled-components";

export const StyledContent = styled.div`
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
`;

export const StyledButtonsGroup = styled(ButtonsGroup)`
    margin: 30px 0;
`;

export const StyledNote = styled.p`
    color: #aaaaaa;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    border-top: 1px solid lightgray;
    padding-top: 15px;
    margin-top: 25px;
`;
