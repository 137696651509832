import {
    Button,
    ButtonsGroup,
    IModalRefProps,
    Modal,
    StyledModalTitle,
} from "@nazorna-vyuka/ui";
import { InvitationsAPI, useAuthentication } from "@nazorna-vyuka/utils";
import { forwardRef, RefAttributes } from "react";

export interface IProps {
    emailAddress: string | undefined;
    onSubmit: () => void;
}

export const Modal_DisponentInvitation: React.FC<
    IProps & RefAttributes<IModalRefProps>
> = forwardRef(({ emailAddress, onSubmit }, ref) => {
    const { user } = useAuthentication();

    const handleSubmit = () => {
        if (user && emailAddress) {
            InvitationsAPI.create({
                emailAddress,
                createdBy: user.id,
            }).then(() => {
                // TODO: Show confirmation modal
            });
        }

        onSubmit();
    };

    return (
        <Modal ref={ref}>
            <StyledModalTitle level={3} alignment="left">
                Uživatel neexistuje
            </StyledModalTitle>

            <p>
                Uživatel s e-mailovou adresou {emailAddress} zatím Názornou
                výuku nepoužívá.
            </p>

            <p>Chcete mu odeslat pozvánku?</p>

            <ButtonsGroup>
                <Button label="Odeslat pozvánku" onClick={handleSubmit} />
            </ButtonsGroup>
        </Modal>
    );
});
