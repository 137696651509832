import { ERoutePaths } from "@nazorna-vyuka/types";
import { Route } from "react-router-dom";
import { Page_InvitationAccept } from "./InvitationAccept";

export default [
    <Route
        path={ERoutePaths.INVITATION_ACCEPT}
        element={<Page_InvitationAccept />}
    />,
];
