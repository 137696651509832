module.exports = {
    name: "Názorná výuka",
    nameShort: "Názorná výuka",
    description: "Snadná interaktivní výuka",
    keywords:
        "výuka,vzdělávání,interaktivní,tabule,animace,video,matematika,matika,fyzika,chemie,informatika",
    backgroundColor: "#E4E0EB",
    accentColor: "#21b7cd",
    developerName: "Edhance s.r.o.",
    developerUrl: "https://www.edhance.cz/",
    supportEmailAddress: "info@edhance.cz",
    logtailTokenDev: "7vhxsXSS36YxAnvpNJ7329R4",
    logtailTokenTest: "TvkevyRxh9VUvXUa2U4vK31z",
    logtailTokenProd: "tCpsyhXhyDFe5z53qVEPs88z",
    caching: true,
};
