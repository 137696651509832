import rehypeRaw from "rehype-raw";
import gfm from "remark-gfm";
import { StyledReactMarkdown } from "./MarkdownText.styled";

interface IProps {
    value: string;
    className?: string;
}

export const MarkdownText: React.FC<IProps> = ({ value, className }) => (
    <StyledReactMarkdown
        rehypePlugins={[rehypeRaw]}
        remarkPlugins={[gfm]}
        className={className}
    >
        {value}
    </StyledReactMarkdown>
);
