import {
    replaceAccentedCharacters,
    sortAlphabetically,
} from "@honzachalupa/utils";
import { Icons } from "@nazorna-vyuka/assets";
import { ICourse, ILesson } from "@nazorna-vyuka/types";
import { IModalRefProps, Loader } from "@nazorna-vyuka/ui";
import {
    Context,
    courseIcons,
    CoursesAPI,
    formatUrlTitle,
    LessonsAPI,
    OwnershipsAPI,
    useAuthentication,
    useNavigation,
} from "@nazorna-vyuka/utils";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Modal_LessonActivation } from "../../organisms/modals/LessonActivation";
import { ModalMessage } from "../ModalMessage";
import { AuthenticationStatus } from "./AuthenticationStatus";
import {
    StyledItem,
    StyledItemsContainer,
    StyledItem_IconsContainer,
    StyleItem_Content,
    StyleItem_IconContainer,
    StyleItem_Image,
    StyleItem_Name,
    StyleItem_Number,
} from "./LessonsGrid.styled";

type IData = (ILesson & { CourseID: ICourse["id"] })[];

interface IProps {
    filter?: {
        query?: string;
        courseIDs?: string[];
        lessonIDs?: string[];
    };
    sort?: {
        alphabetically: boolean;
    };
}

const LessonsGrid_Item: React.FC<{
    lesson: IData[0];
    unlockedLessonIDs: ILesson["id"][];
    isSubscriptionActive: boolean | undefined;
    handleOpenCourse: (
        courseId: string,
        lessonId: string,
        sortId: number,
        lessonTitle: string,
    ) => void;
}> = ({
    lesson: { id, title, CourseID },
    unlockedLessonIDs,
    isSubscriptionActive,
    handleOpenCourse,
}) => {
    const { storageData } = useContext(Context);

    const isLocked = useMemo(
        () => !isSubscriptionActive && !unlockedLessonIDs.includes(id),
        [isSubscriptionActive, unlockedLessonIDs, id],
    );

    const thumbnailUrl = useMemo(
        () => storageData.find((item) => item.lessonId === id)?.thumbnailUrl,
        [id, storageData],
    );

    const sortId = useMemo(
        () => storageData.find((item) => item.lessonId === id)?.sortId || 0,
        [id, storageData],
    );

    return (
        <StyledItem
            key={id}
            onClick={() => handleOpenCourse(CourseID, id, sortId, title)}
            data-test-id="menu"
        >
            <StyleItem_Content>
                <StyleItem_Image src={thumbnailUrl} />

                <StyleItem_Name>{title}</StyleItem_Name>

                <StyleItem_Number>Lekce {sortId}</StyleItem_Number>

                <StyledItem_IconsContainer>
                    <StyleItem_IconContainer>
                        <img src={courseIcons[CourseID]} alt="" />
                    </StyleItem_IconContainer>

                    {isLocked && (
                        <StyleItem_IconContainer
                            color="#ED9082"
                            data-test-id="zamek"
                        >
                            <img src={Icons.lock} alt="Uzamčeno" />
                        </StyleItem_IconContainer>
                    )}
                </StyledItem_IconsContainer>
            </StyleItem_Content>
        </StyledItem>
    );
};

export const LessonsGrid: React.FC<IProps> = ({ filter, sort }) => {
    const navigateTo = useNavigation();
    const {
        user,
        signedAsUserId,
        isUserAuthenticated,
        isUserAuthenticatedAnonymously,
        isUserInfoCompleted,
    } = useAuthentication();

    const { storageData } = useContext(Context);

    const modalMessageRef1 = useRef<IModalRefProps>(null);
    const modalMessageRef2 = useRef<IModalRefProps>(null);
    const purchaseLessonModalRef = useRef<IModalRefProps>(null);

    const [lessons, setLessons] = useState<IData>([]);
    const [lessonActivationData, setLessonActivationData] = useState<{
        courseId: ICourse["id"];
        lessonId: ILesson["id"];
        lessonTitle: ILesson["title"];
    }>();
    const [unlockedLessonIDs, setUnlockedLessonIDs] = useState<ILesson["id"][]>(
        [],
    );
    const [isSubscriptionActive, setIsSubscriptionActive] = useState<boolean>();
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const getOwnedLessons = () => {
        if (isUserAuthenticated || isUserAuthenticatedAnonymously) {
            OwnershipsAPI.getUnlockedLessons(user?.id || signedAsUserId!).then(
                (lessons) => {
                    setUnlockedLessonIDs(
                        lessons.map(({ lessonId }) => lessonId),
                    );
                },
            );
        }
    };

    const getSubscriptionStatus = () => {
        if (isUserAuthenticated || isUserAuthenticatedAnonymously) {
            OwnershipsAPI.getSubscriptionsStatus(
                user?.id || signedAsUserId!,
            ).then((status) => {
                setIsSubscriptionActive(status.isActive);
            });
        }
    };

    const filterLessons = (lesson: ILesson, courseId: ICourse["id"]) => {
        if (filter?.query) {
            return (
                !filter.query ||
                replaceAccentedCharacters(lesson.title.toLowerCase()).includes(
                    replaceAccentedCharacters(filter.query.toLowerCase()),
                )
            );
        }

        if (filter?.courseIDs && filter?.courseIDs?.length > 0) {
            return filter.courseIDs.includes(courseId);
        }

        if (filter?.lessonIDs && filter?.lessonIDs?.length > 0) {
            return filter.lessonIDs.includes(lesson.id);
        }

        return true;
    };

    const searchLessons = () => {
        setIsLoading(true);

        CoursesAPI.search().then((courses) => {
            const lessonsArray: IData = [];

            Promise.all(
                courses.map((course) =>
                    Promise.all(
                        course.lessonsIDs.map((lessonId) =>
                            LessonsAPI.get(lessonId),
                        ),
                    ).then((lessons) =>
                        lessons
                            .filter((lesson) =>
                                filterLessons(lesson, course.id),
                            )
                            .sort((a, b) => {
                                const sortId1 =
                                    storageData.find(
                                        ({ lessonId }) => lessonId === a.id,
                                    )?.sortId || 0;

                                const sortId2 =
                                    storageData.find(
                                        ({ lessonId }) => lessonId === b.id,
                                    )?.sortId || 0;

                                return sortId1 - sortId2;
                            })
                            .forEach((lesson) => {
                                lessonsArray.push({
                                    ...lesson,
                                    CourseID: course.id,
                                });
                            }),
                    ),
                ),
            ).then(() => {
                setLessons(
                    lessonsArray.sort((a, b) =>
                        sort?.alphabetically
                            ? sortAlphabetically(a.title, b.title)
                            : 0,
                    ),
                );

                setIsLoading(false);
            });
        });
    };

    const handleOpenCourse = async (
        courseId: string,
        lessonId: string,
        sortId: number,
        lessonTitle: string,
    ) => {
        if (!isUserAuthenticated && !isUserAuthenticatedAnonymously) {
            modalMessageRef1.current?.show();
        } else if (!isUserInfoCompleted) {
            modalMessageRef2.current?.show();

            navigateTo.accountSetInitialDetails();
        } else {
            if (isSubscriptionActive || unlockedLessonIDs.includes(lessonId)) {
                navigateTo.lessonDetail(
                    courseId as ICourse["id"],
                    formatUrlTitle(sortId, lessonTitle),
                );
            } else {
                setLessonActivationData({
                    courseId: courseId as ICourse["id"],
                    lessonId,
                    lessonTitle,
                });

                purchaseLessonModalRef.current?.show();
            }
        }
    };

    useEffect(() => {
        if (storageData) {
            getOwnedLessons();
            getSubscriptionStatus();
            searchLessons();
        }
    }, [storageData, filter]);

    return (
        <div>
            <ModalMessage
                ref={modalMessageRef1}
                message="Musíte se přihlásit."
                onClose={navigateTo.signIn}
            />

            <ModalMessage
                ref={modalMessageRef2}
                message="Musíte vyplnit doplňující informace."
                onClose={navigateTo.accountSetInitialDetails}
            />

            {lessonActivationData && (
                <Modal_LessonActivation
                    ref={purchaseLessonModalRef}
                    courseId={lessonActivationData.courseId}
                    lessonId={lessonActivationData.lessonId}
                    lessonTitle={lessonActivationData.lessonTitle}
                />
            )}

            {!isLoading ? (
                <StyledItemsContainer>
                    <AuthenticationStatus />

                    {lessons.map((lesson) => (
                        <LessonsGrid_Item
                            key={lesson.id}
                            lesson={lesson}
                            unlockedLessonIDs={unlockedLessonIDs}
                            isSubscriptionActive={isSubscriptionActive}
                            handleOpenCourse={handleOpenCourse}
                        />
                    ))}
                </StyledItemsContainer>
            ) : (
                <Loader />
            )}
        </div>
    );
};
