import styled from "styled-components";

export const StyledContainer = styled.div<{ isFullscreenOn: boolean }>`
    ${({ isFullscreenOn }) =>
        isFullscreenOn &&
        `
        background: rgba(255, 255, 255, 0.6);
        border-radius: 10px;
        backdrop-filter: blur(5px);
        padding: 11px 5px;
        display: flex;

        position: absolute;
        bottom: 10px;
        z-index: 99;
    `}
`;

export const StyledSlidesCount = styled.p`
    width: 100%;
    font-size: 13px;
    text-align: center;
    line-height: 140%;
    opacity: 0.7;
    margin: 15px 0;
    padding: 0 10px;
`;
