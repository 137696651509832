import { IModule } from "@nazorna-vyuka/types";
import { RiSettings4Line } from "react-icons/ri";
import { CollapsibleBlock } from "../../../atoms/CollapsibleBlock";
import { MarkdownText } from "../../../atoms/MarkdownText";

export const Module_Activity: React.FC<IModule> = ({ text }) => (
    <CollapsibleBlock
        label="Aktivita"
        icon={<RiSettings4Line />}
        color="#F0D885"
    >
        <MarkdownText value={text} />
    </CollapsibleBlock>
);
