import { capitalize, initServiceWorker } from "@honzachalupa/utils";
import { IContext } from "@nazorna-vyuka/types";
import { translations } from "@nazorna-vyuka/ui";
import {
    Analytics,
    Authentication,
    config,
    isDevEnv,
    log,
    UsersAPI,
} from "@nazorna-vyuka/utils";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

export const initializeTranslations = () => {
    i18n.use(initReactI18next).init({
        resources: translations,
        lng: "cs",
        fallbackLng: "en",
        interpolation: {
            escapeValue: false,
        },
    });
};

export const onFirstLoad = (setState: any) => {
    console.info(
        "Version:",
        VERSION,
        "\nBuild timestamp:",
        BUILD_TIMESTAMP,
        "\nEnvironment:",
        ENVIRONMENT,
        "\nMode:",
        capitalize(MODE),
    );

    Authentication.onAuthStateChanged((authState) => {
        if (authState?.uid) {
            UsersAPI.get(authState.uid)
                .then((user) => {
                    Analytics.setUserId(authState.uid);

                    if (user?.schoolName && user?.emailAddress) {
                        Analytics.setUserProperties({
                            schoolName: user.schoolName,
                            emailAddress: user.emailAddress,
                        });
                    }

                    setState((prevState: IContext) => ({
                        ...prevState,
                        user,
                    }));
                })
                .catch((error) => {
                    log({
                        code: "EXCEPTION",
                        scope: "App",
                        error,
                    });
                });
        } else {
            setState((prevState: IContext) => ({
                ...prevState,
                user: null,
            }));
        }
    });

    if (config.caching && !isDevEnv) {
        initServiceWorker();
    }
};

export const onRouteChange = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
};
