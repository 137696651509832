import {
    Button,
    ButtonsGroup,
    HorizontalDivider,
    Layout_Main,
    Link,
    List,
    Title,
} from "@nazorna-vyuka/ui";
import {
    Context,
    CreditTransactionsAPI,
    useCurrentPage,
} from "@nazorna-vyuka/utils";
import React, { useContext, useEffect, useState } from "react";
import { Form_CreditTopUp } from "../../../components/organisms/forms/CreditTopUp";
import {
    StyledDescription,
    StyledFormContainer,
    StyledHeadline,
    StyledHelpContainer,
    StyledListContainer,
    StyledValue,
} from "./styled";

export const Page_AccountCredit: React.FC = () => {
    const { title, navigateTo } = useCurrentPage({ title: "Kredit" });
    const { user } = useContext(Context);

    const [creditAmount, setCreditAmount] = useState<number>();
    const [isCreditTopUpBlockShown, setIsCreditTopUpBlockShown] =
        useState<boolean>();

    const getCreditAmount = () => {
        CreditTransactionsAPI.get(user!.id).then((items) => {
            const sumAmount =
                items?.reduce(
                    (prevValue, { amount }) => prevValue + amount,
                    0,
                ) || 0;

            setCreditAmount(sumAmount);
        });
    };

    useEffect(() => {
        getCreditAmount();
    }, []);

    return (
        <Layout_Main>
            <Title level={1}>{title}</Title>

            <HorizontalDivider padding={100} isLineHidden>
                <div>
                    <StyledHeadline>Aktuální výše kreditu:</StyledHeadline>
                    <StyledValue>{creditAmount} bodů</StyledValue>

                    {isCreditTopUpBlockShown ? (
                        <div>
                            <StyledFormContainer>
                                <Form_CreditTopUp onSubmit={getCreditAmount} />
                            </StyledFormContainer>

                            <StyledDescription>
                                Kolik si mám nabít kreditu? 1 lekce = 250 bodů
                            </StyledDescription>

                            <StyledListContainer>
                                <List
                                    data={[
                                        {
                                            label: "Matematika pro 5. ročník",
                                            lessonsCount: 20,
                                            creditAmount: 5000,
                                        },
                                        {
                                            label: "Fyzika pro 6. ročník",
                                            lessonsCount: 15,
                                            creditAmount: 3750,
                                        },
                                        {
                                            label: "Chemie pro 8. ročník",
                                            lessonsCount: 32,
                                            creditAmount: 8000,
                                        },
                                        {
                                            label: "Informatika pro I. stupeň",
                                            lessonsCount: 15,
                                            creditAmount: 3750,
                                        },
                                        {
                                            label: "Celý obsah",
                                            lessonsCount: 82,
                                            creditAmount: 20500,
                                        },
                                    ]}
                                    columns={[
                                        {
                                            id: "label",
                                            label: "Předmět",
                                        },
                                        {
                                            id: "lessonsCount",
                                            label: "Počet lekcí",
                                            renderer: ({ lessonsCount }) =>
                                                `${lessonsCount} lekcí`,
                                        },
                                        {
                                            id: "creditAmount",
                                            label: "Potřebný kredit",
                                            renderer: ({ creditAmount }) =>
                                                `${creditAmount} bodů`,
                                        },
                                    ]}
                                />
                            </StyledListContainer>
                        </div>
                    ) : (
                        <ButtonsGroup>
                            <Button
                                label="Dobít kredit"
                                onClick={() => setIsCreditTopUpBlockShown(true)}
                            />
                        </ButtonsGroup>
                    )}
                </div>

                <StyledHelpContainer>
                    <li>Za nabitý kredit lze odemknout nové lekce.</li>

                    <li>
                        Odemčené lekce jsou k dispozici pro všechny disponenty.
                    </li>

                    <li>
                        Nastavení je možné v sekci “Disponenti”.{" "}
                        <Link
                            label="Přejít do sekce
                        Disponenti"
                            onClick={navigateTo.accountDisponents}
                        />
                    </li>

                    <li>
                        Lekce je odemknuta na 12 měsíců od zakoupení.{" "}
                        <b>Chcete získat lepší nabídku?</b> Napište nám e-mail a
                        my vám pošleme individuální nabídku.
                    </li>
                </StyledHelpContainer>
            </HorizontalDivider>
        </Layout_Main>
    );
};
