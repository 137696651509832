import { IAbstractObject, useLocalStorage } from "@honzachalupa/utils";
import { IFormInput } from "@nazorna-vyuka/types";
import { Form } from "@nazorna-vyuka/ui";
import { log, useNavigation, UsersAPI } from "@nazorna-vyuka/utils";
import { useMemo } from "react";

interface IFormData {
    code: number;
}

export const Form_SignInWithAccessCode: React.FC = () => {
    const navigateTo = useNavigation();

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, setAnonymousAuth] = useLocalStorage<IAbstractObject>(
        "anonymousAuth",
        {},
    );

    const handleSubmit = ({ code }: IFormData) => {
        UsersAPI.validateSharedAccessCode(code)
            .then((user) => {
                if (user) {
                    setAnonymousAuth({
                        id: user.id,
                    });

                    navigateTo.home();
                } else {
                    log({
                        code: "EXCEPTION",
                        scope: "Form_SignInWithAccessCode",
                        error: new Error(
                            `User with shared code "${code}" not found.`,
                        ),
                    });
                }
            })
            .catch((error) => {
                log({
                    code: "EXCEPTION",
                    scope: "Form_SignInWithAccessCode",
                    error,
                });
            });
    };

    const inputs = useMemo<IFormInput[]>(
        () => [
            {
                id: "code",
                label: "Kód",
                controlType: "input",
                inputType: "number",
                rules: {
                    required: true,
                },
            },
        ],
        [],
    );

    return (
        <Form
            inputs={inputs}
            buttonsRenderer={(submit) => [
                {
                    label: "Přihlásit",
                    testId: "SignIn",
                    onClick: submit,
                },
            ]}
            onSubmit={handleSubmit}
        />
    );
};
