import { IContext, ICurrentRoute } from "@nazorna-vyuka/types";
import { Helmet, Loader } from "@nazorna-vyuka/ui";
import { initialContext, log } from "@nazorna-vyuka/utils";
import React, { useEffect, useState } from "react";
import {
    initializeTranslations,
    onFirstLoad,
    onRouteChange,
} from "./App.functions";
import { ProvidersWrapper } from "./ProvidersWrapper";
import { Router } from "./Router";

initializeTranslations();

export const App: React.FC = () => {
    const [state, setState] = useState<IContext>(initialContext);

    const setContextProp = (key: keyof IContext, value: unknown) => {
        setState((prevState: IContext) => ({
            ...prevState,
            [key]: value,
        }));
    };

    useEffect(() => {
        getStorageData();
        onFirstLoad(setState);
    }, []);

    useEffect(() => {
        onRouteChange();
    }, [state.currentRoute]);

    const getStorageData = () => {
        fetch(`${state.STORAGE_URL}/enumerator.json`)
            .then((response) => response.json())
            .then((data) => {
                setContextProp(
                    "storageData",
                    data.map(
                        ({ thumbnailUrl, animationUrl, ...rest }: any) => ({
                            ...rest,
                            thumbnailUrl: thumbnailUrl
                                ? `${state.STORAGE_URL}${thumbnailUrl}`
                                : null,
                            animationUrl: animationUrl
                                ? `${state.STORAGE_URL}${animationUrl}`
                                : null,
                        }),
                    ),
                );
            })
            .catch(() => {
                log({
                    code: "EXCEPTION",
                    scope: "App",
                    error: new Error("Failed to load enum.json file."),
                });
            });
    };

    const globalFunctions = {
        setIsGlobalLoading: (value: boolean) =>
            setContextProp("isGlobalLoading", value),
        setSearchQuery: (value: string | undefined) =>
            setContextProp("searchQuery", value),
        setCurrentRoute: (value: ICurrentRoute) =>
            setContextProp("currentRoute", value),
        setIsScrollDisabled: (value: boolean) =>
            setContextProp("isScrollDisabled", value),
        setIsCookiesAllowed: (value: boolean) =>
            setContextProp("isCookiesAllowed", value),
    };

    return (
        <ProvidersWrapper context={{ ...state, ...globalFunctions }}>
            <Helmet />

            {state.user !== undefined ? <Router /> : <Loader />}
        </ProvidersWrapper>
    );
};
