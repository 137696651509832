import { ILesson, IModule } from "@nazorna-vyuka/types";
import { Module_Activity } from "./modules/Activity";
import { Module_Audio } from "./modules/Audio";
import { Module_Definition } from "./modules/Definition";
import { Module_FileDownload } from "./modules/FileDownload";
import { Module_Headline } from "./modules/Headline";
import { Module_Image } from "./modules/Image";
import { Module_Interesting } from "./modules/Interesting";
import { Module_Methodology } from "./modules/Methodology";
import { Module_Note } from "./modules/Note";
import { Module_NoteToNotebook } from "./modules/NoteToNotebook";
import { Module_Paragraph } from "./modules/Paragraph";
import { Module_PresentationSlides } from "./modules/PresentationSlides";
import { Module_QuestionAnswer } from "./modules/QuestionAnswer";
import { Module_SimulatorManual } from "./modules/SimulatorManual";
import { Module_Video } from "./modules/Video";

export interface IProps {
    lessonId: ILesson["id"];
    module: IModule;
}

export const LessonContentModule: React.FC<IProps> = ({ lessonId, module }) => {
    if (
        module.type === "H1" ||
        module.type === "H2" ||
        module.type === "H3" ||
        module.type === "H4"
    ) {
        return (
            <Module_Headline
                {...module}
                level={Number(module.type.split("")[1])}
            />
        );
    }

    if (module.type === "Paragraph") {
        return <Module_Paragraph {...module} />;
    }

    if (module.type === "Note") {
        return <Module_Note {...module} />;
    }

    if (module.type === "Definition") {
        return <Module_Definition {...module} />;
    }

    if (module.type === "Interesting") {
        return <Module_Interesting {...module} />;
    }

    if (module.type === "Activity") {
        return <Module_Activity {...module} />;
    }

    if (module.type === "QuestionAnswer") {
        return <Module_QuestionAnswer {...module} />;
    }

    if (module.type === "NoteToNotebook") {
        return <Module_NoteToNotebook {...module} />;
    }

    if (module.type === "Methodology") {
        return <Module_Methodology {...module} />;
    }

    if (module.type === "SimulatorManual") {
        return <Module_SimulatorManual {...module} />;
    }

    if (module.type === "Video") {
        return <Module_Video {...module} />;
    }

    if (module.type === "Audio") {
        return <Module_Audio {...module} />;
    }

    if (module.type === "Image") {
        return <Module_Image {...module} />;
    }

    if (module.type === "FileDownload") {
        return <Module_FileDownload {...module} />;
    }

    if (module.type === "PresentationSlides") {
        return <Module_PresentationSlides {...module} lessonId={lessonId} />;
    }

    return null;
};
