import {
    convertDocsToItems,
    convertDocToItem,
    TQuery,
} from "@honzachalupa/firebase";
import {
    ICourse,
    ICreditTopUpItem,
    ILesson,
    ISignedUser,
    TCreditTransactionType,
} from "@nazorna-vyuka/types";
import moment from "moment";
import { Collections, Database } from "../utils/firebase";
import { log } from "../utils/logging";

export const CreditTransactionsAPI = {
    search: (
        query?: TQuery,
    ): Promise<
        {
            id: ISignedUser["id"];
            transactions: {
                id: string;
                type: TCreditTransactionType;
                createdDate: string;
            }[];
        }[]
    > =>
        Database.search(Collections.creditTransactions, query)
            .then(convertDocsToItems)
            .catch((error) => {
                log({
                    code: "EXCEPTION",
                    scope: "CoursesAPI",
                    error,
                });

                return [];
            }),

    get: (userId: ISignedUser["id"]): Promise<ICreditTopUpItem[]> =>
        Database.get(Collections.creditTransactions, userId)
            .then(convertDocToItem)
            .then(({ transactions }: any) => transactions)
            .catch((error) => error),

    create: (
        userId: ISignedUser["id"],
        data: {
            type: TCreditTransactionType;
            amount: number;
            info?: {
                courseId: ICourse["id"];
                lessonId: ILesson["id"];
                lessonTitle: string;
            };
        },
    ) =>
        Database.get(Collections.creditTransactions, userId)
            .then((doc) => {
                const prevState = doc.data()?.["transactions"] || [];

                return Database[doc.exists() ? "update" : "set"](
                    Collections.creditTransactions,
                    userId,
                    {
                        transactions: [
                            {
                                ...data,
                                createdDate: moment().format(),
                            },
                            ...prevState,
                        ],
                    },
                );
            })
            .catch((error) => {
                log({
                    code: "EXCEPTION",
                    scope: "CoursesAPI",
                    error,
                });

                return null;
            }),
};
