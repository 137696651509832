import { ERoutePaths } from "@nazorna-vyuka/types";
import { Route } from "react-router-dom";
import { Page_TermsAndConditions } from "./TermsAndConditions";

export default [
    <Route
        path={ERoutePaths.TERMS_AND_CONDITION}
        element={<Page_TermsAndConditions />}
    />,
];
