import styled from "styled-components";

export const StyledHeadline = styled.h4`
    text-transform: uppercase;
    display: inline;
`;

export const StyledContent = styled.div`
    ol {
        counter-reset: item;
    }

    ol > li,
    ul > li {
        display: block;
        margin-left: 20px;
    }

    ol > li:before {
        content: counters(item, ".") ".";
        counter-increment: item;
        margin-right: 20px;
    }
`;
