import {
    Button,
    ButtonsGroup,
    HorizontalDivider,
    IModalRefProps,
    LayoutMargin,
    Layout_Main,
    theme,
    Title,
} from "@nazorna-vyuka/ui";
import { useCurrentPage } from "@nazorna-vyuka/utils";
import React, { useRef } from "react";
import { ModalMessage } from "../../../components/molecules/ModalMessage";
import { Form_SharedAccessCode } from "../../../components/organisms/forms/SharedAccessCode";
import { Modal_PasswordChange } from "../../../components/organisms/modals/PasswordChange";
import { StyledHeadline, StyledList } from "./styled";

export const Page_AccountDetails: React.FC = () => {
    const { title, user, navigateTo } = useCurrentPage({ title: "Účet" });

    const modalMessageRef = useRef<IModalRefProps>(null);
    const changePasswordModalRef = useRef<IModalRefProps>(null);

    return (
        <Layout_Main>
            <ModalMessage
                ref={modalMessageRef}
                message="Heslo bylo změněno."
                icon={{
                    name: "check",
                    color: "white",
                    backgroundColor: theme.colors.green,
                }}
            />

            <Modal_PasswordChange
                ref={changePasswordModalRef}
                onSubmit={() => {
                    changePasswordModalRef.current?.hide();
                    modalMessageRef.current?.show();
                }}
            />

            <Title level={1}>{title}</Title>

            <LayoutMargin>
                <HorizontalDivider padding={100}>
                    <div>
                        <StyledHeadline>Mé údaje</StyledHeadline>

                        <StyledList>
                            <li>E-mailová adresa: {user?.emailAddress}</li>
                            <li>Jméno: {user?.firstName}</li>
                            <li>Příjmení: {user?.lastName}</li>
                            <li>Telefon: {user?.phoneNumber}</li>
                            <li>Název školy: {user?.schoolName}</li>
                        </StyledList>

                        <ButtonsGroup alignment="left">
                            <Button
                                label="Upravit účet"
                                onClick={navigateTo.accountDetailsEdit}
                            />

                            <Button
                                label="Změnit heslo"
                                color="secondary"
                                onClick={() =>
                                    changePasswordModalRef.current?.show()
                                }
                            />
                        </ButtonsGroup>
                    </div>

                    <div>
                        <StyledHeadline>Kód pro sdílení s žáky</StyledHeadline>

                        <Form_SharedAccessCode />
                    </div>
                </HorizontalDivider>
            </LayoutMargin>
        </Layout_Main>
    );
};
