import { ERoutePaths } from "@nazorna-vyuka/types";
import { Route } from "react-router-dom";
import { Page_CreditTransactions } from "./pages/CreditTransactions";
import { Page_DataManager } from "./pages/DataManager";
import { Page_Home } from "./pages/Home";

export const routes = [
    <Route path={ERoutePaths.ADMINISTRATION} element={<Page_Home />} />,
    <Route
        path={ERoutePaths.ADMINISTRATION_DATA_MANAGER}
        element={<Page_DataManager />}
    />,
    <Route
        path={ERoutePaths.ADMINISTRATION_CREDIT_TRANSACTIONS}
        element={<Page_CreditTransactions />}
    />,
];
