import {
    adminPaths,
    ERoutePaths,
    IContext,
    ICurrentRoute,
    notPrivatePaths,
    privatePaths,
} from "@nazorna-vyuka/types";
import { useContext, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { config } from "..";
import { Context } from "../utils/context";
import { Analytics } from "../utils/firebase";
import { log } from "../utils/logging";
import { useAuthentication } from "./authentication";
import { useNavigation } from "./navigation";

interface IProps {
    title?: string;
    isAnalyticsDisabled?: boolean;
}

export const useCurrentPage = ({ title, isAnalyticsDisabled }: IProps) => {
    const location = useLocation();
    const params = useParams();
    const navigateTo = useNavigation();
    const {
        user,
        isAdmin,
        isUserAuthenticated,
        isUserAuthenticatedAnonymously,
    } = useAuthentication();

    const { isCookiesAllowed, setCurrentRoute } = useContext<IContext>(Context);

    const [route, setCurrentRouteState] = useState<ICurrentRoute>();

    useEffect(() => {
        window.document.title = title
            ? `${title} | ${config.name}`
            : config.name;

        const currentRoute: ICurrentRoute = {
            id:
                Object.entries(ERoutePaths)
                    .map(([id, path]) => ({
                        id,
                        pattern: new RegExp(
                            `^${path.replace(/:[a-z]*/gi, "[^/]+")}$`,
                        ),
                    }))
                    .find(({ pattern }) => pattern.test(location.pathname))
                    ?.id || undefined,
            path: undefined,
        };

        if (currentRoute.id) {
            currentRoute.path =
                ERoutePaths[currentRoute.id as keyof typeof ERoutePaths];

            if (currentRoute.path) {
                const isCurrentPath = {
                    private: privatePaths.includes(currentRoute.path),
                    notPrivate: notPrivatePaths.includes(currentRoute.path),
                    admin: adminPaths.includes(currentRoute.path),
                };

                if (
                    !isUserAuthenticated &&
                    !isUserAuthenticatedAnonymously &&
                    isCurrentPath.private
                ) {
                    navigateTo.signIn();
                } else if (
                    ((isUserAuthenticated || isUserAuthenticatedAnonymously) &&
                        isCurrentPath.notPrivate) ||
                    (!isAdmin && isCurrentPath.admin)
                ) {
                    navigateTo.home();
                } else {
                    setCurrentRouteState(currentRoute);
                    setCurrentRoute(currentRoute);

                    if (isCookiesAllowed && !isAnalyticsDisabled) {
                        Analytics.setCurrentScreen(currentRoute.id);
                    }
                }
            } else {
                log({
                    code: "EXCEPTION",
                    scope: "useCurrentPage",
                    message: "Attempt to access unknown path.",
                    data: {
                        currentRoute,
                    },
                });
            }
        } else {
            log({
                code: "EXCEPTION",
                scope: "useCurrentPage",
                message: "Unable to determine page ID from pathname.",
                data: {
                    location,
                },
            });

            navigateTo.notFound();
        }
    }, [isCookiesAllowed]);

    return {
        title,
        user,
        route,
        location,
        params,
        navigateTo,
        isUserAuthenticated,
    };
};
