import { convertDocToItem } from "@honzachalupa/firebase";
import { IModule } from "@nazorna-vyuka/types";
import { Collections, Database } from "../utils/firebase";

export const ModulesAPI = {
    get: (id: IModule["id"]): Promise<IModule> =>
        Database.get(Collections.modules, id)
            .then(convertDocToItem)
            .catch((error) => error),
};
