import { IModule } from "@nazorna-vyuka/types";
import { HiOutlinePencil } from "react-icons/hi";
import { CollapsibleBlock } from "../../../atoms/CollapsibleBlock";
import { MarkdownText } from "../../../atoms/MarkdownText";

export const Module_NoteToNotebook: React.FC<IModule> = ({ text }) => (
    <CollapsibleBlock
        label="Zápis do sešitu"
        icon={<HiOutlinePencil />}
        color="#B6EEFF"
    >
        <MarkdownText value={text} />
    </CollapsibleBlock>
);
