import styled from "styled-components";
import { Icon } from "../../../";

export const StyledContainer = styled.div<{ color: string; hasIcon: boolean }>`
    background: ${({ color }) => color};
    border: 1px solid rgba(0, 0, 0, 0.25);
    border-radius: 7px;
    margin: 20px 0;
    overflow: hidden;
    position: relative;

    .Collapsible {
        &__trigger {
            height: 65px;
            font-weight: 500;
            font-size: 20px;
            line-height: 22px;
            padding-left: ${({ hasIcon }) => (hasIcon ? 60 : 25)}px;
            cursor: pointer;
            display: flex;
            align-items: center;
        }

        &__contentInner {
            background: white;
            padding: 20px;
        }
    }
`;

export const StyledIconContainer = styled.div`
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 22px;
    left: 20px;
    pointer-events: none;

    * {
        width: 100%;
        height: 100%;
    }
`;

export const StyledToggleIcon = styled(Icon)`
    width: 35px;
    position: absolute;
    top: 17px;
    right: 15px;
    pointer-events: none;
`;

export const StyledText = styled.div`
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;

    p + p {
        margin-bottom: 10px;
    }
`;
