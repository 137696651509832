import { Fragment } from "react";
import { Button, ButtonsGroup, IconButton } from "../../../../../";
import { StyledContainer, StyledSlidesCount } from "./Controls.styled";

interface IProps {
    index: number;
    framesCount: number;
    isFullscreenOn: boolean;
    isActionInProgress: boolean;
    onFullscreenModeChange: () => void;
    onSlideChange: (direction: "prev" | "next") => void;
}

export const Controls: React.FC<IProps> = ({
    index,
    framesCount,
    isFullscreenOn,
    isActionInProgress,
    onFullscreenModeChange: toggleFullscreenMode,
    onSlideChange: changeSlide,
}) => {
    const isPrevButtonDisabled = index === 0 || isActionInProgress;
    const isNextButtonDisabled =
        index + 1 === framesCount || isActionInProgress;

    return (
        <StyledContainer isFullscreenOn={isFullscreenOn}>
            {framesCount > 0 && (
                <StyledSlidesCount data-test-id="count">
                    Slide: {index + 1} z {framesCount}
                </StyledSlidesCount>
            )}

            <ButtonsGroup>
                {framesCount > 0 && (
                    <Fragment>
                        <IconButton
                            label="Předchozí"
                            color="green"
                            icon={{
                                name: "arrowLeft",
                                color: "white",
                            }}
                            isDisabled={isPrevButtonDisabled}
                            onClick={() => changeSlide("prev")}
                        />

                        <IconButton
                            label="Další"
                            color="green"
                            icon={{
                                name: "arrowRight",
                                color: "white",
                            }}
                            isDisabled={isNextButtonDisabled}
                            onClick={() => changeSlide("next")}
                        />
                    </Fragment>
                )}

                {isFullscreenOn ? (
                    <Button
                        label="Ukončit režim celé obrazovky"
                        color="secondary"
                        icon={{
                            name: "fullScreenExit",
                        }}
                        onClick={toggleFullscreenMode}
                    />
                ) : (
                    <IconButton
                        label="Na celou obrazovku"
                        color="secondary"
                        icon={{
                            name: "fullScreen",
                        }}
                        onClick={toggleFullscreenMode}
                    />
                )}
            </ButtonsGroup>
        </StyledContainer>
    );
};
