import { ButtonsGroup } from "@honzachalupa/ui";
import styled from "styled-components";
import { Title } from "../Title";

export const StyledModalTitle = styled(Title)`
    font-size: 24px;
`;

export const StyledModalText = styled.p`
    font-size: 16px;
`;

export const StyledModalButtonsGroup = styled(ButtonsGroup)`
    margin-top: 30px;
`;
