import { createGlobalStyle } from "styled-components";

export const Fonts = createGlobalStyle`
    @font-face {
        font-family: "Graphik";
        font-weight: 100;
        src: local("Graphik"), url("./assets/fonts/Graphik/Extralight.otf") format("opentype");
    }

    @font-face {
        font-family: "Graphik";
        font-weight: 200;
        src: local("Graphik"), url("./assets/fonts/Graphik/Medium.otf") format("opentype");
    }

    @font-face {
        font-family: "Graphik";
        font-weight: 400;
        src: local("Graphik"), url("./assets/fonts/Graphik/Regular.otf") format("opentype");
    }

    @font-face {
        font-family: "GraphikSemibold";
        font-weight: 600;
        src: local("Graphik"), url("./assets/fonts/Graphik/Semibold.otf") format("opentype");
    }
`;
