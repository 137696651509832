export enum ERoutePaths {
    INDEX = "/",
    COURSES = "/kurzy",
    COURSES_OWNED = "/zakoupene-kurzy",
    COURSE = "/kurz/:courseId",
    LESSON_DETAIL = "/lekce/:courseId/:lessonTitle",
    ACCOUNT_DETAILS = "/ucet",
    ACCOUNT_DETAILS_EDIT = "/ucet/upravit",
    ACCOUNT_CREDIT = "/kredit",
    ACCOUNT_DISPONENTS = "/disponenti",
    PAYMENT_CONFIRMATION = "/potvrzeni-platby",
    INVITATION_ACCEPT = "/pozvanka/:id",
    AUTHENTICATION_SIGN_UP_INFO = "/zkusit-zdarma",
    AUTHENTICATION_SIGN_UP = "/registrace",
    AUTHENTICATION_SIGN_UP_DETAILS = "/registrace/doplnujici-informace",
    AUTHENTICATION_SIGN_IN = "/prihlaseni",
    AUTHENTICATION_SIGN_OUT = "/odhlasit",
    NOT_FOUND = "/stranka-nenalezena",
    ADMINISTRATION = "/administrace",
    ADMINISTRATION_DATA_MANAGER = "/administrace/sprava-dat",
    ADMINISTRATION_CREDIT_TRANSACTIONS = "/administrace/transakce",
    TERMS_AND_CONDITION = "/obchodni-podminky",
}

// Signed users
export const privatePaths = [
    ERoutePaths.COURSES,
    ERoutePaths.COURSE,
    ERoutePaths.LESSON_DETAIL,
    ERoutePaths.COURSES_OWNED,
    ERoutePaths.ACCOUNT_DETAILS,
    ERoutePaths.ACCOUNT_CREDIT,
    ERoutePaths.ACCOUNT_DISPONENTS,
    ERoutePaths.AUTHENTICATION_SIGN_UP_DETAILS,
];

export const notPrivatePaths = [
    ERoutePaths.AUTHENTICATION_SIGN_UP,
    ERoutePaths.AUTHENTICATION_SIGN_IN,
    ERoutePaths.INVITATION_ACCEPT,
];

// Signed users with admin role
export const adminPaths = [
    ERoutePaths.ADMINISTRATION,
    ERoutePaths.ADMINISTRATION_DATA_MANAGER,
];
