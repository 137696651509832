import { ILesson, IModule } from "@nazorna-vyuka/types";
import { LessonContentModule } from "@nazorna-vyuka/ui";
import { ModulesAPI } from "@nazorna-vyuka/utils";
import { useEffect, useState } from "react";

interface IProps {
    lessonId: ILesson["id"];
    moduleIDs: string[];
}

export const Content: React.FC<IProps> = ({ lessonId, moduleIDs }) => {
    const [modules, setModules] = useState<IModule[]>([]);

    useEffect(() => {
        Promise.all(moduleIDs.map((id) => ModulesAPI.get(id))).then((data) =>
            setModules(data),
        );
    }, [moduleIDs]);

    return (
        <div>
            {modules.map((module) => (
                <LessonContentModule
                    key={module.id}
                    lessonId={lessonId}
                    module={module}
                />
            ))}
        </div>
    );
};
