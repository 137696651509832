const content: {
    headline?: string;
    ol?: {
        text: string;
        ol?: {
            text: string;
        }[];
        ul?: {
            text: string;
        }[];
    }[];
    ul?: {
        text: string;
        ol?: {
            text: string;
        }[];
        ul?: {
            text: string;
        }[];
    }[];
}[] = [
    {
        headline: "Úvodní ustanovení",
        ol: [
            {
                text: "Tyto obchodní podmínky (dále jen „obchodní podmínky“) obchodní společnosti EDHANCE s. r. o., se sídlem Zámecké náměstí 511/10, 415 01 Teplice, identifikační číslo: 03521770, zapsané v obchodním rejstříku vedeném u Krajského soudu v Ústí nad Labem, oddíl C, vložka 38733 (dále jen „prodávající“) upravují v souladu s ustanovením § 1751 odst. 1 zákona č. 89/2012 Sb., občanský zákoník, ve znění pozdějších předpisů (dále jen „občanský zákoník“) vzájemná práva a povinnosti smluvních stran vzniklé v souvislosti nebo na základě kupní smlouvy (dále jen „kupní smlouva“) uzavírané mezi prodávajícím a jinou fyzickou osobou (dále jen „kupující“) prostřednictvím internetového obchodu prodávajícího. Internetový obchod je prodávajícím provozován na webové stránce umístěné na internetové adrese ZabavnaHodina.cz (dále jen „webová stránka“), a to prostřednictvím rozhraní webové stránky (dále jen „webové rozhraní obchodu“).",
            },
            {
                text: "Obchodní podmínky se nevztahují na případy, kdy osoba, která má v úmyslu nakoupit zboží od prodávajícího, je právnickou osobou či osobou, jež jedná při objednávání zboží v rámci své podnikatelské činnosti nebo v rámci svého samostatného výkonu povolání.",
            },
            {
                text: "Ustanovení odchylná od obchodních podmínek je možné sjednat v kupní smlouvě. Odchylná ujednání v kupní smlouvě mají přednost před ustanoveními obchodních podmínek.",
            },
            {
                text: "Ustanovení obchodních podmínek jsou nedílnou součástí kupní smlouvy. Kupní smlouva a obchodní podmínky jsou vyhotoveny v českém jazyce. Kupní smlouvu lze uzavřít v českém jazyce.",
            },
            {
                text: "Znění obchodních podmínek může prodávající měnit či doplňovat. Tímto ustanovením nejsou dotčena práva a povinnosti vzniklá po dobu účinnosti předchozího znění.",
            },
        ],
    },
    {
        headline: "Uživatelský účet",
        ol: [
            {
                text: "Na základě registrace kupujícího provedené na webové stránce může kupující přistupovat do svého uživatelského rozhraní. Ze svého uživatelského rozhraní může kupující provádět objednávání zboží (dále jen „uživatelský účet“). V případě, že to webové rozhraní obchodu umožňuje, může kupující provádět objednávání zboží též bez registrace přímo z webového rozhraní obchodu.",
            },
            {
                text: "Při registraci na webové stránce a při objednávání zboží je kupující povinen uvádět správně a pravdivě všechny údaje. Údaje uvedené v uživatelském účtu je kupující při jakékoliv jejich změně povinen aktualizovat. Údaje uvedené kupujícím v uživatelském účtu a při objednávání zboží jsou prodávajícím považovány za správné.",
            },
            {
                text: "Přístup k uživatelskému účtu je zabezpečen uživatelským jménem a heslem. Kupující je povinen zachovávat mlčenlivost ohledně informací nezbytných k přístupu do jeho uživatelského účtu.",
            },
            {
                text: "Kupující není oprávněn umožnit využívání uživatelského účtu třetím osobám.",
            },
            {
                text: "Prodávající může zrušit uživatelský účet, a to zejména v případě, kdy kupující svůj uživatelský účet déle než 90 dnů nevyužívá, či v případě, kdy kupující poruší své povinnosti z kupní smlouvy (včetně obchodních podmínek).",
            },
            {
                text: "Kupující bere na vědomí, že uživatelský účet nemusí být dostupný nepřetržitě, a to zejména s ohledem na nutnou údržbu hardwarového a softwarového vybavení prodávajícího, popř. nutnou údržbu hardwarového a softwarového vybavení třetích osob.",
            },
        ],
    },
    {
        headline: "Uzavření kupní smlouvy",
        ol: [
            {
                text: "Veškerá prezentace zboží umístěná ve webovém rozhraní obchodu je informativního charakteru a prodávající není povinen uzavřít kupní smlouvu ohledně tohoto zboží. Ustanovení § 1732 odst. 2 občanského zákoníku se nepoužije.",
            },
            {
                text: "Webové rozhraní obchodu obsahuje informace o zboží, a to včetně uvedení cen jednotlivého zboží a nákladů za navrácení zboží, jestliže toto zboží ze své podstaty nemůže být navráceno obvyklou poštovní cestou. Ceny zboží jsou uvedeny včetně daně z přidané hodnoty a všech souvisejících poplatků. Ceny zboží zůstávají v platnosti po dobu, kdy jsou zobrazovány ve webovém rozhraní obchodu. Tímto ustanovením není omezena možnost prodávajícího uzavřít kupní smlouvu za individuálně sjednaných podmínek.",
            },
            {
                text: "Webové rozhraní obchodu obsahuje také informace o nákladech spojených s balením a dodáním zboží. Informace o nákladech spojených s balením a dodáním zboží uvedené ve webovém rozhraní obchodu platí pouze v případech, kdy je zboží doručováno v rámci území České republiky. V případě, kdy prodávající nabízí dopravu zboží zdarma, je předpokladem vzniku práva na bezplatnou dopravu zboží na straně kupujícího zaplacení minimální celkové kupní ceny dopravovaného zboží ve výši stanovené ve webovém rozhraní obchodu. V případě, kdy dojde k částečnému odstoupení od kupní smlouvy kupujícím a celková kupní cena zboží, u kterého nedošlo k odstoupení od smlouvy kupujícím, nedosahuje minimální výše, jež je potřebná pro vznik práva na dopravu zboží zdarma podle předchozí věty, právo kupujícího na dopravu zboží zdarma zaniká a kupující je povinen dopravu zboží prodávajícímu uhradit.",
            },
            {
                text: "Pro objednání zboží vyplní kupující objednávkový formulář ve webovém rozhraní obchodu. Objednávkový formulář obsahuje zejména informace o:",
                ol: [
                    {
                        text: "objednávaném zboží (objednávané zboží „vloží“ kupující do elektronického nákupního košíku webového rozhraní obchodu),",
                    },
                    {
                        text: "způsobu úhrady kupní ceny zboží, údaje o požadovaném způsobu doručení objednávaného zboží a",
                    },
                    {
                        text: "informace o nákladech spojených s dodáním zboží (dále společně jen jako „objednávka“).",
                    },
                ],
            },
            {
                text: "Před zasláním objednávky prodávajícímu je kupujícímu umožněno zkontrolovat a měnit údaje, které do objednávky kupující vložil, a to i s ohledem na možnost kupujícího zjišťovat a opravovat chyby vzniklé při zadávání dat do objednávky. Objednávku odešle kupující prodávajícímu kliknutím na tlačítko „Dobít kredit“. Údaje uvedené v objednávce jsou prodávajícím považovány za správné. Prodávající neprodleně po obdržení objednávky toto obdržení kupujícímu potvrdí elektronickou poštou, a to na adresu elektronické pošty kupujícího uvedenou v uživatelském účtu či v objednávce (dále jen „elektronická adresa kupujícího“).",
            },
            {
                text: "Prodávající je vždy oprávněn v závislosti na charakteru objednávky (množství zboží, výše kupní ceny, předpokládané náklady na dopravu) požádat kupujícího o dodatečné potvrzení objednávky (například písemně či telefonicky).",
            },
            {
                text: "Smluvní vztah mezi prodávajícím a kupujícím vzniká doručením přijetí objednávky (akceptací), jež je prodávajícím zasláno kupujícímu elektronickou poštou, a to na adresu elektronické pošty kupujícího.",
            },
            {
                text: "Kupující souhlasí s použitím komunikačních prostředků na dálku při uzavírání kupní smlouvy. Náklady vzniklé kupujícímu při použití komunikačních prostředků na dálku v souvislosti s uzavřením kupní smlouvy (náklady na internetové připojení, náklady na telefonní hovory) si hradí kupující sám, přičemž tyto náklady se neliší od základní sazby.",
            },
        ],
    },
    {
        headline: "Cena zboží a platební podmínky",
        ol: [
            {
                text: "Po založení účtu si může kupující na zákaznický účet vložit peněžitou částku (dále jen „kredit“), minimálně ve výši 300 Kč. ",
            },
            {
                text: "Vklad kreditu se provádí prostřednictvím platební brány. Kupující má přístup ke stavu svého zákaznického účtu. ",
            },
            {
                text: "Vložený kredit je kupující povinen vyčerpat nejpozději do 12 měsíců, počítáno od okamžiku vložení příslušného kreditu. Po uplynutí doby dle předchozí věty skončí platnost vloženého kreditu. Platnost kreditu je možno prodloužit vložením další finanční částky, min. 300 Kč. Zakoupený kredit není možné směnit zpět na peněžní prostředky a jakkoli vracet, to platí také v případě zrušení zákaznického účtu. ",
            },
            {
                text: "Cenu zboží a případné náklady spojené s dodáním zboží dle kupní smlouvy může kupující uhradit prodávajícímu prostřednictvím kreditu na účtu kupujícího.",
            },
            {
                text: "Společně s kupní cenou je kupující povinen zaplatit prodávajícímu také náklady spojené s balením a dodáním zboží ve smluvené výši. Není-li uvedeno výslovně jinak, rozumí se dále kupní cenou i náklady spojené s dodáním zboží.",
            },
            {
                text: "Prodávající nepožaduje od kupujícího zálohu či jinou obdobnou platbu. Tímto není dotčeno ustanovení čl. 4.6 obchodních podmínek ohledně povinnosti uhradit kupní cenu zboží předem.",
            },
            {
                text: "Prodávající je oprávněn, zejména v případě, že ze strany kupujícího nedojde k dodatečnému potvrzení objednávky (čl. 3.6), požadovat uhrazení celé kupní ceny ještě před odesláním zboží kupujícímu. Ustanovení § 2119 odst. 1 občanského zákoníku se nepoužije.",
            },
            {
                text: "Případné slevy z ceny zboží poskytnuté prodávajícím kupujícímu nelze vzájemně kombinovat.",
            },
            {
                text: "Je-li to v obchodním styku obvyklé nebo je-li tak stanoveno obecně závaznými právními předpisy, vystaví prodávající ohledně plateb prováděných na základě kupní smlouvy kupujícímu daňový doklad – fakturu. Prodávající je plátcem daně z přidané hodnoty. Daňový doklad – fakturu vystaví prodávající kupujícímu po uhrazení ceny zboží a zašle jej v elektronické podobě na elektronickou adresu kupujícího.",
            },
            {
                text: "1Podle zákona o evidenci tržeb je prodávající povinen vystavit kupujícímu účtenku. Zároveň je povinen zaevidovat přijatou tržbu u správce daně online; v případě technického výpadku pak nejpozději do 48 hodin.",
            },
        ],
    },
    {
        headline: "Odstoupení od kupní smlouvy",
        ol: [
            {
                text: "Kupující bere na vědomí, že dle ustanovení § 1837 občanského zákoníku nelze mimo jiné odstoupit od kupní smlouvy o dodávce zboží, které bylo upraveno podle přání kupujícího nebo pro jeho osobu, od kupní smlouvy o dodávce zboží, které podléhá rychlé zkáze, jakož i zboží, které bylo po dodání nenávratně smíseno s jiným zbožím, od kupní smlouvy o dodávce zboží v uzavřeném obalu, které spotřebitel z obalu vyňal a z hygienických důvodů jej není možné vrátit a od kupní smlouvy o dodávce zvukové nebo obrazové nahrávky nebo počítačového programu, pokud porušil jejich původní obal.",
            },
            {
                text: "Nejedná-li se o případ uvedený v čl. 5.1 obchodních podmínek či o jiný případ, kdy nelze od kupní smlouvy odstoupit, má kupující v souladu s ustanovením § 1829 odst. 1 občanského zákoníku právo od kupní smlouvy odstoupit, a to do čtrnácti (14) dnů od převzetí zboží, přičemž v případě, že předmětem kupní smlouvy je několik druhů zboží nebo dodání několika částí, běží tato lhůta ode dne převzetí poslední dodávky zboží. Odstoupení od kupní smlouvy musí být prodávajícímu odesláno ve lhůtě uvedené v předchozí větě. Pro odstoupení od kupní smlouvy může kupující využit vzorový formulář poskytovaný prodávajícím, jenž tvoří přílohu obchodních podmínek. Odstoupení od kupní smlouvy může kupující zasílat mimo jiné na adresu provozovny prodávajícího či na adresu elektronické pošty prodávajícího info@edhance.cz.",
            },
            {
                text: "V případě odstoupení od kupní smlouvy dle čl. 5.2 obchodních podmínek se kupní smlouva od počátku ruší. Zboží musí být kupujícím prodávajícímu vráceno do čtrnácti (14) dnů od doručení odstoupení od kupní smlouvy prodávajícímu. Odstoupí-li kupující od kupní smlouvy, nese kupující náklady spojené s navrácením zboží prodávajícímu, a to i v tom případě, kdy zboží nemůže být vráceno pro svou povahu obvyklou poštovní cestou.",
            },
            {
                text: "V případě odstoupení od kupní smlouvy dle čl. 5.2 obchodních podmínek vrátí prodávající peněžní prostředky přijaté od kupujícího do čtrnácti (14) dnů od odstoupení od kupní smlouvy kupujícím, a to stejným způsobem, jakým je prodávající od kupujícího přijal. Prodávající je taktéž oprávněn vrátit plnění poskytnuté kupujícím již při vrácení zboží kupujícím či jiným způsobem, pokud s tím kupující bude souhlasit a nevzniknou tím kupujícímu další náklady. Odstoupí-li kupující od kupní smlouvy, prodávající není povinen vrátit přijaté peněžní prostředky kupujícímu dříve, než mu kupující zboží vrátí nebo prokáže, že zboží prodávajícímu odeslal.",
            },
            {
                text: "Nárok na úhradu škody vzniklé na zboží je prodávající oprávněn jednostranně započíst proti nároku kupujícího na vrácení kupní ceny.",
            },
            {
                text: "V případech, kdy má kupující v souladu s ustanovením § 1829 odst. 1 občanského zákoníku právo od kupní smlouvy odstoupit, je prodávající také oprávněn kdykoliv od kupní smlouvy odstoupit, a to až do doby převzetí zboží kupujícím. V takovém případě vrátí prodávající kupujícímu kupní cenu bez zbytečného odkladu, a to bezhotovostně na účet určený kupujícím.",
            },
            {
                text: "Je-li společně se zbožím poskytnut kupujícímu dárek, je darovací smlouva mezi prodávajícím a kupujícím uzavřena s rozvazovací podmínkou, že dojde-li k odstoupení od kupní smlouvy kupujícím, pozbývá darovací smlouva ohledně takového dárku účinnosti a kupující je povinen spolu se zbožím prodávajícímu vrátit i poskytnutý dárek.",
            },
        ],
    },
    {
        headline: "Přeprava a dodání zboží",
        ol: [
            {
                text: "V případě, že je způsob dopravy smluven na základě zvláštního požadavku kupujícího, nese kupující riziko a případné dodatečné náklady spojené s tímto způsobem dopravy.",
            },
            {
                text: "Je-li prodávající podle kupní smlouvy povinen dodat zboží na místo určené kupujícím v objednávce, je kupující povinen převzít zboží při dodání.",
            },
            {
                text: "V případě, že je z důvodů na straně kupujícího nutno zboží doručovat opakovaně nebo jiným způsobem, než bylo uvedeno v objednávce, je kupující povinen uhradit náklady spojené s opakovaným doručováním zboží, resp. náklady spojené s jiným způsobem doručení.",
            },
            {
                text: "Při převzetí zboží od přepravce je kupující povinen zkontrolovat neporušenost obalů zboží a v případě jakýchkoliv závad toto neprodleně oznámit přepravci. V případě shledání porušení obalu svědčícího o neoprávněném vniknutí do zásilky nemusí kupující zásilku od přepravce převzít. Tímto nejsou dotčena práva kupujícího z odpovědnosti za vady zboží a další práva kupujícího vyplývající z obecně závazných právních předpisů.",
            },
            {
                text: "Další práva a povinnosti stran při přepravě zboží mohou upravit zvláštní dodací podmínky prodávajícího, jsou-li prodávajícím vydány.",
            },
        ],
    },
    {
        headline: "Práva z vadného plnění",
        ol: [
            {
                text: "Práva a povinnosti smluvních stran ohledně práv z vadného plnění se řídí příslušnými obecně závaznými právními předpisy (zejména ustanoveními § 1914 až 1925, § 2099 až 2117 a § 2161 až 2174 občanského zákoníku a zákonem č. 634/1992 Sb., o ochraně spotřebitele, ve znění pozdějších předpisů).",
            },
            {
                text: "Prodávající odpovídá kupujícímu, že zboží při převzetí nemá vady. Zejména prodávající odpovídá kupujícímu, že v době, kdy kupující zboží převzal:",
                ol: [
                    {
                        text: "má zboží vlastnosti, které si strany ujednaly, a chybí-li ujednání, má takové vlastnosti, které prodávající nebo výrobce popsal nebo které kupující očekával s ohledem na povahu zboží a na základě reklamy jimi prováděné,",
                    },
                    {
                        text: "se zboží hodí k účelu, který pro jeho použití prodávající uvádí nebo ke kterému se zboží tohoto druhu obvykle používá,",
                    },
                    {
                        text: "zboží odpovídá jakostí nebo provedením smluvenému vzorku nebo předloze, byla-li jakost nebo provedení určeno podle smluveného vzorku nebo předlohy,",
                    },
                    {
                        text: "je zboží v odpovídajícím množství, míře nebo hmotnosti a",
                    },
                    {
                        text: "zboží vyhovuje požadavkům právních předpisů.",
                    },
                ],
            },
            {
                text: "Projeví-li se vada v průběhu šesti měsíců od převzetí, má se za to, že zboží bylo vadné již při převzetí.",
            },
            {
                text: "Prodávající má povinnosti z vadného plnění nejméně v takovém rozsahu, v jakém trvají povinnosti z vadného plnění výrobce. Kupující je jinak oprávněn uplatnit právo z vady, která se vyskytne u spotřebního zboží v době dvaceti čtyř měsíců od převzetí. Je-li na prodávaném zboží, na jeho obalu, v návodu připojenému ke zboží nebo v reklamě v souladu s jinými právními předpisy uvedena doba, po kterou lze zboží použít, použijí se ustanovení o záruce za jakost. Zárukou za jakost se prodávající zavazuje, že zboží bude po určitou dobu způsobilé k použití pro obvyklý účel nebo že si zachová obvyklé vlastnosti. Vytkl-li kupující prodávajícímu vadu zboží oprávněně, neběží lhůta pro uplatnění práv z vadného plnění ani záruční doba po dobu, po kterou kupující nemůže vadné zboží užívat.",
            },
            {
                text: "Ustanovení uvedená v čl. 7.4 obchodních podmínek se nepoužijí u zboží prodávaného za nižší cenu na vadu, pro kterou byla nižší cena ujednána, na opotřebení zboží způsobené jeho obvyklým užíváním, u použitého zboží na vadu odpovídající míře používání nebo opotřebení, kterou zboží mělo při převzetí kupujícím, nebo vyplývá-li to z povahy zboží. Právo z vadného plnění kupujícímu nenáleží, pokud kupující před převzetím zboží věděl, že zboží má vadu, anebo pokud kupující vadu sám způsobil.",
            },
            {
                text: "Práva z odpovědnosti za vady zboží se uplatňují u prodávajícího. Je-li však v potvrzení vydaném prodávajícím ohledně rozsahu práv z odpovědnosti za vady (ve smyslu ustanovení § 2166 občanského zákoníku) uvedena jiná osoba určená k opravě, která je v místě prodávajícího nebo v místě pro kupujícího bližším, uplatní kupující právo na opravu u toho, kdo je určen k provedení opravy. S výjimkou případů, kdy je k provedení opravy určena jiná osoba podle předchozí věty, je prodávající povinen přijmout reklamaci v kterékoli provozovně, v níž je přijetí reklamace možné s ohledem na sortiment prodávaných výrobků nebo poskytovaných služeb, případně i v sídle nebo místě podnikání prodávajícího. Prodávající je povinen kupujícímu vydat písemné potvrzení o tom, kdy kupující právo uplatnil, co je obsahem reklamace a jaký způsob vyřízení reklamace kupující požaduje; a dále potvrzení o datu a způsobu vyřízení reklamace, včetně potvrzení o provedení opravy a době jejího trvání, případně písemné odůvodnění zamítnutí reklamace. Tato povinnost se vztahuje i na jiné osoby určené prodávajícím k provedení opravy.",
            },
            {
                text: "Práva z odpovědnosti za vady zboží může kupující konkrétně uplatnit zejména osobně na adrese Velká Hradební 2800, Ústí nad Labem 400 01, telefonicky na čísle 604 967 443 či elektronickou poštou na adrese info@edhance.cz.",
            },
            {
                text: "Kupující sdělí prodávajícímu, jaké právo si zvolil, při oznámení vady, nebo bez zbytečného odkladu po oznámení vady. Provedenou volbu nemůže kupující změnit bez souhlasu prodávajícího; to neplatí, žádal-li kupující opravu vady, která se ukáže jako neopravitelná.",
            },
            {
                text: "Nemá-li zboží vlastnosti stanovené v čl. 7.2 obchodních podmínek, může kupující požadovat i dodání nového zboží bez vad, pokud to není vzhledem k povaze vady nepřiměřené, ale pokud se vada týká pouze součásti zboží, může kupující požadovat jen výměnu součásti; není-li to možné, může odstoupit od smlouvy. Je-li to však vzhledem k povaze vady neúměrné, zejména lze-li vadu odstranit bez zbytečného odkladu, má kupující právo na bezplatné odstranění vady. Právo na dodání nového zboží, nebo výměnu součásti má kupující i v případě odstranitelné vady, pokud nemůže zboží řádně užívat pro opakovaný výskyt vady po opravě nebo pro větší počet vad. V takovém případě má kupující i právo od smlouvy odstoupit. Neodstoupí-li kupující od smlouvy nebo neuplatní-li právo na dodání nového zboží bez vad, na výměnu jeho součásti nebo na opravu zboží, může požadovat přiměřenou slevu. Kupující má právo na přiměřenou slevu i v případě, že mu prodávající nemůže dodat nové zboží bez vad, vyměnit jeho součást nebo zboží opravit, jakož i v případě, že prodávající nezjedná nápravu v přiměřené době nebo že by zjednání nápravy kupujícímu působilo značné obtíže.",
            },
            {
                text: "Kdo má právo podle § 1923 občanského zákoníku, náleží mu i náhrada nákladů účelně vynaložených při uplatnění tohoto práva. Neuplatní-li však právo na náhradu do jednoho měsíce po uplynutí lhůty, ve které je třeba vytknout vadu, soud právo nepřizná, pokud prodávající namítne, že právo na náhradu nebylo uplatněno včas.",
            },
            {
                text: "Další práva a povinnosti stran související s odpovědností prodávajícího za vady může upravit reklamační řád prodávajícího.",
            },
        ],
    },
    {
        headline: "Další práva a povinnosti smluvních stran",
        ol: [
            {
                text: "Kupující nabývá vlastnictví ke zboží zaplacením celé kupní ceny zboží.",
            },
            {
                text: "Prodávající není ve vztahu ke kupujícímu vázán žádnými kodexy chování ve smyslu ustanovení § 1826 odst. 1 písm. e) občanského zákoníku.",
            },
            {
                text: "Vyřizování stížností spotřebitelů zajišťuje prodávající prostřednictvím elektronické adresy info@edhance.cz. Informaci o vyřízení stížnosti kupujícího zašle prodávající na elektronickou adresu kupujícího.",
            },
            {
                text: "K mimosoudnímu řešení spotřebitelských sporů z kupní smlouvy je příslušná Česká obchodní inspekce, se sídlem Štěpánská 567/15, 120 00 Praha 2, IČ: 000 20 869, internetová adresa: https://adr.coi.cz/cs. Platformu pro řešení sporů on-line nacházející se na internetové adrese http://ec.europa.eu/consumers/odr je možné využít při řešení sporů mezi prodávajícím a kupujícím z kupní smlouvy.",
            },
            {
                text: "Evropské spotřebitelské centrum Česká republika, se sídlem Štěpánská 567/15, 120 00 Praha 2, internetová adresa: http://www.evropskyspotrebitel.cz je kontaktním místem podle Nařízení Evropského parlamentu a Rady (EU) č. 524/2013 ze dne 21. května 2013 o řešení spotřebitelských sporů on-line a o změně nařízení (ES) č. 2006/2004 a směrnice 2009/22/ES (nařízení o řešení spotřebitelských sporů on-line).",
            },
            {
                text: "Prodávající je oprávněn k prodeji zboží na základě živnostenského oprávnění. Živnostenskou kontrolu provádí v rámci své působnosti příslušný živnostenský úřad. Dozor nad oblastí ochrany osobních údajů vykonává Úřad pro ochranu osobních údajů. Česká obchodní inspekce vykonává ve vymezeném rozsahu mimo jiné dozor nad dodržováním zákona č. 634/1992 Sb., o ochraně spotřebitele, ve znění pozdějších předpisů.",
            },
            {
                text: "Kupující tímto přebírá na sebe nebezpečí změny okolností ve smyslu § 1765 odst. 2 občanského zákoníku.",
            },
        ],
    },
    {
        headline: "Ochrana osobních údajů",
        ol: [
            {
                text: "Svou informační povinnost vůči kupujícímu ve smyslu čl. 13 Nařízení Evropského parlamentu a Rady 2016/679 o ochraně fyzických osob v souvislosti se zpracováním osobních údajů a o volném pohybu těchto údajů a o zrušení směrnice 95/46/ES (obecné nařízení o ochraně osobních údajů) (dále jen „nařízení GDPR“) související se zpracováním osobních údajů kupujícího pro účely plnění kupní smlouvy, pro účely jednání o kupní smlouvě a pro účely plnění veřejnoprávních povinností prodávajícího plní prodávající prostřednictvím zvláštního dokumentu.",
            },
        ],
    },
    {
        headline: "Zasílání obchodních sdělení a ukládání cookies",
        ol: [
            {
                text: "Kupující souhlasí ve smyslu ustanovení § 7 odst. 2 zákona č. 480/2004 Sb., o některých službách informační společnosti a o změně některých zákonů (zákon o některých službách informační společnosti), ve znění pozdějších předpisů, se zasíláním obchodních sdělení prodávajícím na elektronickou adresu či na telefonní číslo kupujícího. Svou informační povinnost vůči kupujícímu ve smyslu čl. 13 nařízení GDPR související se zpracováním osobních údajů kupujícího pro účely zasílání obchodních sdělení plní prodávající prostřednictvím zvláštního dokumentu.",
            },
            {
                text: "Své zákonné povinnosti související s případným ukládáním cookies na zařízení kupujícího plní prodávající prostřednictvím zvláštního dokumentu.",
            },
        ],
    },
    {
        headline: "Doručování",
        ol: [
            {
                text: "Kupujícímu může být doručováno na elektronickou adresu kupujícího.",
            },
        ],
    },
    {
        headline: "Závěrečná ustanovení",
        ol: [
            {
                text: "Pokud vztah založený kupní smlouvou obsahuje mezinárodní (zahraniční) prvek, pak strany sjednávají, že vztah se řídí českým právem. Volbou práva podle předchozí věty není kupující, který je spotřebitelem, zbaven ochrany, kterou mu poskytují ustanovení právního řádu, od nichž se nelze smluvně odchýlit, a jež by se v případě neexistence volby práva jinak použila dle ustanovení čl. 6 odst. 1 Nařízení Evropského parlamentu a Rady (ES) č. 593/2008 ze dne 17. června 2008 o právu rozhodném pro smluvní závazkové vztahy (Řím I).",
            },
            {
                text: "Je-li některé ustanovení obchodních podmínek neplatné nebo neúčinné, nebo se takovým stane, namísto neplatných ustanovení nastoupí ustanovení, jehož smysl se neplatnému ustanovení co nejvíce přibližuje. Neplatností nebo neúčinností jednoho ustanovení není dotčena platnost ostatních ustanovení.",
            },
            {
                text: "Kupní smlouva včetně obchodních podmínek je archivována prodávajícím v elektronické podobě a není přístupná.",
            },
            {
                text: "Přílohu obchodních podmínek tvoří vzorový formulář pro odstoupení od kupní smlouvy.",
            },
            {
                text: "Kontaktní údaje prodávajícího: adresa pro doručování Zámecké náměstí 511/10, 415 01, Teplice, adresa elektronické pošty info@edhance.cz, telefon 604 967 443.",
            },
        ],
    },
];

export default content;
