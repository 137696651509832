import { IModule } from "@nazorna-vyuka/types";
import styled from "styled-components";

const StyledVideo = styled.video`
    width: 100%;
`;

export const Module_Video: React.FC<IModule> = ({ url }) => (
    <StyledVideo controls>
        <source src={url} type="video/mp4" />
    </StyledVideo>
);
