import {
    Menu,
    MenuItem,
    ProSidebar,
    SidebarContent,
    SidebarFooter,
    SubMenu,
} from "react-pro-sidebar";
import { Footer } from "../../../layouts/Main/components/Footer";
import {
    StyledMenuItemContent,
    StyledProSidebarContainer,
} from "./SideBar.styled";

interface INavigationItemCommon {
    label: string;
    icon?: React.ReactNode;
    isSelected: boolean;
    onClick: () => void;
}

export type INavigationItem = INavigationItemCommon & {
    children?: INavigationItemCommon[];
};

export interface IProps {
    items: INavigationItem[];
}

export const SideBar: React.FC<IProps> = ({ items }) => {
    const renderItem = ({
        label,
        icon,
        children,
        isSelected,
        onClick,
    }: INavigationItem) => {
        if (children) {
            return (
                <SubMenu key={label} title={label} icon={icon} defaultOpen>
                    {children.map(renderItem)}
                </SubMenu>
            );
        } else {
            return (
                <MenuItem key={label} icon={icon} onClick={onClick}>
                    <StyledMenuItemContent isSelected={isSelected}>
                        {label}
                    </StyledMenuItemContent>
                </MenuItem>
            );
        }
    };

    return (
        <StyledProSidebarContainer>
            <ProSidebar>
                <SidebarContent>
                    <Menu iconShape="round">{items.map(renderItem)}</Menu>
                </SidebarContent>

                <SidebarFooter>
                    <Footer />
                </SidebarFooter>
            </ProSidebar>
        </StyledProSidebarContainer>
    );
};
