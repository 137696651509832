import { useQuery, useWindowSize } from "@honzachalupa/utils";
import { Icons } from "@nazorna-vyuka/assets";
import { ERoutePaths } from "@nazorna-vyuka/types";
import {
    Context,
    courseIcons,
    CoursesAPI,
    log,
    OwnershipsAPI,
    useAuthentication,
    useNavigation,
} from "@nazorna-vyuka/utils";
import { useContext, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useTheme } from "styled-components";
import { CookieBanner, INavigationItem, Loader, SideBar } from "../..";
import { Header } from "./components/Header";
import {
    StyledContent,
    StyledContentContainer,
    StyledIcon,
} from "./Main.styled";

export interface IProps {
    children: React.ReactNode;
}

export const Layout_Main: React.FC<IProps> = ({ children }) => {
    const theme = useTheme();
    const { user } = useAuthentication();

    const { currentRoute, isGlobalLoading, setSearchQuery } =
        useContext(Context);

    const { width } = useWindowSize();

    const { courseId } = useParams();
    const navigateTo = useNavigation();

    const [isUserOwnerOfLessons, setIsUserOwnerOfLessons] =
        useState<boolean>(true);

    const { data: courses } = useQuery(["courses"], () =>
        CoursesAPI.search().catch((error) => {
            log({
                code: "EXCEPTION",
                scope: "Layout_Main",
                error,
            });
        }),
    );

    const courseItems: INavigationItem[] = useMemo(
        () =>
            courses?.map(({ id, title }) => ({
                label: title,
                icon: <StyledIcon src={courseIcons[id]} alt={title} />,
                isSelected: id === courseId,
                onClick: () => {
                    setSearchQuery(undefined);
                    navigateTo.lessons(id);
                },
            })) || [],
        [courses, courseId],
    );

    const items: INavigationItem[] = useMemo(
        () =>
            [
                {
                    label: "Všechny lekce",
                    icon: <StyledIcon src={Icons.home} alt="Všechny lekce" />,
                    isSelected: currentRoute?.path === ERoutePaths.INDEX,
                    onClick: () => {
                        setSearchQuery(undefined);
                        navigateTo.home();
                    },
                },
                isUserOwnerOfLessons
                    ? {
                          label: "Odemčené lekce",
                          icon: (
                              <StyledIcon
                                  src={Icons.unlock}
                                  alt="Odemčené lekce"
                              />
                          ),
                          isSelected:
                              currentRoute?.path === ERoutePaths.COURSES_OWNED,
                          onClick: () => {
                              setSearchQuery(undefined);
                              navigateTo.coursesOwned();
                          },
                      }
                    : null,
                {
                    label: "Kurzy",
                    children: courseItems,
                },
            ].filter(Boolean) as INavigationItem[],
        [courseId, courseItems, currentRoute, isUserOwnerOfLessons],
    );

    const getIsUserOwnerOfLessons = async () => {
        let isUserOwnerOfLessons = false;

        if (user) {
            isUserOwnerOfLessons =
                (await OwnershipsAPI.getUnlockedLessons(user.id)).length > 0;
        }

        setIsUserOwnerOfLessons(isUserOwnerOfLessons);
    };

    useEffect(() => {
        getIsUserOwnerOfLessons();
    }, [user]);

    return width >= parseInt(theme.breakpoints.sm) ? (
        <div>
            <Header
                onLogoClick={() => {
                    setSearchQuery(undefined);
                    navigateTo.home();
                }}
            />

            <StyledContentContainer>
                <SideBar items={items} />

                <StyledContent>{children}</StyledContent>

                <CookieBanner />
            </StyledContentContainer>

            {isGlobalLoading && <Loader isFullscreen />}
        </div>
    ) : (
        <p style={{ fontSize: 20, textAlign: "center", margin: 20 }}>
            Otevřete si web nazornavyuka.cz na Vašem počítači.
        </p>
    );
};
