import { IFormRefProps } from "@honzachalupa/ui/components/molecules/Form";
import { EMAIL_ADDRESS_PATTERN } from "@honzachalupa/utils";
import { ISignedUser } from "@nazorna-vyuka/types";
import {
    Button,
    Form,
    HorizontalDivider,
    IModalRefProps,
    Layout_Main,
    Loader,
    theme,
    Title,
} from "@nazorna-vyuka/ui";
import { Context, OwnershipsAPI, useCurrentPage } from "@nazorna-vyuka/utils";
import React, {
    Fragment,
    useContext,
    useEffect,
    useRef,
    useState,
} from "react";
import { ModalMessage } from "../../../components/molecules/ModalMessage";
import { Modal_DisponentInvitation } from "../../../components/organisms/modals/DisponentInvitation";
import {
    StyledDescription,
    StyledDisponentsList,
    StyledDisponentsList_Item,
    StyledHeadline,
    StyledSubHeadline,
} from "./styled";

interface IFormData {
    emailAddress: string;
}

export const Page_AccountDisponents: React.FC = () => {
    const { title } = useCurrentPage({ title: "Disponenti" });
    const { user } = useContext(Context);

    const modalMessageRef1 = useRef<IModalRefProps>(null);
    const modalMessageRef2 = useRef<IModalRefProps>(null);
    const modalMessageRef3 = useRef<IModalRefProps>(null);
    const modalMessageRef4 = useRef<IModalRefProps>(null);
    const modalSuccessRef = useRef<IModalRefProps>(null);
    const formRef = useRef<IFormRefProps>(null);

    const [disponents, setDisponents] = useState<ISignedUser[]>([]);
    /* const [disponentsSharedBy, setDisponentsSharedBy] = useState<
        IDisponentListItem[]
    >([]); */
    const [formData, setFormData] = useState<IFormData>();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const getDisponents = () => {
        setIsLoading(true);

        OwnershipsAPI.getDisponents(user!.id)
            .then(setDisponents)
            .finally(() => setIsLoading(false));
    };

    const handleSubmitDisponent = ({ emailAddress }: IFormData) => {
        const validate = () => {
            if (!EMAIL_ADDRESS_PATTERN.test(emailAddress)) {
                modalMessageRef1.current?.show();

                return false;
            }

            if (emailAddress === user!.emailAddress) {
                modalMessageRef2.current?.show();

                return false;
            }

            if (
                disponents
                    .map(({ emailAddress }) => emailAddress)
                    .includes(emailAddress)
            ) {
                modalMessageRef4.current?.show();

                return false;
            }

            return true;
        };

        if (validate()) {
            setIsLoading(true);

            OwnershipsAPI.setDisponent(user!.id, emailAddress)
                .then(() => {
                    modalSuccessRef.current?.show();
                    formRef.current?.reset();

                    getDisponents();
                })
                .catch(() => {
                    modalMessageRef3.current?.show();
                })
                .finally(() => setIsLoading(false));
        }
    };

    const handleRemoveDisponent = (emailAddress: string) => {
        setIsLoading(true);

        OwnershipsAPI.unsetDisponent(user!.id, emailAddress)
            .then(() => {
                setDisponents((prevState) =>
                    [...prevState].filter(
                        (disponent) => disponent.emailAddress !== emailAddress,
                    ),
                );
            })
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        getDisponents();

        /* OwnershipsAPI.get(user!.id).then(({ shared }) =>
            Promise.all(
                shared
                    .map(({ userId }) => userId)
                    .flat()
                    .map((userId) => UsersAPI.get(userId)),
            ).then(setDisponentsSharedBy),
        ); */
    }, []);

    return (
        <Layout_Main>
            <ModalMessage
                ref={modalMessageRef1}
                message="Zadaná e-mailová adresa má špatný formát."
                icon={{
                    name: "cross",
                    color: "white",
                    backgroundColor: theme.colors.red,
                }}
            />

            <ModalMessage
                ref={modalMessageRef2}
                message="Zadejte adresu někoho jiného než sebe - například vašeho kolegy."
                icon={{
                    name: "cross",
                    color: "white",
                    backgroundColor: theme.colors.red,
                }}
            />

            <Modal_DisponentInvitation
                ref={modalMessageRef3}
                emailAddress={formData?.emailAddress}
                onSubmit={() => modalMessageRef3.current?.hide()}
            />

            <ModalMessage
                ref={modalMessageRef4}
                message="Uživatel se zadanou e-mailovou adresou je již jako disponent nastaven."
                icon={{
                    name: "cross",
                    color: "white",
                    backgroundColor: theme.colors.red,
                }}
            />

            <ModalMessage
                ref={modalSuccessRef}
                message="Disponent byl přidán."
                icon={{
                    name: "check",
                    color: "white",
                    backgroundColor: theme.colors.green,
                }}
            />

            <Title level={1}>{title}</Title>

            <HorizontalDivider padding={100}>
                <div>
                    <StyledHeadline>Přidat dalšího disponenta</StyledHeadline>

                    <StyledDescription>
                        Můžete přidat až 9 disponentů do svého týmu, se kterými
                        budete sdílet své odemčené lekce.
                    </StyledDescription>

                    <Form
                        ref={formRef}
                        inputs={[
                            {
                                id: "emailAddress",
                                controlType: "input",
                                placeholder: "E-mailová adresa",
                                rules: {
                                    required: true,
                                },
                            },
                        ]}
                        buttonsRenderer={(submit) => [
                            {
                                label: "Přidat",
                                testId: "Submit",
                                onClick: submit,
                            },
                        ]}
                        onChange={setFormData}
                        onSubmit={handleSubmitDisponent}
                    />
                </div>

                <div>
                    <StyledHeadline>Aktuální disponenti</StyledHeadline>

                    <StyledDescription>
                        Zkontrolujte si, kdo všechno má přístup, popřípadě
                        můžete seznam disponentů upravit.
                    </StyledDescription>

                    {disponents.length > 0 && (
                        <Fragment>
                            <StyledSubHeadline>Seznam členů</StyledSubHeadline>

                            <StyledDisponentsList>
                                {disponents.map(
                                    ({
                                        id,
                                        firstName,
                                        lastName,
                                        emailAddress,
                                    }) => (
                                        <StyledDisponentsList_Item key={id}>
                                            <p>
                                                {firstName} {lastName}
                                            </p>

                                            <Button
                                                label="Odebrat disponenta"
                                                color="test"
                                                icon={{
                                                    name: "cross",
                                                    color: theme.colors
                                                        .accentPrimary,
                                                }}
                                                onClick={() =>
                                                    handleRemoveDisponent(
                                                        emailAddress,
                                                    )
                                                }
                                            />
                                        </StyledDisponentsList_Item>
                                    ),
                                )}
                            </StyledDisponentsList>
                        </Fragment>
                    )}

                    {isLoading && <Loader />}
                </div>
            </HorizontalDivider>

            {/* {disponentsSharedBy.length > 0 && (
                <List
                    data={disponentsSharedBy}
                    columns={[
                        {
                            id: "emailAddress",
                            label: "E-mailová adresa",
                            renderer: ({ emailAddress }: IDisponentListItem) =>
                                emailAddress,
                        },
                    ]}
                />
            )} */}
        </Layout_Main>
    );
};
