import {
    EEndpoints,
    IPaymentCreateResponseData,
    IPaymentData,
    IPaymentGetResponseData,
    IResponse,
} from "@nazorna-vyuka/types";
import { api } from "../utils/api";
import { isDevEnv } from "../utils/app";

export const PaymentsAPI = {
    create: (
        data: IPaymentData,
    ): Promise<IResponse<IPaymentCreateResponseData>> =>
        api
            .post(EEndpoints.PAYMENT_GATEWAY.CREATE_PAYMENT, {
                ...data,
                isDevEnv,
            })
            .catch((error) => {
                throw new Error(error);
            }),

    get: (id: number): Promise<IResponse<IPaymentGetResponseData>> =>
        api
            .post(EEndpoints.PAYMENT_GATEWAY.GET_PAYMENT, {
                id,
                isDevEnv,
            })
            .catch((error) => {
                throw new Error(error);
            }),
};
