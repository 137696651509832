import styled from "styled-components";

export const StyledHeadline = styled.h3`
    font-weight: 500;
    font-size: 28px;
    line-height: 31px;
    text-align: center;
    margin-bottom: 40px;
`;

export const StyledSubHeadline = styled.h4`
    font-weight: 500;
    font-size: 20px;
    line-height: 22px;
    text-align: center;
    margin-bottom: 20px;
`;

export const StyledDescription = styled.p`
    font-weight: 400;
    font-size: 20px;
    line-height: 22px;
    margin-bottom: 30px;
`;

export const StyledDisponentsList = styled.ul`
    background: white;
    border: 1px solid #cfcfd1;
    border-radius: 7px;
    padding: 10px 30px;

    p {
        font-weight: 400;
        font-size: 20px;
        line-height: 32px;
        margin-left: 20px;
    }
`;

export const StyledDisponentsList_Item = styled.li`
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
