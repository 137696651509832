import { ERoutePaths } from "@nazorna-vyuka/types";
import { Route } from "react-router-dom";
import { Page_CoursesUnlocked } from "./CoursesUnlocked";

export default [
    <Route
        path={ERoutePaths.COURSES_OWNED}
        element={<Page_CoursesUnlocked />}
    />,
];
