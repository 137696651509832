import styled from "styled-components";

export const StyledContainer = styled.div`
    width: 100%;
    border: 1px solid orange;
    text-align: center;
    margin: 0 5px 30px;
    padding: 10px;
`;

export const StyledContent = styled.p`
    margin-bottom: 15px;
`;
