import { IModalRefProps, Modal, StyledModalTitle } from "@nazorna-vyuka/ui";
import { forwardRef, RefAttributes } from "react";
import { Form_PasswordChange } from "../forms/PasswordChange";

export interface IProps {
    onSubmit: () => void;
}

export const Modal_PasswordChange: React.FC<
    IProps & RefAttributes<IModalRefProps>
> = forwardRef(({ onSubmit }, ref) => (
    <Modal ref={ref}>
        <StyledModalTitle level={3} alignment="left">
            Změnit heslo
        </StyledModalTitle>

        <Form_PasswordChange onSubmit={onSubmit} />
    </Modal>
));
