import styled from "styled-components";

export const StyledContainer = styled.div`
    margin: 20px -20px;
    display: flex;
`;

export const StyledContentItem = styled.div<{
    padding?: number;
    isLineHidden?: boolean;
}>`
    width: 50%;
    padding: 0 ${({ padding }) => padding || 20}px;

    ${({ theme, isLineHidden }) =>
        !isLineHidden &&
        `
        border-right: 1px solid ${theme.colors.grayLight};

        &:last-of-type {
            border-right: none;
        }
    `}
`;
