import { useEventListener } from "@honzachalupa/utils";
import { Context, useNavigation } from "@nazorna-vyuka/utils";
import { useContext, useEffect, useRef, useState } from "react";
import { Button, StyledInputContainer } from "../../../..";
import { StyledContainer } from "./Search.styled";

export const Search: React.FC = () => {
    const { searchQuery, setSearchQuery } = useContext(Context);

    const navigateTo = useNavigation();

    const inputRef = useRef<HTMLInputElement>(null);

    const [query, setQuery] = useState<string | undefined>();
    const [triggerSearchHelper, setTriggerSearchHelper] = useState<number>();

    const handleSearch = () => {
        setSearchQuery(query);

        navigateTo.home();
    };

    useEventListener(
        "keydown",
        ({ key }: KeyboardEvent) => {
            if (key === "Enter") {
                setTriggerSearchHelper(Math.random());
            }
        },
        inputRef,
    );

    useEffect(() => {
        if (query !== searchQuery) {
            setQuery(searchQuery);
        }
    }, [searchQuery]);

    useEffect(() => {
        if (triggerSearchHelper) {
            handleSearch();
        }
    }, [triggerSearchHelper]);

    return (
        <StyledContainer>
            <StyledInputContainer>
                <input
                    ref={inputRef}
                    type="text"
                    value={query || ""}
                    onChange={(e) => {
                        setQuery(e.target.value);
                    }}
                />
            </StyledInputContainer>

            {query && (
                <Button
                    label="Hledat"
                    color="grayLight"
                    onClick={handleSearch}
                />
            )}
        </StyledContainer>
    );
};
