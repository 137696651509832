import { ILesson, IModule } from "@nazorna-vyuka/types";
import { LessonContentModule } from "@nazorna-vyuka/ui";
import { TeachBookAPI } from "@nazorna-vyuka/utils";
import { useEffect, useState } from "react";
import {
    StyledContainer,
    StyledContent,
    StyledContent_ItemsContainer,
    StyledOverview,
    StyledOverview_Headline,
    StyledOverview_Item,
} from "./TeachBook.styled";

interface IProps {
    lessonId: ILesson["id"];
    moduleIDs: string[];
}

export const TeachBook: React.FC<IProps> = ({ lessonId, moduleIDs }) => {
    const [modules, setOverviewModules] = useState<IModule[]>([]);
    const [overviewModules, setContent] = useState<IModule[]>([]);

    const handleScrollToId = (id: string) => {
        const element = document.getElementById(id);
        element?.scrollIntoView();
    };

    useEffect(() => {
        Promise.all(moduleIDs.map((id) => TeachBookAPI.get(id))).then(
            (data) => {
                setOverviewModules(data);
                setContent(
                    data.filter(({ type }) => ["H1", "H2"].includes(type)),
                );
            },
        );
    }, [moduleIDs]);

    return (
        <StyledContainer>
            <StyledContent>
                {modules.map((module) => (
                    <LessonContentModule
                        key={module.id}
                        lessonId={lessonId}
                        module={module}
                    />
                ))}
            </StyledContent>

            <StyledOverview>
                <StyledOverview_Headline>Obsah</StyledOverview_Headline>

                <StyledContent_ItemsContainer>
                    {overviewModules.map(({ id, type, text }) => (
                        <StyledOverview_Item
                            key={id}
                            type={type}
                            onClick={() => handleScrollToId(id)}
                        >
                            {text}
                        </StyledOverview_Item>
                    ))}
                </StyledContent_ItemsContainer>
            </StyledOverview>
        </StyledContainer>
    );
};
