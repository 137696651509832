import { Button, ButtonsGroup } from "@nazorna-vyuka/ui";
import { useAuthentication, useNavigation } from "@nazorna-vyuka/utils";
import { StyledContainer, StyledContent } from "./AuthenticationStatus.styled";

export const AuthenticationStatus: React.FC = () => {
    const navigateTo = useNavigation();
    const {
        isUserAuthenticated,
        isUserAuthenticatedAnonymously,
        isUserInfoCompleted,
    } = useAuthentication();

    return !isUserAuthenticated && !isUserAuthenticatedAnonymously ? (
        <StyledContainer>
            <StyledContent>
                Abyste mohli procházet lekcemi, musíte se nejprve přihlásit.
            </StyledContent>

            <ButtonsGroup>
                <Button label="Přihlásit" onClick={navigateTo.signIn} />
            </ButtonsGroup>
        </StyledContainer>
    ) : !isUserInfoCompleted ? (
        <StyledContainer>
            <StyledContent>
                Abyste mohli procházet lekcemi, musíte nejprve vyplnit
                doplňující informace.
            </StyledContent>

            <ButtonsGroup>
                <Button
                    label="Vyplnit"
                    onClick={navigateTo.accountSetInitialDetails}
                />
            </ButtonsGroup>
        </StyledContainer>
    ) : null;
};
