import { IFormInput } from "@nazorna-vyuka/types";
import { Form, IFormRefProps } from "@nazorna-vyuka/ui";
import { Context, log, UsersAPI } from "@nazorna-vyuka/utils";
import {
    Fragment,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";

export const Form_SharedAccessCode: React.FC = () => {
    const { user } = useContext(Context);

    const formRef = useRef<IFormRefProps>(null);

    const [code, setCode] = useState<number>();
    const [isCoppied, setIsCoppied] = useState<boolean>();

    const handleGenerateNew = () => {
        UsersAPI.generateSharedAccessCode()
            .then((generatedCode) =>
                UsersAPI.updateSharedAccessCode(user!.id, generatedCode)
                    .then(() => {
                        setCode(generatedCode);
                    })
                    .catch((error) => {
                        log({
                            code: "EXCEPTION",
                            scope: "Form_SharedAccessCode",
                            error,
                        });
                    }),
            )
            .catch((error) => {
                log({
                    code: "EXCEPTION",
                    scope: "Form_SharedAccessCode",
                    error,
                });
            });
    };

    const handleCopyToClipboard = () => {
        if (code) {
            navigator.clipboard.writeText(code.toString());

            setIsCoppied(true);
        }
    };

    useEffect(() => {
        if (user) {
            if (user.sharedAccessCode) {
                setCode(user.sharedAccessCode);
            } else {
                UsersAPI.generateSharedAccessCode()
                    .then((code) => {
                        setCode(code);

                        UsersAPI.updateSharedAccessCode(user.id, code).catch(
                            (error) => {
                                log({
                                    code: "EXCEPTION",
                                    scope: "Form_SharedAccessCode",
                                    error,
                                });
                            },
                        );
                    })
                    .catch((error) => {
                        log({
                            code: "EXCEPTION",
                            scope: "Form_SharedAccessCode",
                            error,
                        });
                    });
            }
        }
    }, [user]);

    useEffect(() => {
        if (isCoppied) {
            setTimeout(() => {
                setIsCoppied(false);
            }, 2000);
        }
    }, [isCoppied]);

    return (
        <Fragment>
            <Form
                key={code}
                ref={formRef}
                inputs={useMemo<IFormInput[]>(
                    () => [
                        {
                            id: "code",
                            label: "Váš kód",
                            value: code,
                            controlType: "input",
                            isReadOnly: true,
                        },
                    ],
                    [code],
                )}
                buttonsRenderer={() => [
                    {
                        label: isCoppied ? "Zkopírováno ✓" : "Zkopírovat",
                        onClick: () => handleCopyToClipboard(),
                    },
                    {
                        label: "Vygenerovat nový",
                        color: "secondary",
                        onClick: () => handleGenerateNew(),
                    },
                ]}
            />

            <p>
                Vygenerováním nového kódu znemožníte přihlášení uživatelům,
                kterým jsem již dříve kód poskytli.
            </p>
        </Fragment>
    );
};
