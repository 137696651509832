import { convertDocToItem } from "@honzachalupa/firebase";
import { ISlide } from "@nazorna-vyuka/types";
import { Collections, Database } from "../utils/firebase";

export const SlidesAPI = {
    get: (id: ISlide["id"]): Promise<ISlide> =>
        Database.get(Collections.slides, id)
            .then(convertDocToItem)
            .catch((error) => error),
};
