import { CollectionReference } from "@firebase/firestore";
import {
    ICourse,
    ICourseFull,
    ILesson,
    IModule,
    ISlide,
} from "@nazorna-vyuka/types";
import { Collections, Database } from "@nazorna-vyuka/utils";
import vimtoTaskIDs from "../../../../../data/_vimto.json";
import {
    cleanCourse,
    cleanLesson,
    cleanModule,
    cleanTask,
    cleanTeachBookModule,
    generateKey,
} from "./dataProcessor.cleaners";

const sortByOrder = (a: { Order: number }, b: { Order: number }) =>
    a.Order > b.Order ? 1 : -1;

export const uploadData = (courseSource: ICourseFull) =>
    new Promise((resolve, reject) => {
        const lessonsArray: ILesson[] = [];
        const slidesArray: ISlide[] = [];
        const modulesArray: IModule[] = [];
        const teachBookModulesArray: IModule[] = [];
        const tasksArray: any[] = [];

        const lessons = courseSource.Lessons.filter(
            ({ IsDeleted }) => !IsDeleted,
        );

        const courseCleaned = cleanCourse(courseSource);
        const courseKey = courseCleaned.id;

        const course: ICourse = {
            ...courseCleaned,
            lessonsIDs:
                lessons.map((_, lessonIndex) =>
                    generateKey("lesson", lessonIndex, courseKey),
                ) || [],
        };

        lessons?.forEach((lesson, lessonIndex) => {
            const slides = lesson.LessonGuide?.Slides?.filter(
                ({ IsDeleted }) => !IsDeleted,
            );
            const teachBookModules = lesson.TeachBook?.Modules?.filter(
                ({ IsDeleted }) => !IsDeleted,
            );
            const tasks = lesson.Tasks?.filter(
                ({ IsDeleted }) => !IsDeleted,
            ).filter(({ TaskKind }) => TaskKind === "InteractiveTask");

            const lessonKey = generateKey("lesson", lessonIndex, courseKey);

            lessonsArray.push({
                ...cleanLesson(lesson, lessonIndex, courseKey),
                slidesIDs:
                    slides?.map((_, slideIndex) =>
                        generateKey("slide", slideIndex, lessonKey),
                    ) || [],
                tasksIDs:
                    tasks?.map((_, taskIndex) =>
                        generateKey("task", taskIndex, lessonKey),
                    ) || [],
                teachBookModuleIDs:
                    teachBookModules
                        ?.sort(sortByOrder)
                        .map((_, teachBookModuleIndex) =>
                            generateKey(
                                "teach-book-module",
                                teachBookModuleIndex,
                                lessonKey,
                            ),
                        ) || [],
            });

            slides?.forEach((slide, slideIndex) => {
                const modules = slide.Modules?.filter(
                    ({ IsDeleted }) => !IsDeleted,
                ).sort(sortByOrder);

                const slideKey = generateKey("slide", slideIndex, lessonKey);

                slidesArray.push({
                    id: slideKey,
                    moduleIDs:
                        modules?.map((_, moduleIndex) =>
                            generateKey("module", moduleIndex, slideKey),
                        ) || [],
                } as ISlide);

                modules?.forEach((module, moduleIndex) => {
                    modulesArray.push(
                        cleanModule(module, moduleIndex, slideKey),
                    );
                });
            });

            teachBookModules?.forEach(
                (teachBookModule, teachBookModuleIndex) => {
                    teachBookModulesArray.push(
                        cleanTeachBookModule(
                            teachBookModule,
                            teachBookModuleIndex,
                            lessonKey,
                        ),
                    );
                },
            );

            tasks?.forEach((task, taskIndex) => {
                tasksArray.push(
                    cleanTask(task, taskIndex, lessonKey, vimtoTaskIDs),
                );
            });
        });

        const uploadQueue: {
            data: ICourse[];
            collection: CollectionReference;
        }[] = [
            {
                data: [course],
                collection: Collections.courses,
            },
            {
                data: lessonsArray,
                collection: Collections.lessons,
            },
            {
                data: slidesArray,
                collection: Collections.slides,
            },
            {
                data: modulesArray,
                collection: Collections.modules,
            },
            {
                data: teachBookModulesArray,
                collection: Collections.teachBookModules,
            },
            {
                data: tasksArray,
                collection: Collections.tasks,
            },
        ];

        Promise.all(
            uploadQueue.map(({ data, collection }) =>
                data.map((item) =>
                    Database.set(collection, item.id, item)
                        .then(resolve)
                        .catch(reject),
                ),
            ),
        )
            .then(resolve)
            .catch(reject);
    });
