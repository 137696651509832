import styled from "styled-components";

export const StyledList = styled.ul`
    margin-bottom: 50px;

    li {
        list-style: none;
        font-weight: 400;
        font-size: 20px;
        line-height: 26px;
    }
`;

export const StyledHeadline = styled.h3`
    font-weight: 500;
    font-size: 28px;
    line-height: 31px;
    text-align: center;
    margin-bottom: 40px;
`;
