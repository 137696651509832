import {
    ERoutePaths,
    ICourse,
    ILesson,
    ISignedUser,
} from "@nazorna-vyuka/types";
import { useNavigate } from "react-router-dom";

export const useNavigation = () => {
    const navigate = useNavigate();

    const navigateToPath = (path: string, replace?: boolean) => {
        navigate(path, { replace });
    };

    const navigateToUrl = (url: string, newTab?: boolean) => {
        if (newTab) {
            window.open(url);
        } else {
            window.location.href = url;
        }
    };

    return {
        signUpInfo: () => {
            navigate(ERoutePaths.AUTHENTICATION_SIGN_UP_INFO);
        },
        signUp: () => {
            navigate(ERoutePaths.AUTHENTICATION_SIGN_UP);
        },
        signIn: () => {
            navigate(ERoutePaths.AUTHENTICATION_SIGN_IN);
        },
        signOut: () => {
            navigate(ERoutePaths.AUTHENTICATION_SIGN_OUT);
        },
        home: () => {
            navigate(ERoutePaths.INDEX);
        },
        coursesOwned: () => {
            navigate(ERoutePaths.COURSES_OWNED);
        },
        lessons: (courseId: ICourse["id"]) => {
            navigate(ERoutePaths.COURSE.replace(":courseId", courseId));
        },
        lessonDetail: (
            courseId: ICourse["id"],
            lessonTitle: ILesson["title"],
        ) => {
            navigate(
                ERoutePaths.LESSON_DETAIL.replace(
                    ":courseId",
                    courseId,
                ).replace(":lessonTitle", lessonTitle),
            );
        },
        accountSetInitialDetails: () => {
            navigate(ERoutePaths.AUTHENTICATION_SIGN_UP_DETAILS);
        },
        accountDetails: () => {
            navigate(ERoutePaths.ACCOUNT_DETAILS);
        },
        accountDetailsEdit: () => {
            navigate(ERoutePaths.ACCOUNT_DETAILS_EDIT);
        },
        accountCredit: () => {
            navigate(ERoutePaths.ACCOUNT_CREDIT);
        },
        accountDisponents: () => {
            navigate(ERoutePaths.ACCOUNT_DISPONENTS);
        },
        administration: () => {
            navigate(ERoutePaths.ADMINISTRATION);
        },
        administrationDataManager: () => {
            navigate(ERoutePaths.ADMINISTRATION_DATA_MANAGER);
        },
        administrationCreditTransactions: () => {
            navigate(ERoutePaths.ADMINISTRATION_CREDIT_TRANSACTIONS);
        },
        termsAndConditions: () => {
            navigate(ERoutePaths.TERMS_AND_CONDITION);
        },
        notFound: () => {
            navigate(ERoutePaths.NOT_FOUND);
        },
        mailClient: (emailAddress: ISignedUser["emailAddress"]) => {
            document.location.href = `mailto:${emailAddress}`;
        },
        path: navigateToPath,
        url: navigateToUrl,
    };
};
