import { useQuery } from "@honzachalupa/utils";
import { ICourse, ILesson } from "@nazorna-vyuka/types";
import {
    Context,
    CoursesAPI,
    formatUrlTitle,
    LessonsAPI,
    log,
} from "@nazorna-vyuka/utils";
import { useContext } from "react";

export const useData = ({
    courseId,
    lessonTitle,
}: {
    courseId: ICourse["id"];
    lessonTitle: ILesson["title"];
}) => {
    const { storageData } = useContext(Context);

    const courseQuery = useQuery(
        ["course"],
        () =>
            CoursesAPI.get(courseId).catch((error) => {
                log({
                    code: "EXCEPTION",
                    scope: "Page_LessonDetail",
                    error,
                });
            }),
        {
            keepPreviousData: false,
        },
    );

    const lessonQuery = useQuery(
        ["lesson"],
        () =>
            LessonsAPI.search()
                .then((data) =>
                    data.find(({ id, title }) => {
                        const sortId =
                            storageData.find(({ lessonId }) => lessonId === id)
                                ?.sortId || 0;

                        return formatUrlTitle(sortId, title) === lessonTitle;
                    }),
                )
                .catch((error) => {
                    log({
                        code: "EXCEPTION",
                        scope: "Page_LessonDetail",
                        error,
                    });
                }),
        {
            keepPreviousData: false,
        },
    );

    return {
        course: courseQuery.data,
        lesson: lessonQuery.data,
        isLoading: courseQuery.isLoading || lessonQuery.isLoading,
    };
};
