import { ITitleProps, Title } from "@honzachalupa/ui";
import styled from "styled-components";

export const StyledTitle = styled(Title)<{ level: ITitleProps["level"] }>`
    color: black;

    ${({ level }) =>
        level === 1 &&
        `
            margin-top: 50px;
            margin-bottom: 50px;
        `}
`;
