import { ERoutePaths } from "@nazorna-vyuka/types";
import { Route } from "react-router-dom";
import { Page_AccountCredit } from "./Credit";
import { Page_AccountDetails } from "./Details";
import { Page_AccountDetailsEdit } from "./DetailsEdit";
import { Page_AccountDisponents } from "./Disponents";

export default [
    <Route
        path={ERoutePaths.ACCOUNT_DETAILS}
        element={<Page_AccountDetails />}
    />,

    <Route
        path={ERoutePaths.ACCOUNT_DETAILS_EDIT}
        element={<Page_AccountDetailsEdit />}
    />,

    <Route
        path={ERoutePaths.ACCOUNT_CREDIT}
        element={<Page_AccountCredit />}
    />,

    <Route
        path={ERoutePaths.ACCOUNT_DISPONENTS}
        element={<Page_AccountDisponents />}
    />,
];
