import { IContext } from "@nazorna-vyuka/types";
import { createContext } from "react";

export const initialContext: IContext = {
    STORAGE_URL: "https://ftp.nazornavyuka.cz",
    user: undefined,
    searchQuery: undefined,
    currentRoute: undefined,
    storageData: undefined as any,
    isGlobalLoading: false,
    isScrollDisabled: false,
    isCookiesAllowed: false,
    setIsGlobalLoading: () => {},
    setSearchQuery: () => {},
    setCurrentRoute: () => {},
    setIsCookiesAllowed: () => {},
    setIsScrollDisabled: () => {},
};

export const Context = createContext(initialContext);
