import { useEventListener } from "@honzachalupa/utils";
import { ILesson } from "@nazorna-vyuka/types";
import { Context } from "@nazorna-vyuka/utils";
import {
    RefObject,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { StyledContainer, StyledIframe } from "./Animation.styled";
import { Controls } from "./components/Controls";

export interface IProps {
    lessonId: ILesson["id"];
    initialSlideIndex?: number;
    isControlsHidden?: boolean;
    onFrameChange: (index: number) => void;
}

interface IAction {
    name: "goToSlide";
    data: {
        slideId: string;
    };
}

const Iframe: React.FC<{
    url: string;
    setRef: (ref: any) => void;
}> = ({ url, setRef }) => {
    const ref = useRef<HTMLIFrameElement>(null);

    useEffect(() => {
        setRef(ref);
    }, []);

    return <StyledIframe ref={ref} src={url} title="iframe" />;
};

export const Animation: React.FC<IProps> = ({
    lessonId,
    initialSlideIndex,
    isControlsHidden,
    onFrameChange,
}) => {
    const { STORAGE_URL } = useContext(Context);

    const [iframeRef, setRef] = useState<RefObject<HTMLIFrameElement>>();
    const [index, setIndex] = useState<number>(0);
    const [slides, setSlides] = useState<string[]>([]);
    const [isFullscreenOn, setIsFullscreenOn] = useState<boolean>(false);
    const [isActionInProgress, setIsActionInProgress] = useState<boolean>(true);

    const animationUrl = useMemo(
        () => `${STORAGE_URL}/getAnimation.html?lessonId=${lessonId}`,
        [lessonId],
    );

    const triggerAction = (name: IAction["name"], data: IAction["data"]) => {
        setIsActionInProgress(true);

        if (name === "goToSlide") {
            iframeRef?.current?.contentWindow?.postMessage(
                {
                    action: name,
                    data,
                },
                "*",
            );
        }
    };

    const handleChangeFrame = (direction: "next" | "prev") => {
        setIndex((prevState) => {
            const nextState = prevState + (direction === "next" ? 1 : -1);

            triggerAction("goToSlide", {
                slideId: slides[nextState],
            });

            onFrameChange(nextState);

            return nextState;
        });
    };

    useEffect(() => {
        if (initialSlideIndex && slides.length > 0) {
            triggerAction("goToSlide", {
                slideId: slides[initialSlideIndex],
            });
        }
    }, [initialSlideIndex, slides]);

    const setRefCallback = useCallback(setRef, []);

    useEventListener("message", ({ origin, data: { action, data } }: any) => {
        if (origin !== window.location.origin) {
            if (action === "animation-loaded") {
                setSlides(data.slides);
            } else if (action === "action-finished") {
                /* if (data.action === "goToSlide") {
                        setIndex(data.data.index);
                    } */
            } else if (action === "animation-failed") {
                console.error("Animation failed to load.");
            }

            setIsActionInProgress(false);
        }
    });

    return (
        <StyledContainer isFullscreenOn={isFullscreenOn}>
            <Iframe url={animationUrl} setRef={setRefCallback} />

            {!isControlsHidden && (
                <Controls
                    index={index}
                    framesCount={slides.length}
                    isFullscreenOn={isFullscreenOn}
                    isActionInProgress={isActionInProgress}
                    onFullscreenModeChange={() =>
                        setIsFullscreenOn((prevState) => !prevState)
                    }
                    onSlideChange={handleChangeFrame}
                />
            )}
        </StyledContainer>
    );
};
