import { ILesson, IModule } from "@nazorna-vyuka/types";
import { useState } from "react";

import { Animation } from "../../../molecules/Animation";
import { Form } from "../../../molecules/Form";

export const Module_PresentationSlides: React.FC<
    IModule & { lessonId: ILesson["id"] }
> = ({ slideIndexes, lessonId }) => {
    const [selectedIndex, setSelectedIndex] = useState<number>(
        slideIndexes?.[0] || 0,
    );

    return slideIndexes ? (
        <div style={{ margin: "40px 0" }}>
            {slideIndexes.length > 1 ? (
                <Form
                    inputs={[
                        {
                            id: "slide",
                            label: "",
                            controlType: "select",
                            options: slideIndexes?.map((index) => ({
                                id: index.toString(),
                                label: `Slide ${index + 1}`,
                            })),
                        },
                    ]}
                    onChange={({ slide }) => setSelectedIndex(Number(slide))}
                />
            ) : (
                <p style={{ fontSize: 14, marginBottom: 5 }}>
                    Slide {selectedIndex + 1}
                </p>
            )}

            <Animation
                lessonId={lessonId}
                initialSlideIndex={selectedIndex}
                isControlsHidden
                onFrameChange={console.log}
            />
        </div>
    ) : null;
};
