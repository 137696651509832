import { ERoutePaths } from "@nazorna-vyuka/types";
import { Route } from "react-router-dom";
import { Page_AuthenticationSignIn } from "./SignIn";
import { Page_AuthenticationSignOut } from "./SignOut";
import { Page_AuthenticationSignUp } from "./SignUp";
import { Page_AuthenticationSignUpDetails } from "./SignUpDetails";
import { Page_AuthenticationSignUpInfo } from "./SignUpInfo";

export default [
    <Route
        path={ERoutePaths.AUTHENTICATION_SIGN_UP}
        element={<Page_AuthenticationSignUp />}
    />,

    <Route
        path={ERoutePaths.AUTHENTICATION_SIGN_UP_INFO}
        element={<Page_AuthenticationSignUpInfo />}
    />,

    <Route
        path={ERoutePaths.AUTHENTICATION_SIGN_IN}
        element={<Page_AuthenticationSignIn />}
    />,

    <Route
        path={ERoutePaths.AUTHENTICATION_SIGN_UP_DETAILS}
        element={<Page_AuthenticationSignUpDetails />}
    />,

    <Route
        path={ERoutePaths.AUTHENTICATION_SIGN_OUT}
        element={<Page_AuthenticationSignOut />}
    />,
];
