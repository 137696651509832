import { Button, ButtonsGroup, Layout_Main, Title } from "@nazorna-vyuka/ui";
import { useCurrentPage } from "@nazorna-vyuka/utils";

export const Page_AuthenticationSignUpInfo: React.FC = () => {
    const { title, navigateTo } = useCurrentPage({ title: "Zkusit zdarma" });

    return (
        <Layout_Main>
            <Title level={1} alignment="left">
                {title}
            </Title>

            <p>
                Pokud vás zajímá, jak vypadají jednotlivé lekce, stačí se
                zaregistrovat. Registrací získáte 750 kreditů, za které můžete
                otevřít až tři lekce.
            </p>

            <br />
            <br />

            <ButtonsGroup alignment="left">
                <Button label="Registrovat" onClick={navigateTo.signUp} />
            </ButtonsGroup>
        </Layout_Main>
    );
};
