import { LayoutMargin, Layout_Main, Title } from "@nazorna-vyuka/ui";
import { useCurrentPage } from "@nazorna-vyuka/utils";
import React from "react";
import { Form_AccountDetails } from "../../../components/organisms/forms/AccountDetails";

export const Page_AuthenticationSignUpDetails: React.FC = () => {
    const { title, navigateTo } = useCurrentPage({ title: "Registrace" });

    return (
        <Layout_Main>
            <Title level={1}>{title}</Title>

            <LayoutMargin>
                <Title level={3} alignment="left">
                    Mé údaje
                </Title>

                <Form_AccountDetails
                    onSave={() => {
                        navigateTo.home();

                        window.location.reload();
                    }}
                />
            </LayoutMargin>
        </Layout_Main>
    );
};
