import { formatCurrency } from "@honzachalupa/utils";
import { IFormInput } from "@nazorna-vyuka/types";
import { Form } from "@nazorna-vyuka/ui";
import {
    Context,
    envUrl,
    log,
    PaymentsAPI,
    useNavigation,
} from "@nazorna-vyuka/utils";
import { useContext, useMemo, useState } from "react";

interface IProps {
    onSubmit: () => void;
}

interface IFormData {
    amount: number;
}

export const Form_CreditTopUp: React.FC<IProps> = ({ onSubmit }) => {
    const { setIsGlobalLoading } = useContext(Context);

    const navigateTo = useNavigation();

    const [formData, setFormData] = useState<IFormData>();

    const handlePay = () => {
        setIsGlobalLoading(true);

        if (formData) {
            PaymentsAPI.create({
                orderId: 1,
                amount: formData.amount,
                description: `Zakoupení kreditu v hodnotě ${formatCurrency(
                    formData.amount,
                    "CZK",
                )}.`,
                callbackUrl: `${envUrl}/potvrzeni-platby`,
            })
                .then(({ data }) => {
                    navigateTo.url(data.gw_url);
                })
                .catch((error) => {
                    setIsGlobalLoading(false);

                    log({
                        code: "EXCEPTION",
                        scope: "Form_CreditTopUp",
                        error,
                    });
                });
        }
    };

    const options = useMemo(
        () =>
            [250, 500, 1000, 2500, 5000, 7500, 10000, 15000, 20000].map(
                (amount) => ({
                    id: amount.toString(),
                    label: `${amount} bodů`,
                }),
            ),
        [],
    );

    return (
        <Form
            inputs={useMemo<IFormInput[]>(
                () => [
                    {
                        id: "amount",
                        label: "Vyberte čásku pro nabití (1 Kč = 1 bod)",
                        value: options[0].id,
                        controlType: "select",
                        options,
                        rules: {
                            required: true,
                        },
                    },
                ],
                [],
            )}
            buttonsRenderer={(submit) => [
                {
                    label: "Zaplatit",
                    onClick: submit,
                },
            ]}
            onChange={setFormData}
            onSubmit={handlePay}
        />
    );
};
