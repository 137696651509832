import { IModalRefProps, Modal, StyledModalTitle } from "@nazorna-vyuka/ui";
import { forwardRef, RefAttributes } from "react";
import { Form_SignInWithAccessCode } from "../forms/SignInWithAccessCode";

export const Modal_SignInWithAccessCode: React.FC<
    RefAttributes<IModalRefProps>
> = forwardRef((_, ref) => (
    <Modal ref={ref}>
        <StyledModalTitle level={3} alignment="left">
            Přihlásit se kódem učitele
        </StyledModalTitle>

        <Form_SignInWithAccessCode />
    </Modal>
));
