import { Context } from "@nazorna-vyuka/utils";
import { Fragment, useContext } from "react";
import { StyledVideo } from "./Videos.styled";

interface IProps {
    urls: string[];
}

export const Videos: React.FC<IProps> = ({ urls }) => {
    const { STORAGE_URL } = useContext(Context);

    return (
        <Fragment>
            {urls.map((url) => (
                <StyledVideo key={url} controls>
                    <source src={`${STORAGE_URL}${url}`} />
                </StyledVideo>
            ))}
        </Fragment>
    );
};
