import { Auth } from "@honzachalupa/firebase";
import { Button, ButtonsGroup, IModalRefProps } from "@nazorna-vyuka/ui";
import { log, useNavigation, UsersAPI } from "@nazorna-vyuka/utils";
import { Fragment, useRef } from "react";
import { Modal_SignInWithAccessCode } from "../../organisms/modals/SignInWithAccessCode";

interface IProps {
    action: "sign-up" | "sign-in";
}

export const ThirdPartyAuthOptions: React.FC<IProps> = ({ action }) => {
    const navigateTo = useNavigation();

    const modalRef = useRef<IModalRefProps>(null);

    const getButtonLabel = (providerLabel: string) =>
        action === "sign-up"
            ? `Registrovat se přes ${providerLabel}`
            : `Přihlásit se přes ${providerLabel}`;

    const signInWithProvider = (provider: any) => {
        Auth.signInWithPopup(Auth.getAuth(), provider)
            .then(async ({ user }) => {
                const isExistingUser = await UsersAPI.get(user?.uid).then(
                    ({ emailAddress }) => !!emailAddress,
                );

                if (isExistingUser) {
                    navigateTo.home();
                } else {
                    UsersAPI.create(user?.uid, user.email?.trim() || "")
                        .then(navigateTo.accountSetInitialDetails)
                        .catch((error) => {
                            log({
                                code: "EXCEPTION",
                                scope: "ThirdPartyAuthOptions",
                                error,
                            });
                        });
                }
            })
            .catch((error) => {
                if (error.code !== "auth/popup-closed-by-user") {
                    log({
                        code: "EXCEPTION",
                        scope: "ThirdPartyAuthOptions",
                        error,
                        data: { action },
                    });
                }
            });
    };

    const handleSignUp_Google = () => {
        signInWithProvider(new Auth.GoogleAuthProvider());
    };

    const handleSignUp_Facebook = () => {
        signInWithProvider(new Auth.FacebookAuthProvider());
    };

    const handleSignUp_sharedAccessCode = () => {
        modalRef.current?.show();
    };

    return (
        <Fragment>
            <Modal_SignInWithAccessCode ref={modalRef} />

            <ButtonsGroup orientation="vertical">
                <Button
                    label={getButtonLabel("Google")}
                    color="google"
                    icon={{ name: "googleColored" }}
                    onClick={handleSignUp_Google}
                />

                <Button
                    label={getButtonLabel("Facebook")}
                    color="facebook"
                    icon={{ name: "facebook", color: "white" }}
                    onClick={handleSignUp_Facebook}
                />

                <Button
                    label="Přihlásit se kódem učitele"
                    color="green"
                    onClick={handleSignUp_sharedAccessCode}
                />
            </ButtonsGroup>
        </Fragment>
    );
};
