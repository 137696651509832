import { IFormInput } from "@nazorna-vyuka/types";
import { Form, IFormRefProps } from "@nazorna-vyuka/ui";
import { Context, UsersAPI } from "@nazorna-vyuka/utils";
import { useContext, useMemo, useRef } from "react";

interface IProps {
    onSave: () => void;
}

interface IFormData {
    emailAddress: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    schoolName: string;
}

export const Form_AccountDetails: React.FC<IProps> = ({ onSave }) => {
    const { user } = useContext(Context);

    const formRef = useRef<IFormRefProps>(null);

    const handleSubmit = (formData: IFormData) => {
        UsersAPI.update(user!.id, {
            firstName: formData.firstName.trim(),
            lastName: formData.lastName.trim(),
            phoneNumber: formData.phoneNumber.trim(),
            schoolName: formData.schoolName.trim(),
        }).then(() => {
            onSave();
        });
    };

    return (
        <Form
            ref={formRef}
            columns={2}
            inputs={useMemo<IFormInput[]>(
                () => [
                    {
                        id: "firstName",
                        label: "Jméno",
                        value: user?.firstName,
                        controlType: "input",
                        rules: {
                            required: true,
                        },
                    },
                    {
                        id: "lastName",
                        label: "Příjmení",
                        value: user?.lastName,
                        controlType: "input",
                        rules: {
                            required: true,
                        },
                    },
                    {
                        id: "phoneNumber",
                        label: "Telefon",
                        value: user?.phoneNumber,
                        controlType: "input",
                        rules: {
                            required: true,
                        },
                    },
                    {
                        id: "schoolName",
                        label: "Název školy",
                        value: user?.schoolName,
                        controlType: "input",
                        rules: {
                            required: true,
                        },
                    },
                ],
                [user],
            )}
            buttonsRenderer={(submit) => [
                {
                    label: "Uložit změny",
                    onClick: submit,
                },
            ]}
            onSubmit={handleSubmit}
        />
    );
};
