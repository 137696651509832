import { IModule } from "@nazorna-vyuka/types";
import styled from "styled-components";
import { MarkdownText } from "../../../atoms/MarkdownText";

const StyledContainer = styled.div`
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    margin: 20px 0;
`;

export const Module_Paragraph: React.FC<IModule> = ({ text }) => (
    <StyledContainer>
        <MarkdownText value={text} />
    </StyledContainer>
);
