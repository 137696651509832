import { IModule } from "@nazorna-vyuka/types";
import { RiQuestionAnswerLine } from "react-icons/ri";
import styled from "styled-components";
import { CollapsibleBlockWithMultipleLabels } from "../../../atoms/CollapsibleBlockWithMultipleLabels";
import { MarkdownText } from "../../../atoms/MarkdownText";

const StyledItem = styled.div`
    & + & {
        margin-top: 20px;
    }
`;

const StyledQuestion = styled.div`
    font-weight: 600;
    margin-bottom: 5px;
`;

export const Module_QuestionAnswer: React.FC<IModule> = ({ items }) => (
    <CollapsibleBlockWithMultipleLabels
        labels={
            items?.map(({ question }) => <MarkdownText value={question} />) ||
            []
        }
        icon={<RiQuestionAnswerLine />}
        color="#FA9573"
    >
        {items?.map(({ question, answer }) => (
            <StyledItem key={question}>
                <StyledQuestion>
                    <MarkdownText value={question} />
                </StyledQuestion>

                <MarkdownText value={answer} />
            </StyledItem>
        ))}
    </CollapsibleBlockWithMultipleLabels>
);
