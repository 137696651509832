import { ReactNode, useState } from "react";
import ReactCollapsible from "react-collapsible";
import {
    StyledContainer,
    StyledIconContainer,
    StyledLabelsContainer,
    StyledText,
    StyledToggleIcon,
} from "./CollapsibleBlockWithMultipleLabels.styled";

interface IProps {
    labels: (string | ReactNode)[];
    icon?: any;
    color: string;
    children: ReactNode;
}

export const CollapsibleBlockWithMultipleLabels: React.FC<IProps> = ({
    labels,
    icon,
    color,
    children,
}) => {
    const [isOpened, setIsOpened] = useState<boolean>();

    return (
        <StyledContainer color={color} hasIcon={!!icon}>
            {!isOpened && icon ? (
                <StyledIconContainer>{icon}</StyledIconContainer>
            ) : null}

            <StyledToggleIcon
                name={isOpened ? "arrowTop" : "arrowDown"}
                isClickDisabled={true}
            />

            <ReactCollapsible
                trigger={
                    !isOpened ? (
                        <StyledLabelsContainer
                            style={{ display: "flex", flexDirection: "column" }}
                        >
                            {labels.map((label) =>
                                typeof label === "string" ? (
                                    <p>{label}</p>
                                ) : (
                                    label
                                ),
                            )}
                        </StyledLabelsContainer>
                    ) : (
                        <StyledToggleIcon
                            name={isOpened ? "arrowTop" : "arrowDown"}
                        />
                    )
                }
                transitionTime={250}
                easing="ease-in-out"
                onTriggerOpening={() => setIsOpened(true)}
                onTriggerClosing={() => setIsOpened(false)}
            >
                <StyledText>{children}</StyledText>
            </ReactCollapsible>
        </StyledContainer>
    );
};
