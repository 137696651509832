import { useAuthentication, useNavigation } from "@nazorna-vyuka/utils";
import { Button, ButtonsGroup } from "../../../../";
import { Account } from "./components/Account";
import { StyledContainer } from "./Navigation.styled";

export const Navigation: React.FC = () => {
    const { isUserAuthenticated, isUserAuthenticatedAnonymously } =
        useAuthentication();
    const navigateTo = useNavigation();

    return (
        <StyledContainer>
            <ButtonsGroup>
                {!isUserAuthenticated && !isUserAuthenticatedAnonymously && (
                    <Button
                        label="Zkusit zdarma"
                        color="green"
                        onClick={navigateTo.signUpInfo}
                    />
                )}
            </ButtonsGroup>

            <Account />
        </StyledContainer>
    );
};
