import { ICourseFull } from "@nazorna-vyuka/types";
import { Button, ButtonsGroup, Loader, Title } from "@nazorna-vyuka/ui";
import { log } from "@nazorna-vyuka/utils";
import { ChangeEvent, Fragment, useRef, useState } from "react";
import { uploadData } from "../../../../utils/dataProcessor";
import { StyledFileSelector, StyledStatusMessage } from "./Import.styled";

export const Import: React.FC = () => {
    const fileSelectorRef = useRef<HTMLInputElement>(null);

    const [data, setData] = useState<ICourseFull | undefined>();
    const [importProgress, setImportProgress] = useState<
        "NOT_RUN" | "SUCCESS" | "FAIL"
    >("NOT_RUN");
    const [isLoading, setIsLoading] = useState<boolean>();

    const readFile = (e: ChangeEvent<HTMLInputElement>) => {
        setImportProgress("NOT_RUN");

        const { files } = e.target;

        if (files && files.length === 1) {
            const file = files[0];

            setIsLoading(true);

            const reader = new FileReader();
            reader.onload = (e) => {
                setData(JSON.parse(e.target?.result as string));
                setIsLoading(false);
            };
            reader.readAsText(file);
        }
    };

    const handleImport = () => {
        setIsLoading(true);

        log({
            code: "INFO",
            scope: "Import",
            message: "Import started",
        });

        uploadData(data!)
            .then(() => {
                setImportProgress("SUCCESS");

                log({
                    code: "INFO",
                    scope: "Import",
                    message: "Import finished",
                });
            })
            .catch((error) => {
                setImportProgress("FAIL");

                log({
                    code: "EXCEPTION",
                    scope: "Import",
                    error,
                });
            })
            .finally(() => {
                setData(undefined);
                setIsLoading(false);
            });
    };

    return (
        <div>
            <Title level={3} alignment="left">
                Import kurzů
            </Title>

            <StyledFileSelector
                ref={fileSelectorRef}
                name="file"
                type="file"
                accept="application/json"
                onChange={readFile}
            />

            {importProgress === "NOT_RUN" && (
                <ButtonsGroup alignment="left">
                    {data ? (
                        <Button
                            label={`Importovat kurz ${data.Title}`}
                            color="red"
                            isDisabled={!data}
                            onClick={handleImport}
                        />
                    ) : (
                        <Button
                            label="Vybrat JSON soubor"
                            onClick={() => fileSelectorRef.current?.click()}
                        />
                    )}
                </ButtonsGroup>
            )}

            {importProgress === "SUCCESS" && (
                <Fragment>
                    <StyledStatusMessage>
                        Úspěšně naimportováno.
                    </StyledStatusMessage>

                    <Button
                        label="Importovat další data"
                        color="accentPrimary"
                        onClick={() => setImportProgress("NOT_RUN")}
                    />
                </Fragment>
            )}

            {importProgress === "FAIL" && (
                <p>Při importu došlo k neznámé chybě.</p>
            )}

            <br />
            <br />

            {isLoading && <Loader isFullscreen />}
        </div>
    );
};
