import styled from "styled-components";

export const StyledFileSelector = styled.input`
    display: none;
`;

export const StyledStatusMessage = styled.div`
    color: ${({ theme }) => theme.colors.green};
    font-size: 15px;
    font-weight: 500;
    margin: 0 0 20px 2px;
`;
