import { ICourse, ILesson } from "@nazorna-vyuka/types";
import {
    Button,
    IModalRefProps,
    Modal,
    StyledModalButtonsGroup,
    StyledModalTitle,
} from "@nazorna-vyuka/ui";
import {
    Context,
    CreditTransactionsAPI,
    formatUrlTitle,
    OwnershipsAPI,
    useAuthentication,
    useNavigation,
} from "@nazorna-vyuka/utils";
import {
    forwardRef,
    Fragment,
    RefAttributes,
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react";
import { StyledContent, StyledNote } from "./LessonActivation.styled";

export interface IProps {
    courseId: ICourse["id"];
    lessonId: ILesson["id"];
    lessonTitle: ILesson["title"];
}

export const Modal_LessonActivation: React.FC<
    IProps & RefAttributes<IModalRefProps>
> = forwardRef(({ courseId, lessonId, lessonTitle }, ref) => {
    const navigateTo = useNavigation();
    const { user } = useAuthentication();

    const { storageData } = useContext(Context);

    const [creditAmount, setCreditAmount] = useState<number>(0);

    const isEnoughCredit = creditAmount >= 250;

    const sortId = useMemo(
        () =>
            storageData.find((item) => item.lessonId === lessonId)?.sortId || 0,
        [lessonId, storageData],
    );

    const getCreditAmount = () => {
        if (user) {
            CreditTransactionsAPI.get(user.id).then((items) => {
                const sumAmount =
                    items?.reduce(
                        (prevValue, { amount }) => prevValue + amount,
                        0,
                    ) || 0;

                setCreditAmount(sumAmount);
            });
        }
    };

    const handleSubmit = () => {
        if (isEnoughCredit) {
            CreditTransactionsAPI.create(user!.id, {
                type: "LESSON_ACTIVATION",
                amount: -250,
                info: {
                    courseId,
                    lessonId,
                    lessonTitle,
                },
            }).then(() =>
                OwnershipsAPI.unlockLesson(user!.id, {
                    lessonId,
                }).then(() => {
                    navigateTo.lessonDetail(
                        courseId as ICourse["id"],
                        formatUrlTitle(sortId, lessonTitle),
                    );
                }),
            );
        }
    };

    useEffect(() => {
        getCreditAmount();
    }, []);

    return (
        <Modal ref={ref}>
            <StyledModalTitle level={3} alignment="left">
                Odemknout lekci
            </StyledModalTitle>

            <StyledContent>
                {isEnoughCredit ? (
                    <Fragment>
                        <p>Přejete si odemknout lekci za 250 bodů?</p>
                        <p>Aktuální zůstatek: {creditAmount} bodů.</p>
                    </Fragment>
                ) : (
                    <Fragment>
                        <p>
                            Nemáte dostatek kreditu. K aktivaci lekce
                            potřebujete 250 bodů kreditu.
                        </p>
                        <p>Aktuální zůstatek: {creditAmount} bodů.</p>

                        <Button
                            label="Dobít kredit"
                            onClick={navigateTo.accountCredit}
                        />
                    </Fragment>
                )}
            </StyledContent>

            <StyledModalButtonsGroup>
                <Button
                    label="Odemknout"
                    isDisabled={!isEnoughCredit}
                    onClick={handleSubmit}
                />
            </StyledModalButtonsGroup>

            <StyledNote>
                Odemčené lekce jsou přístupné pro všechny členy týmu. Tým se
                může skládat až z 9 členů. (více v kategorii Účet)
            </StyledNote>
        </Modal>
    );
});
