import { ButtonsGroup } from "@nazorna-vyuka/ui";
import styled from "styled-components";

export const StyledItemsContainer = styled.ul`
    margin: 50px -6px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
`;

export const StyledItem = styled.li`
    flex-basis: calc(100% / 4 - 20px);
    background: #f5f5f5;
    border: 1px solid rgba(173, 173, 173, 0.2);
    border-radius: 5px;
    list-style: none;
    margin: 10px;
    overflow: hidden;
    transition: 200ms background;

    @media (max-width: 1400px) {
        flex-basis: calc(100% / 3 - 20px);
    }

    @media (max-width: 1100px) {
        flex-basis: calc(100% / 2 - 20px);
    }

    @media (max-width: 800px) {
        flex-basis: calc(100% - 20px);
    }

    &:last-of-type {
        margin-right: auto;
    }
`;

export const StyleItem_Content = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    cursor: pointer;
`;

export const StyleItem_Image = styled.img`
    width: 100%;
    aspect-ratio: 1/0.5;
    object-fit: cover;
`;

export const StyleItem_Name = styled.p`
    max-width: calc(100% - 45px);
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    margin: 5px 10px 10px 10px;
`;

export const StyleItem_Number = styled.p`
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    margin: 15px 10px 10px 10px;
`;

export const StyleItem_IconContainer = styled.div<{ color?: string }>`
    width: 36px;
    height: 36px;
    background-color: ${({ color }) => color || "#d9d9e0"};
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    * {
        width: 60%;
        height: 60%;
    }
`;

export const StyledItem_IconsContainer = styled(ButtonsGroup)`
    width: auto;
    position: absolute;
    right: 10px;
    bottom: 10px;
    pointer-events: none;
`;
