import { ISignedUser } from "@nazorna-vyuka/types";
import { Button, ButtonsGroup, Layout_Main } from "@nazorna-vyuka/ui";
import {
    formatUserName,
    InvitationsAPI,
    log,
    useCurrentPage,
    UsersAPI,
} from "@nazorna-vyuka/utils";
import { useEffect, useState } from "react";
import { StyledContainer, StyledText } from "./InvitationAccept.styled";

export const Page_InvitationAccept: React.FC = () => {
    const { params, navigateTo } = useCurrentPage({
        title: "Pozvánka",
    });

    const id = params["id"];

    const [hostUser, setHostUser] = useState<ISignedUser>();

    const handleAccept = () => {
        InvitationsAPI.setState(id!, "ACCEPTED")
            .then(navigateTo.signUp)
            .catch((error) => {
                log({
                    code: "EXCEPTION",
                    scope: "Page_InvitationAccept",
                    error,
                });
            });
    };

    useEffect(() => {
        if (id) {
            InvitationsAPI.get(id)
                .then(({ state, createdBy }) => {
                    if (state === "SENT") {
                        return UsersAPI.get(createdBy).then(setHostUser);
                    } else {
                        navigateTo.home();
                    }
                })
                .catch((error) => {
                    log({
                        code: "EXCEPTION",
                        scope: "Page_InvitationAccept",
                        error,
                    });
                });
        } else {
            navigateTo.home();
        }
    }, []);

    return (
        <Layout_Main>
            <StyledContainer>
                <StyledText>
                    Uživatel{" "}
                    {formatUserName(hostUser?.firstName, hostUser?.lastName)}{" "}
                    vás zve k registraci do aplikace Názorná výuka.
                </StyledText>

                <ButtonsGroup alignment="left">
                    <Button
                        label="Přijmout pozvánku a registrovat se"
                        onClick={handleAccept}
                    />
                </ButtonsGroup>
            </StyledContainer>
        </Layout_Main>
    );
};
