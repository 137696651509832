import { replaceAccentedCharacters } from "@honzachalupa/utils";
import { CoursesIcons } from "@nazorna-vyuka/assets";
import { ICourse } from "@nazorna-vyuka/types";

export const courseIcons: { [courseId in ICourse["id"]]: string } = {
    "informatika-i": CoursesIcons.informatikaI,
    "chemie-8": CoursesIcons.chemie8,
    "chemie-pokusy": CoursesIcons.chemiePokusy,
    "matematika-5": CoursesIcons.matematika5,
    "matematika-prijimacky": CoursesIcons.matematikaPrijimacky,
    "fyzika-6": CoursesIcons.fyzika6,
    "nutricni-gramotnost": CoursesIcons.nutricniGramotnost,
    "financni-gramotnost": CoursesIcons.financniGramotnost,
    "medialni-gramotnost": CoursesIcons.medialniGramotnost,
};

export const formatUrlTitle = (sortId: number, value: string) =>
    replaceAccentedCharacters(`${sortId}-${value}`.trim().toLowerCase())
        .replace(/[\s-–]+/g, "-")
        .replace(/-$/g, "");
