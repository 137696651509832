import { ITask } from "@nazorna-vyuka/types";
import { List } from "@nazorna-vyuka/ui";
import {
    formatUserName,
    useAuthentication,
    useNavigation,
} from "@nazorna-vyuka/utils";
import moment from "moment";
import { useMemo } from "react";
import { getVimtoUrl } from "./TasksListVimto.utils";

interface IProps {
    tasks: ITask[];
    subjectCode: string;
    classYear: number;
}

enum EDifficulties {
    Easy = "Lehká",
    Medium = "Střední",
    Hard = "Těžká",
}

export const TasksListVimto: React.FC<IProps> = ({
    tasks,
    subjectCode,
    classYear,
}) => {
    const { user } = useAuthentication();
    const navigateTo = useNavigation();

    const items = useMemo(
        () =>
            tasks
                .filter(({ isAvailableInVimto }) => isAvailableInVimto)
                .map((task) => ({
                    name: task.title,
                    questionsCount: task.questionsCount,
                    estimatedTime: moment()
                        .startOf("day")
                        .add("minutes", task.durationInMinutes)
                        .format("H:mm"),
                    // @ts-ignore
                    difficulty: EDifficulties[task.difficulty],
                })),
        [tasks],
    );

    return (
        <List
            columns={[
                {
                    id: "name",
                    label: "Aktivita",
                },
                {
                    id: "questionsCount",
                    label: "Otázky",
                },
                {
                    id: "estimatedTime",
                    label: "Čas",
                },
                {
                    id: "difficulty",
                    label: "Obtížnost",
                },
            ]}
            data={items}
            actions={[
                {
                    label: "Spustit",
                    onClick: ({ name }) =>
                        user &&
                        navigateTo.url(
                            getVimtoUrl({
                                classRoomName: "Naše 8.A",
                                subjectCode,
                                classYear,
                                teacherName: formatUserName(
                                    user?.firstName,
                                    user?.lastName,
                                ),
                                exerciseName: name,
                            }),
                            true,
                        ),
                },
            ]}
        />
    );
};
