import { ILesson } from "./course";
import { ISignedUser } from "./user";

export interface IOwnership {
    id: ISignedUser["id"];
    ownerships: {
        lessonId: ILesson["id"];
    }[];
    subscription?: {
        dueDate: string;
    };
    sharedBy: string[];
}

export interface IOwnershipItem {
    lessonId: ILesson["id"];
    ownershipType: "owned" | "shared";
    sharedBy?: ISignedUser["id"];
}
