import {
    Button,
    IModalRefProps,
    LayoutMargin,
    Layout_Main,
    theme,
    Title,
} from "@nazorna-vyuka/ui";
import { useCurrentPage } from "@nazorna-vyuka/utils";
import React, { useRef } from "react";
import { ModalMessage } from "../../../components/molecules/ModalMessage";
import { Form_AccountDetails } from "../../../components/organisms/forms/AccountDetails";
import { StyledButtonsGroup } from "./styled";

export const Page_AccountDetailsEdit: React.FC = () => {
    const { title, navigateTo } = useCurrentPage({ title: "Upravit účet" });

    const modalMessageRef = useRef<IModalRefProps>(null);

    return (
        <Layout_Main>
            <ModalMessage
                ref={modalMessageRef}
                message="Změny úspěšně uloženy"
                icon={{
                    name: "check",
                    color: "white",
                    backgroundColor: theme.colors.green,
                }}
                onClose={() => window.location.reload()}
            />

            <StyledButtonsGroup alignment="left">
                <Button
                    label="Zpět"
                    color="test"
                    icon={{
                        name: "arrowLeft",
                        color: theme.colors.accentPrimary,
                    }}
                    onClick={navigateTo.accountDetails}
                />
            </StyledButtonsGroup>

            <Title level={1}>{title}</Title>

            <LayoutMargin>
                <Title level={3} alignment="left">
                    Mé údaje
                </Title>

                <Form_AccountDetails
                    onSave={() => {
                        modalMessageRef.current?.show();
                    }}
                />
            </LayoutMargin>
        </Layout_Main>
    );
};
