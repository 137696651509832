import {
    convertDocsToItems,
    convertDocToItem,
    TQuery,
} from "@honzachalupa/firebase";
import { getRandomInRange } from "@honzachalupa/utils";
import { ERoles, ISignedUser } from "@nazorna-vyuka/types";
import moment from "moment";
import { Collections, Database } from "../utils/firebase";
import { log } from "../utils/logging";

const getIsOfficialDomain = (emailAddress: string) => {
    const unofficialDomains = [
        "seznam",
        "email",
        "centrum",
        "volny",
        "atlas",
        "gmail",
        "outlook",
        "hotmail",
        "icloud",
    ];

    let isOfficialDomain = true;

    unofficialDomains.forEach((domain) => {
        if (emailAddress.includes(`@${domain}.`)) {
            isOfficialDomain = false;
        }
    });

    return isOfficialDomain;
};

export const UsersAPI = {
    create: (id: string, emailAddress: string) => {
        const isOfficialDomain = getIsOfficialDomain(emailAddress);

        return Database.set(Collections.users, id, {
            emailAddress,
            role: ERoles.USER,
            isOfficialDomain,
        }).then(() =>
            Promise.all([
                isOfficialDomain
                    ? Database.set(Collections.creditTransactions, id, {
                          transactions: [
                              {
                                  type: "CREDIT_TOP_UP_REGISTRATION",
                                  amount: 750,
                                  validityDateFrom: moment().format(),
                                  validityDateTo: moment()
                                      .add(1, "year")
                                      .format(),
                              },
                          ],
                      })
                    : Database.set(Collections.creditTransactions, id, {
                          transactions: [],
                      }),
                Database.set(Collections.ownerships, id, {
                    ownerships: [],
                    sharedBy: [],
                }),
            ]),
        );
    },

    delete: (id: string) => Database.delete(Collections.users, id),

    update: (
        id: string,
        data: {
            firstName: string;
            lastName: string;
            phoneNumber: string;
            schoolName: string;
        },
    ) => Database.update(Collections.users, id, data),

    get: (id: ISignedUser["id"]): Promise<ISignedUser> =>
        Database.get(Collections.users, id)
            .then(convertDocToItem)
            .catch((error) => {
                log({
                    code: "EXCEPTION",
                    scope: "UsersAPI",
                    error,
                });

                return null as any;
            }),

    search: (query?: TQuery): Promise<ISignedUser[]> =>
        Database.search(Collections.users, query)
            .then(convertDocsToItems)
            .catch((error) => {
                log({
                    code: "EXCEPTION",
                    scope: "UsersAPI",
                    error,
                });

                return [];
            }),

    setRole: (id: ISignedUser["id"], role: ERoles) =>
        Database.update(Collections.users, id, { role }),

    generateSharedAccessCode: async () => {
        const usedCodes = await Database.search(Collections.users)
            .then(convertDocsToItems)
            .then((users) =>
                users
                    .map(({ sharedAccessCode }) => sharedAccessCode)
                    .filter(Boolean),
            );

        let newCode = 0;

        while (newCode === 0 || usedCodes.includes(newCode)) {
            newCode = getRandomInRange(100000, 999999);
        }

        return newCode;
    },

    updateSharedAccessCode: (id: ISignedUser["id"], sharedAccessCode: number) =>
        Database.update(Collections.users, id, { sharedAccessCode }),

    validateSharedAccessCode: (code: number): Promise<ISignedUser> =>
        Database.search(Collections.users)
            .then(convertDocsToItems)
            .then((users) =>
                users.find(({ sharedAccessCode }) => sharedAccessCode === code),
            ),
};
