export type TCreditTransactionType =
    | "CREDIT_TOP_UP_REGISTRATION"
    | "CREDIT_TOP_UP"
    | "LESSON_ACTIVATION";

export enum ECreditTransactionTypeLabels {
    CREDIT_TOP_UP = "Zakoupení kreditu",
    LESSON_ACTIVATION = "Odemčení lekce",
}

export interface ICreditTopUpItem {
    amount: number;
}
