import { ERoutePaths, IPaymentGetResponseData } from "@nazorna-vyuka/types";
import {
    IModalRefProps,
    Layout_Main,
    Loader,
    StyledModalText,
    StyledModalTitle,
    theme,
} from "@nazorna-vyuka/ui";
import {
    CreditTransactionsAPI,
    log,
    PaymentsAPI,
    useCurrentPage,
} from "@nazorna-vyuka/utils";
import React, { useEffect, useRef, useState } from "react";
import { ModalMessage } from "../../../components/molecules/ModalMessage";

export const Page_PaymentConfirmation: React.FC = () => {
    const { user, title, location, navigateTo } = useCurrentPage({
        title: "Potvrzení platby",
    });

    const modalMessageRef = useRef<IModalRefProps>(null);

    const [payment, setPayment] = useState<IPaymentGetResponseData>();
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const getPayment = (id: number) => {
        PaymentsAPI.get(id).then(({ data }) => {
            setPayment(data);

            if (data.state === "PAID") {
                CreditTransactionsAPI.create(user!.id, {
                    type: "CREDIT_TOP_UP",
                    amount: data.amount,
                })
                    .then(modalMessageRef.current?.show)
                    .finally(() => setIsLoading(false));
            } else {
                setIsLoading(false);
            }
        });
    };

    useEffect(() => {
        const urlParams = new URLSearchParams(location.search);
        const paymentId = urlParams.get("id");

        if (paymentId) {
            getPayment(Number(paymentId));

            navigateTo.path(ERoutePaths.PAYMENT_CONFIRMATION, true);
        } else {
            log({
                code: "EXCEPTION",
                scope: "Page_PaymentConfirmation",
                error: new Error('Property "paymentId" is undefined.'),
            });

            navigateTo.path(ERoutePaths.INDEX);
        }
    }, []);

    return (
        <Layout_Main>
            <ModalMessage
                ref={modalMessageRef}
                message="Platba byla úspěšně provedena."
                icon={{
                    name: "check",
                    color: "white",
                    backgroundColor: theme.colors.green,
                }}
                onClose={() => {
                    navigateTo.accountCredit();
                    window.location.reload();
                }}
            />

            <StyledModalTitle level={1} alignment="left">
                {title}
            </StyledModalTitle>

            {payment?.state !== "PAID" && (
                <StyledModalText>Platba se nezdařila</StyledModalText>
            )}

            {isLoading && <Loader isFullscreen />}
        </Layout_Main>
    );
};
