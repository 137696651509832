import { IResponse } from "@nazorna-vyuka/types";
import fetch from "node-fetch";
import { isDevEnv } from "./app";

export const apiUrl = isDevEnv
    ? "https://europe-west3-enter-dev-aaaf0.cloudfunctions.net"
    : "https://europe-west3-enter-44054.cloudfunctions.net";
// LOCAL: http://localhost:5001/enter-dev-aaaf0/europe-west3

export const api = {
    post: (path: string, data: object): Promise<IResponse<any>> =>
        fetch(apiUrl + path, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        })
            .then(async (response) => {
                const data = await response.json();

                if (response.ok) {
                    return data;
                } else {
                    throw data;
                }
            })
            .catch(({ error }) => {
                throw new Error(error);
            }),
};
