import { Layout_Main, Link, Text, Title } from "@nazorna-vyuka/ui";
import { useCurrentPage } from "@nazorna-vyuka/utils";
import React from "react";
import styled from "styled-components";
import { Form_SignIn } from "../../../components/organisms/forms/SignIn";

const StyledText = styled(Text)`
    position: relative;
    top: -30px;
`;

export const Page_AuthenticationSignIn: React.FC = () => {
    const { title, navigateTo } = useCurrentPage({ title: "Přihlásit" });

    return (
        <Layout_Main>
            <Title level={1}>{title}</Title>

            <StyledText alignment="center">
                Jste tu poprvé?{" "}
                <Link label="Zaregistrujte se" onClick={navigateTo.signUp} />
            </StyledText>

            <Form_SignIn />
        </Layout_Main>
    );
};
