import { IModule } from "@nazorna-vyuka/types";
import styled from "styled-components";

const StyledAudio = styled.audio`
    margin: 10px 0;
`;

export const Module_Audio: React.FC<IModule> = ({ url }) => (
    <StyledAudio controls>
        <source src={url} type="audio/mpeg" />
    </StyledAudio>
);
