import { IModule } from "@nazorna-vyuka/types";
import { BsArrowRepeat } from "react-icons/bs";
import { CollapsibleBlock } from "../../../atoms/CollapsibleBlock";
import { MarkdownText } from "../../../atoms/MarkdownText";

export const Module_Methodology: React.FC<IModule> = ({ text }) => (
    <CollapsibleBlock label="Metodika" icon={<BsArrowRepeat />} color="#B6EEFF">
        <MarkdownText value={text} />
    </CollapsibleBlock>
);
