import { administrationRoutes } from "@nazorna-vyuka/admin-web";
import { Fragment } from "react";
import { BrowserRouter, Routes } from "react-router-dom";
import accountRoutes from "../pages/Account/Account.routes";
import authenticationRoutes from "../pages/Authentication/Authentication.routes";
import coursesOwnedRoutes from "../pages/CoursesUnlocked/CoursesUnlocked.routes";
import homeRoutes from "../pages/Home/Home.routes";
import invitationRoutes from "../pages/InvitationAccept/InvitationAccept.routes";
import lessonsRoutes from "../pages/Lessons/Lessons.routes";
import notFoundRoutes from "../pages/NotFound/NotFound.routes";
import paymentsRoutes from "../pages/Payments/Payments.routes";
import termsAndConditionsRoutes from "../pages/TermsAndConditions/TermsAndConditions.routes";

export const Router: React.FC = () => {
    const routes = [
        ...homeRoutes,
        ...coursesOwnedRoutes,
        ...lessonsRoutes,
        ...accountRoutes,
        ...paymentsRoutes,
        ...invitationRoutes,
        ...authenticationRoutes,
        ...administrationRoutes,
        ...termsAndConditionsRoutes,
        ...notFoundRoutes,
    ];

    return (
        <BrowserRouter>
            <Routes
                children={routes.map((route, i) => (
                    <Fragment key={i}>{route}</Fragment>
                ))}
            />
        </BrowserRouter>
    );
};
