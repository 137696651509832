import {
    QueryClient,
    QueryClientProvider,
    useExceptionHandler,
} from "@honzachalupa/utils";
import { IContext } from "@nazorna-vyuka/types";
import { GlobalStyle, theme } from "@nazorna-vyuka/ui";
import { Context, log } from "@nazorna-vyuka/utils";
import React, { ReactNode } from "react";
import { ThemeProvider } from "styled-components";
import { Fonts } from "./Fonts";

interface IProps {
    context: IContext;
    children: ReactNode;
}

const queryClient = new QueryClient();

export const ProvidersWrapper: React.FC<IProps> = ({ context, children }) => {
    useExceptionHandler((error) => {
        log({
            code: "EXCEPTION",
            scope: "exceptionHandler",
            error,
        });
    });

    return (
        <ThemeProvider theme={theme}>
            <Fonts />

            <GlobalStyle
                theme={theme}
                isScrollDisabled={context.isScrollDisabled}
            />

            <Context.Provider value={context}>
                <QueryClientProvider client={queryClient}>
                    {children}
                </QueryClientProvider>
            </Context.Provider>
        </ThemeProvider>
    );
};
