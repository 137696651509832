import { IFormInput } from "@nazorna-vyuka/types";
import {
    Form,
    HorizontalDivider,
    IModalRefProps,
    Link,
    Loader,
    theme,
} from "@nazorna-vyuka/ui";
import {
    Authentication,
    translateAuthError,
    useNavigation,
} from "@nazorna-vyuka/utils";
import { Fragment, useMemo, useRef, useState } from "react";
import { ModalMessage } from "../../molecules/ModalMessage";
import { ThirdPartyAuthOptions } from "../../molecules/ThirdPartyAuthOptions";
import { Modal_PasswordReset } from "../modals/PasswordReset";
import { StyledTermsAndConditionsContainer } from "./SignIn.styled";

interface IFormData {
    emailAddress: string;
    password: string;
}

export const Form_SignIn: React.FC = () => {
    const navigateTo = useNavigation();

    const modalMessageRef = useRef<IModalRefProps>(null);
    const resetPasswordModalRef = useRef<IModalRefProps>(null);

    const [formData, setFormData] = useState<IFormData>();
    const [errors, setErrors] = useState<{
        [inputId: string]: string | undefined;
    }>();
    const [isLoading, setIsLoading] = useState<boolean>();

    const handleSubmit = ({ emailAddress, password }: IFormData) => {
        setIsLoading(true);

        Authentication.signInWithEmailAndPassword(emailAddress.trim(), password)
            .then(() => {
                navigateTo.home();
            })
            .catch((error) => {
                setErrors(translateAuthError(error.code));
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return (
        <Fragment>
            <ModalMessage
                ref={modalMessageRef}
                message="E-mail s instrukcemi byl odeslán."
                icon={{
                    name: "check",
                    color: "white",
                    backgroundColor: theme.colors.green,
                }}
            />

            <Modal_PasswordReset
                ref={resetPasswordModalRef}
                emailAddress={formData?.emailAddress}
                onSubmit={() => {
                    resetPasswordModalRef.current?.hide();
                    modalMessageRef.current?.show();
                }}
            />

            <HorizontalDivider>
                <div style={{ padding: "0 15%" }}>
                    <Form
                        inputs={useMemo<IFormInput[]>(
                            () => [
                                {
                                    id: "emailAddress",
                                    label: "E-mailová adresa",
                                    controlType: "input",
                                    rules: {
                                        required: true,
                                    },
                                    error: errors?.["emailAddress"],
                                },
                                {
                                    id: "password",
                                    label: "Heslo",
                                    controlType: "input",
                                    inputType: "password",
                                    rules: {
                                        required: true,
                                    },
                                    error: errors?.["password"],
                                },
                                {
                                    id: "password-reset",
                                    controlType: "custom",
                                    children: (
                                        <Link
                                            label="Resetovat heslo"
                                            onClick={() =>
                                                resetPasswordModalRef.current?.show()
                                            }
                                        />
                                    ),
                                },
                            ],
                            [errors],
                        )}
                        buttonsRenderer={(submit) => [
                            {
                                label: "Přihlásit",
                                testId: "SignIn",
                                onClick: submit,
                            },
                        ]}
                        onChange={setFormData}
                        onSubmit={handleSubmit}
                    />
                </div>

                <div style={{ padding: "50px 15% 0px 15%" }}>
                    <ThirdPartyAuthOptions action="sign-in" />
                </div>
            </HorizontalDivider>

            <StyledTermsAndConditionsContainer>
                Přihlášením souhlasíte s podmínkami použití.
            </StyledTermsAndConditionsContainer>

            {isLoading && <Loader isFullscreen />}
        </Fragment>
    );
};
