import { ITheme } from "@honzachalupa/ui";

const colors: ITheme["colors"] = {
    accentPrimary: "#3E6EDD",
    accentSecondary: "#3E6EDD",
    black: "black",
    white: "white",
    grayLight: "#cfcfd1",
    grayDark: "#1c202a",
    green: "#5BB275",
    red: "#e60000",
    blueLight: "#4dc5d7",
    blueDark: "#21b7cd",
};

export const theme: ITheme = {
    colors,
    background: "#F7F8FB",
    fonts: {
        primary: "'Graphik', sans-serif",
        secondary: "'Graphik', sans-serif",
    },
    fontColors: {
        black: colors.black,
        white: colors.white,
        faded: colors.grayDark,
    },
    breakpoints: {
        sm: "600px",
        md: "1100px",
    },
    footer: {
        light: "#b7e1de",
        dark: "#9dcfc6",
    },
    input: {
        border: {
            radius: 8,
        },
    },
    button: {
        border: {
            width: 1,
            radius: 8,
        },
        styles: {
            default: {
                backgroundColor: colors.accentPrimary,
                color: "white",
            },
            secondary: {
                backgroundColor: colors.grayLight,
                color: "black",
            },
            green: {
                backgroundColor: colors.green,
                color: "white",
            },
            red: {
                backgroundColor: colors.red,
                color: "white",
            },
            google: {
                backgroundColor: "white",
                color: "black",
                border: {
                    color: "rgba(0, 0, 0, 0.25)",
                },
                boxShadow: "none",
            },
            facebook: {
                backgroundColor: "#3B5998",
                color: "white",
                border: {
                    color: "rgba(0, 0, 0, 0.25)",
                },
                boxShadow: "none",
            },
            lessonItem: {
                backgroundColor: "#D9D9E0",
                boxShadow: "none",
            },
            test: {
                backgroundColor: "transparent",
                color: colors.accentPrimary,
                boxShadow: "none",
            },
        },
    },
    list: {
        body: {
            borderBottom: {
                width: 0,
            },
        },
    },
    modal: {
        padding: 40,
        border: {
            radius: 15,
        },
        closeIcon: {
            color: "black",
            margin: 40,
        },
    },
    loader: {
        color: colors.accentPrimary,
    },
};
